import { combineReducers } from 'redux'

import auth from '../modules/auth/index'
import onboarding from '../modules/onboarding/index'
import layout from '../modules/MainLayout/index'

export default combineReducers({
  [auth.constants.NAMESPACE]: auth.reducer,
  [onboarding.constants.NAMESPACE]: onboarding.reducer,
  [layout.constants.NAMESPACE]: layout.reducer
})