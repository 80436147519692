import React from 'react'
import styled, {keyframes} from 'styled-components'
import { gradientBG } from '../../styles/animations'

const AxisSkeletonCard = () => (
  <Wrapper>
    <div>
      <CircleWrapper>
        <Circle />
      </CircleWrapper>
      <ContentBox></ContentBox>
    </div>
  </Wrapper>
)


const Wrapper = styled.div`
  background-color: #fafafb;
  border-radius: 1.5rem;
  box-shadow: 0px 10px 15px -3px rgba(0,0,0,0.1), 0px -6px 6px -2px rgba(0,0,0,0.05);
  display: flex;
  justify-content: center;
  align-items: center;
`
const Circle = styled.div`
  height: 64px;
  border-radius: 100%;
  background: linear-gradient(-45deg, #fbfbfb, #cccccc, #fbfbfb );
  background-size: 400% 400%;
  animation: ${gradientBG} 2s ease infinite;

`

const CircleWrapper = styled.div`
  margin: 0 auto;
  width: 64px;
`

const ContentBox = styled.div`
  width: 7rem;
  height: 1.5rem;
  margin-top: 1rem;
  background: linear-gradient(-45deg, #fbfbfb, #cccccc, #fbfbfb );
  background-size: 400% 400%;
  animation: ${gradientBG} 2s ease infinite;
  
`

export default AxisSkeletonCard