import { withFormik } from 'formik'
import * as Yup from 'yup'

export const OrganizationRequestEnhancer = withFormik({
  validationSchema: Yup.object().shape({
    name: Yup.string() 
      .required('signup.name_required'),
    web_url: Yup.string()
      .required('signup.url_required'),
    location: Yup.object(({
      text: Yup.string().required('signup.location_required'),
      lat: Yup.number(),
      lng: Yup.number(),
      locationId: Yup.string()
    }))
  }),
  mapPropsToValues: props => ({
    handleSubmit: props.handleSubmit,
    router: props.handleRouter,
    userId: props.userId,
    name: '',
    web_url: '',
    location: {
      text: '',
      lat: null,
      lng: null,
      locationId: null,
    },
    createOrganization: props.createOrganization
  }),
  handleSubmit: (values, { setSubmitting }) => {    
    const data = {
      organization: {
        name: values.name,
        location: values.location.text,
        lat: values.location.lat,
        lng: values.location.lng,
        location_id: values.location.locationId,
        site: values.web_url,
        game_id: process.env.REACT_APP_ORGANIZATION_ID
      },
      user: {
        id: values.userId
      }
    }
    values.createOrganization({ data, router: values.router })
    setSubmitting(false)
  },
  displayName: 'OrganizationForm'
})