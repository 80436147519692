import { call, put, takeEvery } from 'redux-saga/effects'

import { post } from '../../utils/api'
import * as actions from './actions'

const GAME_ID = process.env.REACT_APP_ORGANIZATION_ID

export function * loginUserWorker(action) {
  yield put(actions.loginUser.start())
  const { email, password } = action.payload

  if (!email || !password) {
    return yield put(actions.loginUser.failure({ error: 'Mail and password are mandatory'}))
  }

  const { res, error } = yield call(post, 'auth/login', { email, password, game_id: GAME_ID })

  if (error) {
    return yield put(actions.loginUser.failure({ error: error.data.error }))
  }

  localStorage.setItem('token', res.token)
  localStorage.setItem('user_id', res.id)
  window.location.replace('/')

  return yield put(actions.loginUser.success({ user: res }))
}

export function * userSignupWorker(action) {
  yield put(actions.signupUser.start())

  const { data } = action.payload
  
  const { res, error } = yield call(post, 'users', data)
  if(error){
    return yield put(actions.signupUser.failure({ error: error.data.errors }))
  }

  localStorage.setItem('token', res.user.token)
  localStorage.setItem('user_id', res.user.id)

  if(res.user.organization_id){
    window.location.replace('/')
  }else{
    window.location.replace(`onboarding/list/${res.user.id}`)
  }

  yield put(actions.signupUser.success({  user: res.user }))

}

export function * googleAuthWorker(action){
  yield put(actions.googleAuth.start())

  const { data } = action.payload
  const {res, error} = yield call(post, 'users/google_auth', data)

  if (error){
    return yield put(actions.googleAuth.failure({ error: error.data.errors}))
  }

  localStorage.setItem('token', res.user.token)
  localStorage.setItem('user_id', res.user.id)

  if(res.user.organization_id){
    window.location.replace('/')
  }else{
    window.location.replace(`onboarding/list/${res.user.id}`)
  }

  return yield put(actions.googleAuth.success({  user: res.user }))

}


export function * loginUserWatcher () {
  yield takeEvery(actions.loginUser.type, loginUserWorker)
}

export function * userSignupWatcher () {
  yield takeEvery(actions.signupUser.type, userSignupWorker)
}

export function * googleAuthWatcher () {
  yield takeEvery(actions.googleAuth.type, googleAuthWorker)
}

export default [
  loginUserWatcher,
  userSignupWatcher,
  googleAuthWatcher
]