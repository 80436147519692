import React from 'react'
import IconWrapper from './iconWrapper'

const PptIcon = () => (
  <IconWrapper width="35px" height="35px">
    <path d="M184,224a80,80,0,1,0,80-80,8,8,0,0,0-8,8v64H192A8,8,0,0,0,184,224Zm80,8a8,8,0,0,0,8-8V160.5A64,64,0,1,1,200.5,232Z"/>
    <path d="M160,200h72a8,8,0,0,0,8-8V120a8,8,0,0,0-8-8,80.091,80.091,0,0,0-80,80A8,8,0,0,0,160,200Zm64-71.5V184H168.5A64.138,64.138,0,0,1,224,128.5Z"/>
    <path d="M349.657,18.343A8,8,0,0,0,344,16H120A56.064,56.064,0,0,0,64,72V440a56.064,56.064,0,0,0,56,56H392a56.064,56.064,0,0,0,56-56V120a8,8,0,0,0-2.343-5.657ZM352,43.313,420.687,112H392a40.045,40.045,0,0,1-40-40ZM120,32H336V72a56.064,56.064,0,0,0,56,56h40V352H80V72A40.045,40.045,0,0,1,120,32ZM392,480H120a40.045,40.045,0,0,1-40-40V368H432v72A40.045,40.045,0,0,1,392,480Z"/>
    <path d="M256,384H240a8,8,0,0,0-8,8v64a8,8,0,0,0,16,0V432h8a24,24,0,0,0,0-48Zm0,32h-8V400h8a8,8,0,0,1,0,16Z"/>
    <path d="M192,384H176a8,8,0,0,0-8,8v64a8,8,0,0,0,16,0V432h8a24,24,0,0,0,0-48Zm0,32h-8V400h8a8,8,0,0,1,0,16Z"/>
    <path d="M336,384H304a8,8,0,0,0,0,16h8v56a8,8,0,0,0,16,0V400h8a8,8,0,0,0,0-16Z"/>
  </IconWrapper>
)

export default PptIcon