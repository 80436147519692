import { gql } from 'apollo-boost'

export const GET_SUGGESTION = gql`
  query suggestion($_id: String!){
    suggestion(_id: $_id){
      title
      description
      axis{
        name
      }
      materials{
        _id
        type
        name
        link
      }
    }
  }
`

export const GET_AXIS_SUGGESTIONS = gql`
{
    user {
      progress {
        suggestion {
          _id
          axis{
            _id
            name
            pallete{
              primary
            }
          }
        }
      }
    }
  }
`

export const GET_SUGGESTIONS = gql`
  {
    user {
      progress {
        suggestion {
          _id
          title
          description
          axis{
            _id
            name
            pallete{
              primary
            }
          }
          materials {
            _id
            type
            name
            link
          }
        }
      }
    }
  }
`

export const GET_SMALL_SUGGESTIONS = gql`
  {
    user {
      progress {
        suggestion {
          _id
          title
        }
      }
    }
  }
`