import React from 'react'
import styled from "styled-components"

const Paragraph = ({children}) => {
  return (<StyledParagraph>
    {children}
  </StyledParagraph>)
}

const StyledParagraph = styled.p`
  font-size: ${props => props.theme.font_size.medium}
  font-weight: 400
`

export default Paragraph