import React from 'react'
import styled from 'styled-components'
import PersonLarge from '../../../../../../components/GlobalIcons/PersonLarge'
import Paragraph from '../../../../../../components/Paragraph'
import Title from '../../../../../../components/Title'
import House from '../../../../../../components/GlobalIcons/House'
import Badge from '../../../../../../components/Badge/Badge'

const NewAxes = ({data, history, loading, t, toggleAxisColor}) => {
  const choose = t('choose')

  return (
    <Wrapper>
      <Axes>
        { loading ? (
          <AxisWrapper>
            <LoaderAxis></LoaderAxis>
          </AxisWrapper>
        ) : data && data.length > 0 && (
          <>
             <Title fontSize={'medium'} padding={'0 0 0 1rem'}>{choose}</Title>
            <AxisWrapper>
              {data.map((axis, key) => (
                <Axis key={key} onClick={() => { 
                  if (axis.pallete) {
                    toggleAxisColor(axis.pallete.primary)
                  }

                  history.push(`/axis/${axis._id}`) 
                }}>
                  <Badge>
                    <Title color={'white'} fontSize={'medium'} padding={'0.3rem 0 0 0.3rem '}>{ axis.name }</Title>
                    {/* <Title color={axis.pallete.primary}>{ axis.name }</Title> */}
                    {/* <Paragraph>Conoces la importancia de tener una vision, mision, y/o proposito claro en tu organizacion</Paragraph> */}
                  </Badge>
                </Axis>
              ))}
            </AxisWrapper>
          </>
        )}
      </Axes>
    </Wrapper>
  )
}

const AxisWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  gap: 0.5rem; 
  margin-top: 1rem;
  @media(max-width:600px){
    flex-direction: column;
    margin-bottom: 20px;
  }

`

const Axis = styled.div`
  color: #1F2937;
  cursor: pointer;
  margin-left: 0.5rem;
  display: flex;
  gap: 1rem;
  lex-wrap: wrap;
  max-width: 100%; 
  overflow: hidden;
  box-sizing: border-box;
  @media(max-width:600px){
    margin-bottom: 10px;
  }
`

const Wrapper = styled.div`
  padding: 0 2rem;
  margin-top: 2rem;
 
`

const Axes = styled.div`

`

const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;

  > h1 {
    margin-left: 1rem;
  }
`

const LoaderAxis = styled.div`
border-radius: ${(props) => props.theme.border_radius.rounded_lg};
  padding: 0 1rem;
  background: #dedede;
  width: 75px;
  height: 30px;
`

export default NewAxes