import React from 'react'
import LoginForm from './forms/LoginForm'

const Auth = ({ onChange, loginUser, responseGoogle, ...props}) => (
  <LoginForm 
      responseGoogle={responseGoogle}
      loginUser={loginUser}
      status={props.status}
      error={props.error}
  />
)

export default Auth