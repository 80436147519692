import React from 'react'
import { withRouter } from 'react-router'
import AchievementCard from '../../components/AchievementCard'


const Achievement = ({achievement, handleModalClose}) => {
  return(
    <div>
      <AchievementCard
        achievement={achievement}
        handleModalClose={handleModalClose}
      />
    </div>
  )
}

export default withRouter(Achievement) 