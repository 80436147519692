import React from 'react'
import styled from 'styled-components'

const IconWrapper = ({children, width, height}) => (
  <Wrapper>
    <StyledSvg version="1.1" id="Layer_1" width={width} height={height} xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
      viewBox="0 0 512.001 512.001" space="preserve">
      {children}
    </StyledSvg>
  </Wrapper>
)

const Wrapper = styled.div`
  margin-right: 1rem;
`
const StyledSvg = styled.svg`
  fill: ${props => props.theme.primary};
`
export default IconWrapper