import React from "react";
import styled from "styled-components";

const IconContainer = styled.div`
  svg {
    width: ${props => props.width || "16px"};
    height: ${props => props.height || "16px"};
    fill: #1F2937; 
    transition: fill 0.2s; 
  }

  &:hover {
    svg {
      fill: ${props => props.theme.primary};
    }
  }
  `

function Icon({color, height, width}) {
  return (
    <IconContainer width={width} height={height} color={color}>
    <svg width="16" height="16" viewBox="0 0 12 16" fill={color} xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M2 13.5V7H3V13.5C3 13.6326 3.05268 13.7598 3.14645 13.8536C3.24021 13.9473 3.36739 14 3.5 14H12.5C12.6326 14 12.7598 13.9473 12.8536 13.8536C12.9473 13.7598 13 13.6326 13 13.5V7H14V13.5C14 13.8978 13.842 14.2794 13.5607 14.5607C13.2794 14.842 12.8978 15 12.5 15H3.5C3.10218 15 2.72064 14.842 2.43934 14.5607C2.15804 14.2794 2 13.8978 2 13.5ZM13 2.5V6L11 4V2.5C11 2.36739 11.0527 2.24021 11.1464 2.14645C11.2402 2.05268 11.3674 2 11.5 2H12.5C12.6326 2 12.7598 2.05268 12.8536 2.14645C12.9473 2.24021 13 2.36739 13 2.5Z" fill={color}/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M7.29299 1.50006C7.48052 1.31259 7.73483 1.20728 7.99999 1.20728C8.26516 1.20728 8.51946 1.31259 8.70699 1.50006L15.354 8.14606C15.4479 8.23995 15.5006 8.36729 15.5006 8.50006C15.5006 8.63284 15.4479 8.76018 15.354 8.85406C15.2601 8.94795 15.1328 9.00069 15 9.00069C14.8672 9.00069 14.7399 8.94795 14.646 8.85406L7.99999 2.20706L1.35399 8.85406C1.2601 8.94795 1.13277 9.00069 0.999991 9.00069C0.867215 9.00069 0.739877 8.94795 0.645991 8.85406C0.552104 8.76018 0.499359 8.63284 0.499359 8.50006C0.499359 8.36729 0.552104 8.23995 0.645991 8.14606L7.29299 1.50006Z" fill={color}/>
    </svg>
    </IconContainer>

  );
}

export default Icon;
