import React from 'react'
import styled from 'styled-components'
import Congrats from './congrats'
import Continue from './continue'


const PathFinished = ({ userAchievements, totalAchievements }) => (
  <Wrapper>
    {userAchievements >= totalAchievements 
      ? <Congrats />
      : <Continue />
    }

  </Wrapper>
)

// width: 100vw;
const Wrapper = styled.div`
  background: ${props => props.theme.primary};
  color: white;
  height: 100%;
  display: flex;
  justify-content: center;
  z-index: 2;
`


export default PathFinished