import React from "react";
import styled from "styled-components";

function Icon({color}) {
  return (
    <IconFilled>
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_614_2951)">
        <path
          d="M6.125 1.2373V3.50005H1.75C1.51794 3.50005 1.29538 3.59224 1.13128 3.75634C0.967187 3.92043 0.875 4.14299 0.875 4.37505V7.87505C0.875 8.10712 0.967187 8.32968 1.13128 8.49377C1.29538 8.65787 1.51794 8.75005 1.75 8.75005H6.125V14.0001H7.875V8.75005H10.9655C11.0938 8.75001 11.2204 8.72177 11.3366 8.66733C11.4527 8.6129 11.5554 8.5336 11.6375 8.43505L13.3289 6.40505C13.3944 6.32644 13.4302 6.22737 13.4302 6.12505C13.4302 6.02274 13.3944 5.92367 13.3289 5.84505L11.6375 3.81505C11.5554 3.71651 11.4527 3.63721 11.3366 3.58278C11.2204 3.52834 11.0938 3.5001 10.9655 3.50005H7.875V1.2373C7.875 1.00524 7.78281 0.782681 7.61872 0.618586C7.45462 0.454492 7.23206 0.362305 7 0.362305C6.76794 0.362305 6.54538 0.454492 6.38128 0.618586C6.21719 0.782681 6.125 1.00524 6.125 1.2373ZM10.9655 4.37505L12.4232 6.12505L10.9655 7.87505H1.75V4.37505H10.9655Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_614_2951">
          <rect width="14" height="14" fill={color} />
        </clipPath>
      </defs>
    </svg>
    </IconFilled>
  );
}
const IconFilled = styled.span`
  fill: ${(props) => props.color || props.theme.primary };
`;
export default Icon;
