import React from 'react'
import DocIcon from './icons/docs'
import PdfIcon from './icons/pdf'
import VideoIcon from './icons/video'
import PptIcon from './icons/ppt'

import FileIcon from "../GlobalIcons/FileIcon";
import LinkIcon from "../GlobalIcons/LinkIcon";
import PlayIcon from "../GlobalIcons/PlayIcon";


const IconSelector = ({type}) => {
  switch(type){
    case 'PDF': {
      return <FileIcon />
    }
    case 'VIDEO': {
      return <PlayIcon />
    }
    case 'PPT': {
      return <FileIcon />
    }

    default: {
      return <LinkIcon />
    }
  }
}

export default IconSelector