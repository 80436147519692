import { withFormik } from 'formik'
import * as Yup from 'yup'

export const OrganizationRequestEnhancer = withFormik({
  validationSchema: Yup.object().shape({
    name: Yup.string()
      .required('signup.name_required'),
    web_url: Yup.string()
      .required('signup.url_required'),
    location: Yup.string()
      .required('signup.location_required'),
  }),
  mapPropsToValues: props => ({
    handleCreateOrganization: props.handleCreateOrganization,
    name: '',
    web_url: '',
    location: '',
  }),
  handleSubmit: (values, { setSubmitting }) => {
    const data = {
      name: values.name,
      country: values.location,
      site: values.web_url
    }
    values.handleCreateOrganization({ data })
    setSubmitting(false)
  },
  displayName: 'OrganizationForm'
})

export const PersonalDataRequestEnhancer = withFormik({
  validationSchema: Yup.object().shape({
    name: Yup.string(),
    email: Yup.string()
      .email('signup.invalid_email')
      .required('signup.email_required'),
    password: Yup.string()
      .required('signup.password_required')
      .min(8, 'signup.invalid_password'),
    passwordConfirmation: Yup.string()
      .required('signup.password_required')
      .oneOf([Yup.ref('password'), null], 'signup.password_match')
  }),
  mapPropsToValues: props => ({
    handleNextStep: props.handleNextStep,
    handleSignupUser: props.handleSignupUser,
    status: props.status,
    name: '',
    email: '',
    password: '',
    passwordConfirmation: ''
  }),
  handleSubmit: (values, { setSubmitting }) => {
    const data = {
      name: values.name,
      email: values.email,
      password: values.password,
      game_id: process.env.REACT_APP_ORGANIZATION_ID
    }
    values.handleSignupUser({ data })
    setSubmitting(false)
  },
  displayName: 'PersonalDataForm'
})

export const AuthRequestEnhancer = withFormik({
  validationSchema: Yup.object().shape({
    email: Yup.string()
      .email('signup.invalid_email')
      .required('signup.email_required'),
    password: Yup.string()
      .required('signup.password_required')    
  }),
  mapPropsToValues: props => ({
    loginUser: props.loginUser,
    responseGoogle: props.responseGoogle,
    status: props.status,
    email: '',
    password: '',
  }),
  handleSubmit: (values, { setSubmitting }) => {
    values.loginUser(values.email, values.password)
    setSubmitting(false)
  },
  displayName: 'LoginForm'
})