import React from 'react'
import styled from 'styled-components'
import t from '../../../utils/i18n'
import Select from '../../../components/Select'
import Button from '../../../components/Button'
import Stepper from '../../../components/Stepper'
import OnboardingStepHeader from './OnboardingStepHeader'
import AuthLayout from '../../../components/AuthLayout'
import RightArrow from '../../../components/GlobalIcons/RightArrow'

import { Link } from 'react-router-dom'



//TO DO: create a provider or a component for this wrapper
const ContentWrapper = styled.div`
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  z-index: 10;
  
  @media (max-width: 768px){
    width: 100%;
  }
`

const Div = styled.div`
  box-sizing: border-box;
  width: 100%;
  padding: ${ props => props.theme.spacing.medium} 0;
`

const ButtonContainer = styled.div`
  padding-top: ${ props => props.theme.spacing.large};
  display: flex;
  flex-direction: column;
  align-items: center;
`

const ButtonWrapper = styled.div`
  width: 100%;
  padding: ${props => props.theme.spacing.small} 0;
`

const StepperWrapper = styled.div`
  padding: ${props => props.theme.spacing.medium} 0;
  text-align: center;
`

const AloneWrapper = styled.div`
  padding: ${props => props.theme.spacing.small};
  display: flex;
  flex-direction: column;
  align-items: center;

  > span {
    font-size: ${props => props.theme.font_size.medium};
    color: ${props => props.theme.secondary};
    padding-bottom: ${props => props.theme.spacing.small};
    font-weight: bold;
  }
`

const ArrowButton = styled.button`
  border: none;
  border-radius: 50%;  
  background: ${props => props.theme.secondary};
  padding: ${props => props.theme.spacing.xs};
  cursor: pointer;

  > svg {
    transform: rotate(90deg);
    width: 22px;
    height: 16px;
  }
`

const StrippedLink = styled(Link)`
  text-decoration: none;
`

const SelectOrganization = ({ onChangeSelect, options, selectedOrganization, handleUserOrganizationHook, userId, handleUpdateUser, router }) => (
  <AuthLayout>
    <ContentWrapper>
      <Div>
      <OnboardingStepHeader/>
      <Select options={ options } handleChange={onChangeSelect} name="selectedOrganization" label={t('signup.institution')} />    
      <ButtonContainer>      
        <ButtonWrapper>
          <StrippedLink to={`/onboarding/create/request/${userId}`}>
            <Button white>{t('signup.no_institution')}</Button>
          </StrippedLink>
        </ButtonWrapper>
        <ButtonWrapper>
          <Button 
            disabled={!(selectedOrganization && selectedOrganization.length > 0)}
            onClick={()=>handleUserOrganizationHook({userId, organizationId: selectedOrganization}) } 
          >{t('signup.cta')}</Button>
        </ButtonWrapper>
      </ButtonContainer>
      <AloneWrapper>
        <span>{t('signup.solo_player')}</span>
        <ArrowButton onClick={() => handleUpdateUser({userId, role: 'alone', router})}>
          <RightArrow color='white'/>
        </ArrowButton>
      </AloneWrapper>
      <StepperWrapper>
        <Stepper
          current={2}
          total={2}
        />
      </StepperWrapper>
      </Div>
    </ContentWrapper>
  </AuthLayout>
)

export default SelectOrganization