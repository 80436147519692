import React from "react";
import styled from "styled-components";
import Axis from "../../../components/AxisCard";

const Dashboard = ({ data, progress }) => {
  return (
    <Wrapper>
      {data.game.axes &&
        data.game.axes.map((axis, i) => {
          return axis.questions.length ? (
            <Axis
              data={axis}
              index={i}
              key={axis._id}
              userProgress={progress[axis._id + ""]}
            />
          ) : (
            <></>
          );
        })}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding: ${(props) => props.theme.spacing.medium};
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  grid-gap: 20px;
  justify-content: center;

  @media (max-width: 600px) {
    padding-top: 0;
    padding-bottom: 100px;
  }

  @media (min-width: 601px) and (max-width: 960px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 375px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

export default Dashboard;
