import React from "react";
import styled from "styled-components";

function Icon({ color }) {
  return (
    <StartFilled>
      <svg
        width="14"
        height="11"
        viewBox="0 0 14 11"
        fill={color}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M1.3125 0.3125C1.19647 0.3125 1.08519 0.358594 1.00314 0.440641C0.921094 0.522688 0.875 0.633968 0.875 0.75V4.95C0.875 5.23727 0.931581 5.52172 1.04151 5.78712C1.15145 6.05252 1.31258 6.29367 1.5157 6.4968C1.92594 6.90703 2.48234 7.1375 3.0625 7.1375H11.6314L8.70275 10.0653C8.6206 10.1474 8.57445 10.2588 8.57445 10.375C8.57445 10.4912 8.6206 10.6026 8.70275 10.6847C8.7849 10.7669 8.89632 10.8131 9.0125 10.8131C9.12868 10.8131 9.2401 10.7669 9.32225 10.6847L12.9972 7.00975C13.038 6.96911 13.0703 6.92083 13.0924 6.86768C13.1144 6.81453 13.1258 6.75755 13.1258 6.7C13.1258 6.64245 13.1144 6.58547 13.0924 6.53232C13.0703 6.47917 13.038 6.43089 12.9972 6.39025L9.49725 2.89025C9.4151 2.8081 9.30368 2.76195 9.1875 2.76195C9.07132 2.76195 8.9599 2.8081 8.87775 2.89025C8.7956 2.9724 8.74945 3.08382 8.74945 3.2C8.74945 3.31618 8.7956 3.4276 8.87775 3.50975L11.6314 6.2625H3.0625C2.7144 6.2625 2.38056 6.12422 2.13442 5.87808C1.88828 5.63194 1.75 5.2981 1.75 4.95V0.75C1.75 0.633968 1.70391 0.522688 1.62186 0.440641C1.53981 0.358594 1.42853 0.3125 1.3125 0.3125Z"
          fill={color}
        />
      </svg>
    </StartFilled>
  );
}

const StartFilled = styled.span`
  fill: ${(props) => props.color || props.theme.primary || "black"};
`;

export default Icon;
