import { gql } from 'apollo-boost'

export const GET_AXIS = gql`
  query axis($_id: String!){
    axis(_id: $_id){
      name
      pallete {
        primary
      }
      achievements {
        _id
        question{
          _id
        }
      }
      questions{
        content
        _id
        answers{
          content
          _id
          next{
            _id
          }
          suggestion{
            _id
          }
          achievement{
            _id
            title
            description
          }
        }
        from{
          _id
        }
      }
      lastQuestion
    }
    user{
      progress{
        achievement{
          _id
          question{
            _id
          }
          axis{
            _id
          }
        }
      }
    }
  }
`

export const GET_AXIS_NAME = gql`
  query axis($_id: String!) {
    axis(_id: $_id) {
      name
    }
  }
`