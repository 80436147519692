import React from 'react'
import SelectOrganization from './SelectOrganization'

const Onboarding = ({options, handleUserOrganizationHook, onChangeSelect, selectedOrganization, userId, handleUpdateUser, router}) => (
  <div>
    <SelectOrganization  
      options={options}
      userId={userId}
      handleUserOrganizationHook={handleUserOrganizationHook}
      onChangeSelect={onChangeSelect} 
      selectedOrganization={selectedOrganization}
      handleUpdateUser={handleUpdateUser}
      router={router}
    />
  </div>
)

export default Onboarding