import React from "react";

function Icon() {
  return (
    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M9 13C9 12.8674 9.05268 12.7402 9.14645 12.6464C9.24021 12.5527 9.36739 12.5 9.5 12.5H15.293L13.146 10.354C13.0521 10.2601 12.9994 10.1328 12.9994 9.99999C12.9994 9.86722 13.0521 9.73988 13.146 9.64599C13.2399 9.5521 13.3672 9.49936 13.5 9.49936C13.6328 9.49936 13.7601 9.5521 13.854 9.64599L16.854 12.646C16.9006 12.6924 16.9375 12.7476 16.9627 12.8084C16.9879 12.8691 17.0009 12.9342 17.0009 13C17.0009 13.0658 16.9879 13.1309 16.9627 13.1916C16.9375 13.2524 16.9006 13.3075 16.854 13.354L13.854 16.354C13.7601 16.4479 13.6328 16.5006 13.5 16.5006C13.3672 16.5006 13.2399 16.4479 13.146 16.354C13.0521 16.2601 12.9994 16.1328 12.9994 16C12.9994 15.8672 13.0521 15.7399 13.146 15.646L15.293 13.5H9.5C9.36739 13.5 9.24021 13.4473 9.14645 13.3535C9.05268 13.2598 9 13.1326 9 13Z" fill="white"/>
    <rect x="0.5" y="0.5" width="25" height="25" rx="12.5" stroke="white"/>
    </svg>
    
  );
}

export default Icon;
