import React from 'react'
import styled, { keyframes } from 'styled-components'
import { withTranslation } from 'react-i18next'
import Triangle from './components/Triangle'
import StretchedCard from '../../../../components/StretchedCard'
import Title from '../../../../components/Title'
import Text from '../../../../components/Text'
import House from '../../../../components/GlobalIcons/House'
import PageTitle from '../../../../components/PageTitle'

const WelcomeMessage = ({show, name, t }) => {  
  return (
    <>
    {/* <HeaderWrapper>
      <House /><Title padding={'0'}>{t('home.new_axes')}</Title>
    </HeaderWrapper> */}
    <PageTitle>
      <House />
      <Title padding={'0'}>{t('home.new_axes')}</Title>
    </PageTitle>
    <StretchedCard icon="💫">
      {/* <Title padding={'0.6rem 0 0 0'}>👋 Hola, {name}</Title> */}
      <Title padding={'0.6rem 0 0 0'}>👋 {t('home.welcome.title', { user: name })}</Title>
      <Text fontSize={'medium'} padding={'0.3rem 0'}>{t('home.welcome.get_started')}</Text>
    </StretchedCard>
    </>
  )
}

const Wrapper = styled.div``



const TriangleWrapper = styled.div`
  position: absolute;
  right: 20px;
  bottom: -41px;
`

const UnderscoreAnimation = keyframes`
  0% { opacity: 0 }
  100% { opacity: 1 }
`

const Underscore = styled.span`
  animation: ${UnderscoreAnimation} 0.5s  infinite;
`
const HeaderWrapper = styled.div`
padding: 0 2rem;
margin-top: 2rem;
  display: flex;
  align-items: center;

  > h1 {
    margin-left: 1rem;
  }`


export default withTranslation()(WelcomeMessage)