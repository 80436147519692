import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { lighten } from "polished";
import Award from "../GlobalIcons/Award";
import ContinueIcon from "../GlobalIcons/ContinueIcon";
import StartIcon from "../GlobalIcons/StartIcon";
import t from "../../utils/i18n";

const UserProgress = ({ progress, id, color, onClickButton }) => {

  const totalPer = (100 / progress.total) * progress.user || 0;
  const buttonText = totalPer == 100 ? t("axis_buttons.finished") : (progress.total > 0 ? t("axis_buttons.continue") : t("axis_buttons.start"));

  // first line transition

  const [progressWidth, setProgressWidth] = useState(0);

  useEffect(() => {
    setProgressWidth(totalPer > 100 ? 100 : totalPer);
  }, [totalPer]);
  

  // const [firstLine, handleFirstLine ] = useState(0)
  // useEffect(() => {
  //   setTimeout(() => {
  //     handleFirstLine(totalPer * 100 / 30 + '%')
  //   }, 300)
  // })

  // // first line transition
  // const [secondLine, handleSecondLine ] = useState(0)
  // useEffect(() => {
  //   setTimeout(() => {
  //     handleSecondLine(totalPer > 70 ? ((totalPer - 70) * 100 / 30 + '%') : 0)
  //   }, 420)
  // })

  return (
    <>
      <BackTotal>
        {/* <Rectangle
      id={id}
      color={color}
      current={totalPer > 30 ? ((totalPer - 30) * 100 / 40) : 0}
    /> */}
        <Info>
          <div>
            <Award width="15" height="12" />
          </div>
          <ProgressData>
            {progress.user} / {progress.total}
          </ProgressData>
        </Info>
      </BackTotal>
      <Wrapper>
        <BackLine>
          <ProgressLine
            progress={progressWidth}
            gradient={true}
            color={color}
          />
        </BackLine>
        {/* <BackLine>
        <ProgressLine
          progress={secondLine}
          color={color}
        />
      </BackLine> */}
      </Wrapper>
      <ButtonWrapper>
        <Button type={buttonText} onClick={onClickButton}>
          {buttonText === t("axis_buttons.start") ? (
            <StartIcon color="white" />
          ) :
          
          buttonText === t("axis_buttons.continue") ? (
            <ContinueIcon />
          ) :
          (
          buttonText === t("axis_buttons.finished") ? (
            ''
          ) : null
          )}
          {buttonText}
        </Button>
      </ButtonWrapper>
    </>
  );
};
const Button = styled.div`
  background: ${(props) => props.type === "Continuar..." ? "white" : props.theme.primary};
  border: 1px solid${(props) => props.type === "Continuar..." ? props.theme.primary : "transparent"};
  color: ${(props) => props.type === "Continuar..." ? props.theme.primary : "white"};
  display: flex;
  gap: 8px;
  cursor: pointer;
  padding: 0.7rem;
  width: fit-content;
  border-radius: ${(props) => props.theme.border_radius.rounded_sm};
`;

const Wrapper = styled.div`
  display: flex;
  padding: 1rem 0;
  align-items: center;
  justify-content: center;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: end;
  margin-top: 10px;
  right: 0;
`;

const BackTotal = styled.div`
  margin-top: 20px;
`;

const Info = styled.div`
  border: 1px solid ${(props) => props.theme.primary}
  border-radius: ${(props) => props.theme.border_radius.rounded_lg};
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;  
  color: #424242;
  width: fit-content;
  padding: 0 10px;
  height: 34px;
  }
`;
const BackLine = styled.div`
  position: relative;
  height: 5px;
  width: 100%;
  border-radius: 4px;
  background: #d8d8d8;
`;
const ProgressData = styled.div`
  color: ${(props) => props.theme.primary};
  margin-left: 1rem;
`;
const ProgressLine = styled(BackLine)`
  position: absolute;
  background: ${(props) => props.theme.primary};
  // background: ${(props) =>
    props.gradient
      ? `linear-gradient(to right, ${lighten(0.3, props.color.primary)} 30%, ${
          props.color.primary
        })`
      : `linear-gradient(to left, ${lighten(0.3, props.color.primary)} 30%, ${
          props.color.primary
        })`};

  width: ${(props) => props.progress}%;
  max-width: 100%;
  transition: width 0.5s;
`;

export default UserProgress;
