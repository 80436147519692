import React from "react";
import { withTheme } from 'styled-components'

const AxisIcon = (props) => {
  return (
    <svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_355_615)">
    <path d="M21.5 11.5C21.5001 12.0543 21.316 12.593 20.9766 13.0313C20.6372 13.4696 20.1617 13.7826 19.625 13.9212V20.28C20.7038 20.3837 21.5 20.7512 21.5 21.1875C21.5 21.705 20.3813 22.125 19 22.125C17.6188 22.125 16.5 21.705 16.5 21.1875C16.5 20.75 17.2963 20.3837 18.375 20.28V13.9212C17.9223 13.8043 17.5114 13.5628 17.1891 13.2242C16.8667 12.8855 16.6458 12.4632 16.5515 12.0053C16.4571 11.5473 16.493 11.0721 16.6552 10.6336C16.8173 10.195 17.0992 9.81075 17.4688 9.52438C17.8384 9.23802 18.2809 9.06104 18.7461 9.01354C19.2112 8.96605 19.6803 9.04995 20.1002 9.25571C20.52 9.46146 20.8738 9.78084 21.1212 10.1776C21.3686 10.5743 21.4999 11.0324 21.5 11.5Z" fill="#1F2937"/>
    <path d="M9 21.0813V23.2275C9.00012 23.4596 9.06485 23.6871 9.18694 23.8845C9.30903 24.0818 9.48365 24.2413 9.69125 24.345L17.8825 28.4413C18.2295 28.6147 18.6121 28.705 19 28.705C19.3879 28.705 19.7705 28.6147 20.1175 28.4413L28.3088 24.345C28.5163 24.2413 28.691 24.0818 28.8131 23.8845C28.9351 23.6871 28.9999 23.4596 29 23.2275V21.0813C29 20.9563 28.925 20.8438 28.81 20.7938L20.875 17.3938V18.635L27.4088 21.3575C27.437 21.3694 27.4611 21.3894 27.4781 21.4148C27.4951 21.4403 27.5043 21.4702 27.5044 21.5009C27.5045 21.5315 27.4956 21.5615 27.4789 21.5872C27.4621 21.6128 27.4381 21.6329 27.41 21.645L19.985 24.8275C19.6738 24.961 19.3386 25.0298 19 25.0298C18.6614 25.0298 18.3262 24.961 18.015 24.8275L10.59 21.645C10.5619 21.6329 10.5379 21.6128 10.5211 21.5872C10.5044 21.5615 10.4955 21.5315 10.4956 21.5009C10.4957 21.4702 10.5049 21.4403 10.5219 21.4148C10.5389 21.3894 10.563 21.3694 10.5912 21.3575L17.125 18.635L17.1088 17.4L9.19 20.7938C9.13363 20.8178 9.08557 20.8579 9.05179 20.909C9.01801 20.9601 9 21.02 9 21.0813V21.0813Z" fill="#1F2937"/>
    </g>
    <defs>
    <clipPath id="clip0_355_615">
    <rect width="20" height="20" fill="white" transform="translate(9 9)"/>
    </clipPath>
    </defs>
    </svg>
  );
}

export default AxisIcon;
