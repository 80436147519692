import React, {useRef, useState} from 'react'
import styled from 'styled-components'
import Select from 'react-select'


const Wrapper = styled.div`
  position: relative;
  margin-bottom: 0.7rem;  
  height: 3.4rem;
`
const Label = styled.label`
  display: block;
  font-size: 0.8rem;
  margin-bottom: 0.5rem;
  font-family: sans-serif;
  color: #c2c2c2;
`

const customStyles = {
  control: (base, state) => ({
    ...base,
    color: '#5B5E62',
    border: 'none',
    boxShadow: state.isFocused ? 0 : 0,
    borderBottom: '1.5px solid #d2d7dc',
    borderRadius: 'none',
    fontSize: '1.2rem',
    padding: '0.5rem 0 0.8rem 0'
  })
}

const CustomSelect = ({ label, handleChange, name, options}) => {
  const [ isShow, showLabel] = useState(false)
  const labelEl = useRef(null)
  const handleOnChange = (ev) => {
    handleChange(name, ev.value)
    showLabel(true)
  }
  return (
    <Wrapper>
      { isShow && <Label ref={labelEl}>{label}</Label> }
      <Select 
        placeholder={label}
        onChange={(ev) => handleOnChange(ev)} 
        name={name}
        options={options}
        styles={customStyles}
        components={{IndicatorSeparator: () => null}}
      />
    </Wrapper>
  )
}

export default CustomSelect