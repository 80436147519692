import React from 'react'
import QuestionCard from '../../components/QuestionCard'
import PathFinished from '../../components/PathFinished'
import { GET_SUGGESTION } from '../../queries/suggestion'
import SuggestedMaterial from './SuggestedMaterial'
import AchievementComponent from '../Achievement'
import { findQuestionsByAchievements } from '../../utils/helpers'
import styled from 'styled-components'


class QuestionContainer extends React.Component{

  constructor(props){
    super(props)
    this.state = {
      selectedSuggestion: null, 
      isSuggestionModalOpen: false,

      questions: this.props.data,
      selectedQuestion: this.sortQuestions(),
      answer: '',
      suggestion: {
        isOpen: false,
        data: {}
      },
      achievement: {
        isOpen: false,
        data: {}
      },
      userAchievementsCount: this.props.userAchievements,
      axisComplete: false
    }
  }




  componentDidMount(){
    const { leftAch, lastQuestion } = this.props
    if(leftAch.length === 0 && !lastQuestion){
      return this.setState({
        axisComplete: true
      })
    }
  }

  sortQuestions = () => {
    const lastQuestion = this.props.lastQuestion
    if(!lastQuestion && this.props.leftAch.length > 0){
      const achQuestions = findQuestionsByAchievements(this.props.data, this.props.leftAch)
      return achQuestions.shift()
    }
    const questions = this.props.data
    return questions.find( q => q._id == lastQuestion)
  }
  
  handleOnNextQuestion = () => {
    const { selectedQuestion, questions, answer } = this.state
    const { lastQuestion , leftAch, data} = this.props

    // Prevent app to crush when there is not answer
    if (!answer) {
      return
    }

    this.props.updateUserProgress({answer})

    const answersArr = selectedQuestion.answers
    const selectedAnswer = answersArr.find( a => a._id === answer)
    
    if(selectedAnswer.suggestion){
      this.handleEventChange({name: 'suggestion', data: selectedAnswer.suggestion, open: true})
    }
    if(selectedAnswer.achievement){
      this.handleEventChange({name: 'achievement', data: selectedAnswer.achievement, open: true})
      const { userAchievementsCount } = this.state

      this.setState({
        userAchievementsCount: userAchievementsCount + 1
      })
    }

    if(!selectedAnswer.next){
      return this.setState({
        axisComplete: true
      })
    }

    if(!lastQuestion && leftAch.length > 0){
      const achQuestions = findQuestionsByAchievements(data, leftAch)
      const nextAchievement = achQuestions.find( q => q._id !== selectedQuestion._id)
      if(!nextAchievement){
        this.setState({
          axisComplete: true
        })
      }else{
        return this.setState({
          selectedQuestion: nextAchievement,
          answer: ''
        })
      }
    }
    this.setState({
      selectedQuestion: selectedAnswer.next && questions.find( q => q._id === selectedAnswer.next._id),
      answer: ''
    })
  }

  handleOnChange = (ev) => {
    this.setState({
      answer: ev.target.value
    })
  } 

  handleEventChange = (ev) => {
    this.setState({
      [ev.name]: {
        isOpen: ev.open,
        data: ev.data
      }
    })
  }




  render() {
    const { 
      questions,
      name,
      selectedQuestion,
      answer,
      suggestion,
      achievement,
      axisComplete,
      userAchievementsCount
    } = this.state
    const {
      totalAchievements
    } = this.props
    console.log(suggestion)
     
    return (
      <Wrapper>
        {axisComplete ? <PathFinished userAchievements={userAchievementsCount}  totalAchievements={totalAchievements}/> : 
          <React.Fragment>
            {(!achievement.isOpen || !suggestion.isOpen) && 
              <QuestionCard 
                history={this.props.history}
                questions={questions}
                name={this.props.name}
                selectedQuestion={selectedQuestion}
                handleOnNextQuestion={this.handleOnNextQuestion}
                onCheckedAnswer={this.handleOnChange}
                selectedAnswer={answer}
              />
            }
          </React.Fragment>
        }
        {suggestion.isOpen && (
           // <Modal suggestionReceived={suggestion.data._id} handleModalClose={this.handleEventChange} />
           <SuggestedMaterial suggestion={suggestion.data._id} handleModalClose={this.handleEventChange} />
        )}


        {achievement.isOpen && (
          <AchievementComponent 
            achievement={achievement.data}
            handleModalClose={this.handleEventChange}
          />
        )}
      </Wrapper>
    )
  }
}

const Wrapper = styled.div`
  position: relative;
  height: 100%;
`

export default QuestionContainer