import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
`

const Dot = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin: 0 ${props => props.theme.spacing.xs};
  background: ${props => props.current ? props.theme.primary : props.theme.primary + '66'};
`

const Stepper = (props) => {
  return (
    <Wrapper>
      { Array(props.total).fill(props.total).map((el, index) => (
        <Dot key={index} current={index + 1 === props.current}/>
      )) }
    </Wrapper>
  )
}

Stepper.defaultProps = {
  total: 1,
  current: 1
}


export default Stepper