import React from 'react'
import styled from 'styled-components'
import Signup from './Signup'
import Stepper from '../../../components/Stepper'
import AuthLayout from '../../../components/AuthLayout'


const SignupWrapper = styled.div`
  background-color: white;
  box-sizing: border-box;
  display: inline-block;
  z-index: 10;
  @media (max-width: 768px){
    width: 100%;
  }
`

const StepperWrapper = styled.div`
  padding: ${props => props.theme.spacing.small};
`

class SignupPreviewContainer extends React.Component {
  render(){
    const { error, status, signupUser } = this.props
    return(
      <AuthLayout>
        <SignupWrapper>
          <Signup handleSignupUser={signupUser} error={error} status={status} />
          <StepperWrapper>
            <Stepper
              total={2}
              current={1}
            />
          </StepperWrapper>
        </SignupWrapper>
      </AuthLayout>
    )
  }
}

export default SignupPreviewContainer