import React from 'react'
import styled from 'styled-components'
import Button from '../../../components/Button'
import ErrorMessage from '../../../components/ErrorMessage'
import EmailIcon from '../../../components/GlobalIcons/Email'
import TextInput from '../../../components/TextInput'


const ForgotPassword = ({ handleChange, submit, submitting, email, error, success, t}) => (
  <Form onSubmit={submit}>
    { success ? (
      <Title>
        {t('auth.forgot.success')}
      </Title>
    ) : (
    <React.Fragment>
      <FormTitle>{t('auth.forgot.title')}</FormTitle>       
      <TextInput
        type="text"
        name="email"
        label={t("login.email")}
        values={email}
        onChange={handleChange}
        icon={(<EmailIcon color="secondary" />)}
      />

      { error && (
        <ErrorMessage message={error} />
      )}
              
      <ButtonWrapper>
        <Button
          disabled={submitting}
          loading={submitting}
        >
          {t('auth.forgot.button')}
        </Button>
      </ButtonWrapper>
    </React.Fragment>
    )}     
  </Form>
)



const Title = styled.h1`
  color: ${props => props.theme.secondary};
  font-size: 1.2rem;
  font-weight: 400;
  margin: 0;
  padding: 1rem 0;
  text-align: center;
`

const Subtitle = styled.h2`
  color: #616161;
  font-size: 1.25rem;
  margin: 0.5rem 0 0 0.5rem;
  opacity: 0.7;
`

const FormTitle = styled.h2`
  color: ${props => props.theme.secondary};
  font-size: 1.2rem;
  font-weight: 400;
  margin: 0;
  padding: 1rem 0;
  text-align: left;
`


const Form = styled.form`
  width: 100%;
  margin: 1rem auto 0;
`

const ButtonWrapper = styled.div`
  padding: 1rem 0;
`

export default ForgotPassword