import React from 'react'
import Button from '../Button'
import styled from 'styled-components'
import { NavLink } from 'react-router-dom'
import t from '../../utils/i18n'

const NotFound = () => (
  <Wrapper>
    <Heading>{t('not_found_title')}</Heading>
    <StyledLink to='/'>
      <Button outline color="#7E4DDB">{t('not_found_cta')}</Button>
    </StyledLink>
  </Wrapper>
)

const StyledLink = styled(NavLink)`

`
const Wrapper = styled.div`
  height: 100vh;
  font-family:  'Roboto', sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Heading = styled.h3`
  font-size: 4rem;
`

export default NotFound