import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { isEmpty } from 'ramda'
import * as actions from '../actions'
import Navbar from '../../../components/Navbar'
import Sidebar from '../../../components/Sidebar'
import BottomBar from '../../../components/BottomBar'
import styled from 'styled-components'
import devices from '../../../utils/devices'
import { CurrentAxisContext } from '../../../components/Context'

const Wrapper = styled.div`
  display: flex;
  background: red;
  background: ${props => props.theme.light};
`

const SidebarWrapper = styled.div`
  display: none;

  @media ${devices.laptop} {
    display: block;
    border-right: 2px solid ${props => props.theme.primary};
  }
`

const ChildrenWrapper = styled.div`
@media (max-width: 600px) {
  margin-top: 2rem; 
  padding-bottom: 20px
}
`

const BodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  flex: 1;
  overflow-y: auto;
  @media (max-width: 600px) {
    // margin-top: 3rem; 
    overflow-y: scroll;
  }
`

class MainLayout extends React.Component{
  constructor(props){
    super(props)

    this.state = {
      showNavbar: false,
      currentAxisColor: null
    }
  }

  componentDidMount(){
    const router = this.props.history
    if(isEmpty(this.props.user)){
      this.props.fetchUserData( router )
    }
    const activeRoute = router.location.pathname
    this.setState({
      showNavbar: !activeRoute.includes('/onboarding')
    })
  }

  componentDidUpdate(prevProps){
    const activeRoute = this.props.location.pathname

    if(prevProps.location.pathname != activeRoute){
      this.setState({
        showNavbar: !activeRoute.includes('/onboarding'),
        isOpen: false
      })
    }
  }

  handleOpen = () => {
    this.setState({
      isOpen: !this.state.isOpen
    })  
  }

  toggleAxisColor = (color) => {
    this.setState({
      currentAxisColor: color
    })
  }

  render(){
    const { isOpen, showNavbar } = this.state
    const { children, handleLogout, user } = this.props
    return(
      <CurrentAxisContext.Provider 
        value={{
          currentAxisColor: this.state.currentAxisColor,
          toggleAxisColor: this.toggleAxisColor
        }}
      >
      <Wrapper>

        { showNavbar && (
          <SidebarWrapper>        
            <Sidebar 
              handleLogout={handleLogout} 
              history={this.props.history}
              user={user.user} 
              isOpen={isOpen} 
            />
          </SidebarWrapper>
        )}       
        
         
        
        <BodyWrapper>
          {showNavbar && <Navbar 
            activeRoute={this.props.location.pathname}
            currentAxisColor={this.state.currentAxisColor}
          />}

          <ChildrenWrapper>
            {children}
          </ChildrenWrapper>                    

          {/* only mobile ! */}
          { showNavbar && (
            <BottomBar
              history={this.props.history}
              handleLogout={handleLogout} 
               user={user.user}
            />
          )}          
          {/* only mobile ! */}
        </BodyWrapper>
      </Wrapper>
      </CurrentAxisContext.Provider>
    )
  }
}

const mapStateToProps = state => ({
  status: state.layout.status,
  error: state.layout.error,
  user: state.layout.user
})

const mapDispatchToProps = dispatch => ({
  fetchUserData: router => dispatch(actions.fetchUserData({ router }))
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MainLayout))