import React from 'react'
import styled from 'styled-components'
import AxisSkeletonCard from '../AxisSkeletonCard';

const AxisSkeletonGrid = () => (
  <Wrapper>
    <AxisSkeletonCard />
    <AxisSkeletonCard />
    <AxisSkeletonCard />
    <AxisSkeletonCard />
    <AxisSkeletonCard />
    <AxisSkeletonCard />
  </Wrapper>
)

const Wrapper = styled.div`
  padding: ${props => props.theme.spacing.small};
  padding-top: ${props => props.theme.spacing.large};
  display: grid;
  grid-template-columns: repeat(2, 150px);
  grid-gap: 30px;
  grid-auto-rows: minmax(150px, auto);

  justify-content: center;

  @media (min-width: 640px){
    grid-template-columns: repeat(3, 150px);
  }
  @media (min-width: 960px){
    grid-template-columns: repeat(3, 300px);
    grid-auto-rows: minmax(300px, auto);
  }
`
export default AxisSkeletonGrid