import { connect } from 'react-redux'

import * as actions from '../actions'
import * as selectors from '../selectors'

import SignupPreview from '../components/SignupPreviewContainer'

const mapStateToProps = state => ({
  status: selectors.status(state),
  error: selectors.error(state),
  step: selectors.step(state)
})

const mapDispatchToProps = dispatch => ({
  signupUser: data=> dispatch(actions.signupUser( data )),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SignupPreview)