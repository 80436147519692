import auth from '../modules/auth/index'
import onboarding from '../modules/onboarding/index'
import layout from '../modules/MainLayout/index'
import { fork, all } from 'redux-saga/effects'

function startSagas(...sagas) {
  return function * rootSaga() {
    yield all(sagas.map(saga => fork(saga)))
  }
}

export default startSagas(
  ...auth.sagas,
  ...onboarding.sagas,
  ...layout.sagas
)