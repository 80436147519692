import reducer from './reducers'
import login from './scenes/Auth'
import signupPreview from './scenes/SignupPreview'
import forgotPassword from './scenes/ForgotPassword'
import resetPassword from './scenes/ResetPassword'
import sagas from './sagas'
import * as constants from './constants'
import * as selectors from './selectors'

export default {
  selectors,
  constants,
  reducer,
  scenes: {
    login,
    signupPreview,
    forgotPassword,
    resetPassword
  },
  sagas: [
    ...sagas
  ]
}