import React from "react";
import styled, { withTheme } from "styled-components";
import t from "../../utils/i18n";
import Answer from "./answer";
import Collapse from "./collapse";
import { CurrentAxisContext } from "../Context";
import Title from "../Title";
import StretchedCard from "../StretchedCard";
import Text from "../Text";
import RigthArrow from "../GlobalIcons/ChevronRight";
import ArrowLeft from "../GlobalIcons/ArrowReturnLeft";

const QuestionCard = ({
  selectedQuestion,
  handleOnNextQuestion,
  onCheckedAnswer,
  history,
  selectedAnswer,
  name,
  ...props
}) => {
  const next = t("question.next")

  return (
    <>
    <ButtonTop>
      <ChevronWrapper onClick={() => history.goBack()}>
        <ArrowLeft />
      </ChevronWrapper>
      <Title padding={"0"}>{name}</Title>
      </ButtonTop>
    
      <Wrapper>
        {selectedQuestion && (
          <React.Fragment>
            <CurrentAxisContext.Consumer>
              {({ currentAxisColor }) => (
                <StretchedCard
                  margin={"1rem 0 0 0"}
                  currentAxis={currentAxisColor}
                >
                  <Title fontSize={"medium"} padding={"10px"}>
                    {selectedQuestion.content}
                  </Title>
                  {selectedQuestion.description && (
                    <React.Fragment>
                      <QuestionSubtitle>
                        {selectedQuestion.description}
                      </QuestionSubtitle>
                      <Collapse />
                    </React.Fragment>
                  )}
                </StretchedCard>
              )}
            </CurrentAxisContext.Consumer>
            <StyledList>
              {selectedQuestion.answers.map((answer, key) => (
                <Answer
                  key={key}
                  answer={answer}
                  onCheckedAnswer={onCheckedAnswer}
                  selectedAnswer={selectedAnswer}
                />
              ))}
            </StyledList>
            <ButtonsContainer>
              <StyledButton
                disabled={!selectedAnswer}
                onClick={handleOnNextQuestion}
                background={props.theme.secondary}
              >
                <Text fontSize={"medium"} color={"white"}>
                  {next}
                </Text>
                <RigthArrow />
              </StyledButton>
            </ButtonsContainer>
          </React.Fragment>
        )}
      </Wrapper>
    </>
  );
};

const Wrapper = styled.div`
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const ButtonsContainer = styled.div`
  display: flex;
  margin: 30px;
`;

const ButtonTop = styled.div`
display: flex;
align-items:center;
padding-bottom: 20px;
  margin-top:30px
  margin-left: 30px
`;

const StyledButton = styled.button`
  display: flex;
  align-items: center;
  gap: 15px;
  border: none;
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1),
    0px -6px 6px -2px rgba(0, 0, 0, 0.05);
  background: ${(props) => props.theme.primary};
  cursor: pointer;
  color: white;
  padding: 0.6rem 0.8rem;
  width: fit-content;
  border-radius: ${(props) => props.theme.border_radius.rounded_sm};
  :disabled {
    opacity: 0.5;
    pointer-events: none;
  }
`;

const QuestionTitle = styled.div`
  box-sizing: border-box;
  background: ${(props) =>
    props.currentAxis ? props.currentAxis : props.theme.primary};
  color: ${(props) => props.theme.white};
  font-weight: bold;
  padding: 1rem;
  text-align: center;
  width: 100%;
  max-width: 100vw;
`;

const TitleText = styled.span`
  display: inline-block;
  max-width: 680px;
  padding: 1rem;
  line-height: 1.25rem;
  text-align: left;
`;

const QuestionSubtitle = styled.div`
  font-weight: normal;
  margin: 0 auto;
  padding: 1rem 0%;
  width: 300px;
`;

const StyledList = styled.div`
  margin-top: 2rem;
  padding: 0 1rem;
  max-width: 680px;
`;

const ChevronWrapper = styled.div`
  top: 62px;
  margin-left: 0.5rem;
  margin-right: 2rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  padding: 1rem;
  width: 20px;
  height: 20px;
  cursor: pointer;
  background: ${(props) => props.theme.primary};
`;

export default withTheme(QuestionCard);
