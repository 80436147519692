import { connect } from 'react-redux'

import * as actions from '../actions'
import * as selectors from '../selectors'
import OnboardingContainer from '../components/OnboardingContainer'

const mapStateToProps = state => ({
  list: selectors.list(state),
  status: selectors.status(state),
  error: selectors.error(state),
})

const mapDispatchToProps = dispatch => ({
  getOrganizations: () => dispatch(actions.getOrganizations()),
  userOrganizationHook: (userId, organizationId, router) => dispatch(actions.userOrganizationHook({ userId, organizationId, router})),
  createOrganization: data => dispatch(actions.createOrganization( data )),
  updateUser: data => dispatch(actions.updateUser(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(OnboardingContainer)