import React from 'react'
import {withRouter} from 'react-router'
import CreateOrganization from './CreateOrganization'

class CreateOrganizationContainer extends React.Component{
  constructor(props){
    super(props)
    this.state = {
      userId: this.props.match.params.userId
    }
  }

  render(){
    const { createOrganization, status, error, history } = this.props
    const { userId } = this.state

    return(
      <CreateOrganization         
        createOrganization={createOrganization}
        status={status}
        error={error}
        userId={userId}
        handleRouter={history}
      />
    )
  }
}

export default withRouter(CreateOrganizationContainer)