import React from 'react'
import Onboarding from './Onboarding'
import {withRouter} from 'react-router'
import { formatSelectOptions } from '../../../utils/helpers';

class OnboardingContainer extends React.Component{
  
  constructor(props){
    super(props)
    this.state = {
      options: [],
      selectedOrganization: '',
      userId: ''
    }
  }

  componentDidMount(){
    this.setState({
      userId: this.props.match.params.id
    })
    this.props.getOrganizations()
  }

  componentDidUpdate(prevProps){
    if(this.props.list !== prevProps.list){
      const formatedOptions = formatSelectOptions(this.props.list)
      this.setState({
        options: formatedOptions
      })
    }

  }
  onChangeSelect = (name, value) => {
    this.setState({
      [name]: value
    })
  }

  handleUserOrganizationHook = (data) => {
    this.props.userOrganizationHook(data.userId, data.organizationId, this.props.history)
  }

  render(){
    const { selectedOrganization, options, userId } = this.state
    const { onChangeSelect, handleUserOrganizationHook } = this
    const { updateUser, history } = this.props
    return(
      <Onboarding 
        options={options} 
        handleUserOrganizationHook={handleUserOrganizationHook}
        onChangeSelect={onChangeSelect}
        selectedOrganization={selectedOrganization}
        userId={userId}
        router={history}
        handleUpdateUser={updateUser}
      />
    )
  }
}

export default withRouter(OnboardingContainer)