import React, { useState } from 'react'
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'
import { ApolloProvider } from '@apollo/react-hooks'
import ApolloClient from 'apollo-boost'
import themes from '../utils/themes'
import ResumeGame from '../modules/Home/components/ResumeGame'
import { isAuthenticated, handleLogout } from '../utils/helpers'
import FetchGameWrapper from '../components/FetchGameWrapper'
import Login from '../modules/auth/index'
import NotFound from '../components/NotFound'
import Onboarding from '../modules/onboarding'
import Home from '../modules/Home'
import Settings from '../modules/Settings'
import Dashboard from '../modules/dashboard/scenes'
import MainLayout from '../modules/MainLayout/scenes'
import { Suggestions, Suggestion, SuggestionsList } from '../modules/Suggestions'
import Axis from '../modules/Axis';
import AboutUs from '../modules/AboutUs'

const client = new ApolloClient({
  request: (operation) => {
    const token = localStorage.getItem('token')
    operation.setContext({
      headers: {
        token: token ? `${token}` : '',
        game_id: process.env.REACT_APP_ORGANIZATION_ID
      }
    })
  },
  uri: `${process.env.REACT_APP_GAME_URL}/graphql`
})

const Root = () => {
  const [gameParams, toggleGameParams] = useState({
    palette: 'default',
    name: 'game',
    lang: 'en'
  })  

  return (
    <FetchGameWrapper onGameChange={(gameParams) => { toggleGameParams(gameParams) }}>
      <ApolloProvider client={client}>
        <ThemeProvider theme={themes[gameParams.palette]}>
          <Router>
            {isAuthenticated() && (
              <MainLayout handleLogout={handleLogout}>
                <Switch>
                  <Route exact path='/' component={Home} />                  
                  <Route path='/onboarding/list/:id' component={Onboarding.scenes.chooseOrganization} />
                  <Route path='/onboarding/create/request/:userId' component={Onboarding.scenes.createOrganization} ></Route>
                  <Route path={'/axis/:axisId'} component={Axis}></Route>
                  <Route exact path='/axis' component={Dashboard} />
                  <Route exact path={'/suggestions'} component={Suggestions} />
                  <Route path={'/suggestions/:axisId'} component={SuggestionsList} />
                  <Route path={'/suggestion/:id'} component={Suggestion} />  
                  <Route exact path='/about-us' component={AboutUs} />         
                  <Route path={'/settings'} component={Settings} />
                  <Route exact path={'/resume-game'} component={ResumeGame} />
                  <Route component={NotFound} />
                </Switch>
              </MainLayout>
            )}
            {!isAuthenticated() && (
              <Switch>
                <Route exact path='/' render={() => (<Redirect to='/login' />)} />
                <Route path='/login' component={Login.scenes.login} />
                <Route path='/signup_preview' component={Login.scenes.signupPreview} ></Route>
                <Route path='/signup/person/request' component={Login.scenes.signup} ></Route>
                <Route path='/forgot' component={Login.scenes.forgotPassword} ></Route>
                <Route path='/reset/:hash?' component={Login.scenes.resetPassword}></Route>
                <Route render={() => (<Redirect to='/login' />)} />
              </Switch>
            )}
          </Router>
        </ThemeProvider>
      </ApolloProvider>
    </FetchGameWrapper>
  )
}

export default Root