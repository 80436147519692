import { handleActions } from 'redux-actions'

import * as actions from './actions'

const initialState = {
  status: 'init',
  error: '',
  list: [],
  organization: {}
}

const reducer = handleActions({
  [actions.getOrganizations.START]: (state) => ({
    ...state,
    status: 'pending',
  }),
  [actions.getOrganizations.FAILURE]: (state, action) => ({
    ...state,
    status: 'failure',
    error: action.payload.error
  }),
  [actions.getOrganizations.SUCCESS]: (state, action) => ({
    ...state,
    status: 'success',
    list: action.payload.data
  }),
  [actions.userOrganizationHook.START]: (state) => ({
    ...state,
    status: 'pending',
  }),
  [actions.userOrganizationHook.FAILURE]: (state, action) => ({
    ...state,
    status: 'failure',
    error: action.payload.error
  }),
  [actions.userOrganizationHook.SUCCESS]: (state) => ({
    ...state,
    status: 'success',
  }),
  [actions.createOrganization.START]: (state) => ({
    ...state,
    status: 'pending',
  }),
  [actions.createOrganization.FAILURE]: (state, action) => ({
    ...state,
    status: 'failure',
    error: action.payload.error
  }),
  [actions.createOrganization.SUCCESS]: (state, action) => ({
    ...state,
    status: 'success',
    organization: action.payload.data
  }),
  [actions.updateUser.START]: (state) => ({
    ...state,
    status: 'pending'
  }),
  [actions.updateUser.FAILURE]: (state, action) => ({
    ...state,
    status: 'failure',
    error: action.error
  }),
  [actions.updateUser.SUCCESS]: (state) => ({
    ...state,
    status: 'success',
  })
}, initialState)

export default reducer