import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import t from '../../utils/i18n';
import Hiking from './assets/hiking';
import Bg from './assets/bg.svg';
import Title from '../Title';
import Text from '../Text';
import themes from '../../utils/themes';

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
  display: block; 
`;

const ModalContent = styled.div`
  background-color: white;
  // background-image: url(${Bg});
  // background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  box-sizing: border-box;
  display: flex;
  padding: 1rem;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
  justify-content: flex-start;
  overflow-x: hidden;
  max-width: 480px;
  position: relative;
  border-radius: 10px;
  margin: 1rem;
  z-index: 1;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  @media(max-width:600px){
    width: 280px;
    margin: 0 auto;
    text-align: center;
  }
`;

const Content = styled.span`
margin-bottom: 1rem;
`;

const Button = styled(Link)`
  display: inline-block;
  position: relative;
  overflow: hidden;
  background-color: ${(props) => props.theme.primary};
  border-radius: ${(props) => props.theme.border_radius.rounded_sm};
  color: white;
  cursor: pointer;
  font-weight: bold;
  padding: 0.90rem 1rem;
  outline: none;
  text-align: center;
  text-decoration: none;
  margin: 2rem 0 0 0;
`;

const Continue = () => (
  <ModalOverlay>
    <ModalContent>
      {/* <Hiking /> */}
      <Title color={'black'} padding={'1rem'}>{t('continue.title')}</Title>
      <Text fontSize={'medium'} color={'black'} textAlign={'center'}>{t('continue.subtitle')}</Text>
      <Content>
      <Button to="/suggestions">{t('continue.cta')}</Button>
      </Content>
    </ModalContent>
  </ModalOverlay>
);

export default Continue;