import React from 'react'
import styled from 'styled-components'
import t from '../../../utils/i18n'
import OrganizationRequestForm from '../forms/OrganizationRequestForm'
import OnboardingStepHeader from '../components/OnboardingStepHeader'
import AuthLayout from '../../../components/AuthLayout'


const ContentWrapper = styled.div`
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  z-index: 10;
`

const HeaderWrapper = styled.div`
  text-align: left;
  width: 100%;
`


const CreateOrganization = ({ createOrganization, error, status, userId, handleRouter}) => (
    <AuthLayout>
      <ContentWrapper>
        <HeaderWrapper>
          <OnboardingStepHeader
            subtitle={t('signup.institution_info')}
          />
        </HeaderWrapper>
        <OrganizationRequestForm
          createOrganization={createOrganization}
          status={status}
          error={error}
          userId={userId}
          handleRouter={handleRouter}
        />
      </ContentWrapper>    
    </AuthLayout>
  )

export default CreateOrganization