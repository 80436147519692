import React from 'react'
import styled from 'styled-components'
import { withTranslation } from 'react-i18next'
import { AuthRequestEnhancer } from '../../enhancers'

import { GoogleLogin } from 'react-google-login'
import TextInput from '../../../../components/TextInput'
import Button from '../../../../components/Button'
import ErrorMessage from '../../../../components/ErrorMessage'
import GoogleButton from '../../../../components/GoogleButton'
import { NavLink } from 'react-router-dom'
import EmailIcon from '../../../../components/GlobalIcons/Email'
import LockIcon from '../../../../components/GlobalIcons/Lock'


const Subtitle = styled.div`
  color: ${props => props.theme.secondary};
  font-size: 3rem;
  font-weight: 300;
  color: #424242
  margin-bottom: 1rem;
  text-align: left;
  @media (max-width: 768px){  
    font-size: 2rem;
  }
`

const InputWrapper = styled.div`
  position: relative;
`

const ButtonWrapper = styled.div`
  box-sizing: border-box;
  padding: ${ props => props.theme.spacing.small};
  text-align: center;

  > button {
    margin: ${ props => props.theme.spacing.small} 0;
  }
`

const LinkWrapper = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  font-weight: bold;
  margin: 1rem 0;

  @media (min-width: 1440px){
    margin: 2rem 0;
  }
`

const ForgotPasswordLink = styled(NavLink)`
  text-decoration: none;
  font-weight: 900;
  color: ${props => props.theme.primary};
`

const SignupLink = styled(NavLink)`
  text-decoration: none;
  padding-bottom: ${props => props.theme.spacing.small};
  color: ${props => props.theme.secondary};
`

const FormWrapper = styled.div`
  padding: 0 0.5rem;
`

const LoginForm = props => {
  const {
    error,
    values,
    touched,
    errors,
    handleChange,
    handleSubmit,
    isSubmitting,
    t
  } = props;
  return (
    <form onSubmit={handleSubmit}>
      <FormWrapper>
        <Subtitle>{t('login.signin')}</Subtitle>
        <InputWrapper>
          <TextInput 
            type="text" 
            name="email" 
            label={t('login.email')}  
            onChange={handleChange}
            values={values.email}
            icon={(<EmailIcon color='secondary'/>)}
          />
        </InputWrapper>     
        {errors.email &&
          touched.email && (
            <ErrorMessage>{errors.email}</ErrorMessage>
        )}   

        <InputWrapper>
          <TextInput 
            type="password" 
            name="password" 
            label={t('login.password')}  
            onChange={handleChange}
            value={values.password} 
            icon={(<LockIcon color='secondary'/>)}
          />
        </InputWrapper>
        {errors.password &&
          touched.password && (
            <ErrorMessage>{errors.password}</ErrorMessage>
        )}
        {error &&
          <ErrorMessage>{t(`login.${error}`)}</ErrorMessage>
        }
        <ButtonWrapper>
          <Button 
            type='submit'
            loading={isSubmitting}            
          >
            {t('login.signin')}
          </Button>
          <GoogleLogin 
            clientId={`${process.env.REACT_APP_GOOGLE_CLIENT_ID}.apps.googleusercontent.com`}
            render={renderProps => (
              <GoogleButton onClick={renderProps.onClick} />
            )}
            cookiePolicy={'single_host_origin'}
            onSuccess={values.responseGoogle}
            onFailure={values.responseGoogle}
          />          
        </ButtonWrapper>      
        <LinkWrapper>
          <SignupLink to='/signup_preview'>{t('login.dont_have_account')}</SignupLink>
          <ForgotPasswordLink to='/forgot'>{t('login.forgot_password')}</ForgotPasswordLink>
        </LinkWrapper>
      </FormWrapper>
    </form>
  )
}


export default withTranslation()(AuthRequestEnhancer(LoginForm))