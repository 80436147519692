import { handleActions } from 'redux-actions'

import * as actions from './actions'

const initialState = {
  status: 'init',
  error: '',
  user: {},
  organization: {},
  list: [],
}

const reducer = handleActions({
  [actions.loginUser.START]: (state) => ({
    ...state,
    status: 'pending'
  }),
  [actions.loginUser.FAILURE]: (state, action) => ({
    ...state,
    status: 'failure',
    error: action.payload.error
  }),
  [actions.loginUser.SUCCESS]: (state, action) => ({
    ...state,
    status: 'success',
    user: action.payload.user
  }),
  [actions.signupUser.START]: (state) => ({
    ...state,
    status: 'pending'
  }),
  [actions.signupUser.FAILURE]: (state, action) => ({
    ...state,
    status: 'failure',
    error: action.payload.error
  }),
  [actions.signupUser.SUCCESS]: (state, action) => ({
    ...state,
    status: 'success',
    user: action.payload.user
  }),
  [actions.googleAuth.START]: (state) => ({
    ...state,
    status: 'pending'
  }),
  [actions.googleAuth.FAILURE]: (state, action) => ({
    ...state,
    status: 'failure',
    error: action.payload.error
  }),
  [actions.googleAuth.SUCCESS]: (state, action) => ({
    ...state,
    status: 'success',
    user: action.payload.user
  }),
}, initialState)

export default reducer