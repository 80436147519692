import React from "react";

function Icon() {
  return (
<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M12.6875 1.3125C12.8036 1.3125 12.9149 1.35859 12.9969 1.44064C13.079 1.52269 13.125 1.63397 13.125 1.75V5.95C13.125 6.23727 13.0685 6.52172 12.9585 6.78712C12.8486 7.05252 12.6875 7.29367 12.4843 7.4968C12.2812 7.69992 12.0401 7.86105 11.7747 7.97099C11.5093 8.08092 11.2248 8.1375 10.9375 8.1375H2.36867L5.2973 11.0653C5.37945 11.1474 5.4256 11.2588 5.4256 11.375C5.4256 11.4912 5.37945 11.6026 5.2973 11.6847C5.21515 11.7669 5.10373 11.8131 4.98755 11.8131C4.87137 11.8131 4.75995 11.7669 4.6778 11.6847L1.0028 8.00975C0.962057 7.96911 0.929732 7.92083 0.907676 7.86768C0.885621 7.81453 0.874268 7.75755 0.874268 7.7C0.874268 7.64245 0.885621 7.58547 0.907676 7.53232C0.929732 7.47917 0.962057 7.43089 1.0028 7.39025L4.5028 3.89025C4.58495 3.8081 4.69637 3.76195 4.81255 3.76195C4.92873 3.76195 5.04015 3.8081 5.1223 3.89025C5.20445 3.9724 5.2506 4.08382 5.2506 4.2C5.2506 4.31618 5.20445 4.4276 5.1223 4.50975L2.36867 7.2625H10.9375C11.2856 7.2625 11.6195 7.12422 11.8656 6.87808C12.1118 6.63194 12.25 6.2981 12.25 5.95V1.75C12.25 1.63397 12.2961 1.52269 12.3782 1.44064C12.4602 1.35859 12.5715 1.3125 12.6875 1.3125Z" fill="white"/>
</svg>


  );
}

export default Icon;
