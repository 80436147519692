import Axios from 'axios'
const URL = `${process.env.REACT_APP_GAME_URL}/api/auth`

const updateUser = (data) => {
  Axios.post(`${URL}/update`, data, {
    headers: { Authorization: `${localStorage.getItem('token')}`}
  }).then( res => res)
    .catch( err => err.response)
}

export {
  updateUser
}