import React from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'
import WelcomeMessage from './components/WelcomeMessage'
import LatestRecommendations from './components/LatestRecommendations'
import ResumeGame from './components/ResumeGame'
import { useQuery } from '@apollo/react-hooks'
import Waves from './components/Waves'
import RankingPosition from './components/RankingPosition'
import { GET_PROGRESS } from '../../queries/user'

const Home = ({history, auth}) => {  
  
  const { loading, error, data } = useQuery(GET_PROGRESS, {
    fetchPolicy: "no-cache",
    errorPolicy: "all",
    variables: {
      _id: process.env.REACT_APP_ORGANIZATION_ID
    },    
  })
  
  return (
    <Wrapper>     
      <Content>   
        <div>
          <WelcomeMessage
            show={!!(auth.user)}
            name={ auth.user && auth.user.name }
          />      
        </div>
        <RankingWrapper>
          <RankingPosition 
            loading={loading}
            data={data}
          />
        </RankingWrapper>
        <div>
          <ResumeGame 
            history={history}
            loading={loading}
            data={data}
          />
        </div>
        <div>
          <LatestRecommendations
            history={history}
          />
        </div>
      </Content>
    </Wrapper>
  )
}
 
const Wrapper = styled.div`
  position: relative;
`

const WavesWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0; 
  width: 100%;
`

const Content = styled.div``

const RankingWrapper = styled.div``

const mapStateToProps = (state) => {
  return {
    auth: state.layout.user
  }
}

export default connect(
  mapStateToProps,
  null
)(Home)