import React from 'react'
import styled from "styled-components"
import CercleArrow from '../GlobalIcons/CercleArrow'
import Title from '../Title'

const Badge = ({color, children}) => {
  return (
  <StyledBadge color={color}>
   {children}
   <IconContent>
    <CercleArrow />
    </IconContent>
  </StyledBadge>)
}

const StyledBadge = styled.div`
  background:  ${props => props.theme.primary};
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 2rem;
  margin: 0;
  color: ${props => props.color || 'white'}
  padding: ${props => props.padding || '0.2rem 1rem 0.6rem 0.8rem'}
  border-radius: ${(props) => props.theme.border_radius.rounded_lg};
`

const IconContent = styled.span`
padding: 5px;
margin-top: 10px;
`

export default Badge