import React from "react";
import styled from "styled-components";

function Icon({ color }) {
  return (
    <Content color={color}>
      <svg
        width="46"
        height="46"
        viewBox="0 0 46 46"
        fill={color}
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="46" height="46" rx="23" fill="white" />
        <path
          d="M22.9999 11L25.5034 12.296L28.2904 12.719L29.5534 15.239L31.5589 17.219L31.0999 20L31.5589 22.781L29.5534 24.761L28.2904 27.281L25.5034 27.704L22.9999 29L20.4964 27.704L17.7094 27.281L16.4464 24.761L14.4409 22.781L14.8999 20L14.4409 17.219L16.4464 15.239L17.7094 12.719L20.4964 12.296L22.9999 11Z"
          fill={color}
        />
        <path
          d="M17 28.691V35L23 33.5L29 35V28.691L25.973 29.15L23 30.689L20.027 29.15L17 28.691Z"
          fill={color}
        />
      </svg>
    </Content>
  );
}

const Content = styled.span`
  fill: ${(props) => props.theme.primary};
`;



export default Icon;
