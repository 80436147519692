import React from 'react'
import styled, { keyframes,  withTheme } from 'styled-components'

const BlowingIntheWind = keyframes`
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }

  50% {
    opacity: 1;
  }
  
  100% {
    transform: translateX(100%);
    opacity: 0;
  }
`

const Svg = styled.svg`
  position: absolute;
  bottom: 0;
`

const Group = styled.g`
  & > .cloud {
    animation-iteration-count: infinite;
    animation-name: ${BlowingIntheWind};
    animation-timing-function: linear;
    animation-duration: 8s;
  }

  & > .cloud:nth(2) {
    animation-delay: 0.5s;
  }
`

const Mountain = (props) => (
  <Svg width='100%' height={300} viewBox="0 0 250 160" {...props}>
      <title>{"mountain"}</title>
      <g fillRule="nonzero" fill="none" transform="scale(1.2)">
        <path fill="#228F9A" d="M36.4 144.8h49.9v-79z" />
        <path fill="#187C86" d="M136.2 144.8H86.3v-79z" />
        <path fill="#FFF" d="M79.2 77l2.2.9 1.6.2 3.3.9.5.1-.5-13.3z" />
        <path fill="#D2EDF3" d="M93.2 76.7l-6.9-10.9V79l2.7.2z" />
        <path
          d="M.7 166.1h50V91.5l-50 74.6zm0 0h50V91.5l-50 74.6z"
          fill="#74C2C9"
        />
        <path fill="#236673" d="M.7 166.1h50V91.5z" />
        <path fill="#054C55" d="M100.7 166.1h-50V91.5z" />
        <path
          fill="#236673"
          d="M32.1 153.9l-7-24.2-20.2 30.1v6.3h29.7zM70.4 159.7l-10.8-23.3-4.9-9.6-4-4.5v43.8h14.7z"
        />
        <path
          fill="#2A9C9D"
          d="M50.7 122.3l-5.3 4.1-3.2 5L27.3 148l1.1 18.1h22.3z"
        />
        <path fill="#FFF" d="M42.8 103l-.6.9 4.9 1.9 3.6 3.6.9-.5-.9-17.4z" />
        <path fill="#D2EDF3" d="M60.3 105.5l-9.6-14v17.9l4.1-2.8z" />
        <g>
          <path fill="#2A9C9D" d="M61 166.2h76.6V52z" />
          <path
            fill="#187C86"
            d="M73.7 154l6.3 12.2h44.6v-15.4l-4.4-39.1-13.7-13.2-1.3 1.8-30.8 47.1z"
          />
          <path
            fill="#054C55"
            d="M213 165.2L170 101l-9.4 22.7-2.6 36.2 14.8 5.8h39z"
          />
          <path
            fill="#035A68"
            d="M176.1 117.2l-2-10.1L149.9 71 138 92.3l13.9 34.1z"
          />
          <path
            d="M178 161.8l-7-28.7s-15.4-27.8-16.1-29.7c-.3-.8-12.8-21.3-12.8-21.3l-4.4 1.5v82.5h36.9l3.4-4.3z"
            fill="#054C55"
          />
          <path fill="#2A9C9D" d="M116.2 111.5l4.3.9 3.7 35.8-21.3.7z" />
          <path fill="#D2EDF3" d="M137.6 52v37.3l1.3-.1 9.6-7.1 7.5-2.7z" />
          <path
            fill="#FFF"
            d="M117.8 82.7l5.8 1 5.9.4 3.9 5.5 4.2-.3V52l-20.2 30.1z"
          />
        </g>
        <Group fill="#DFF2F6">
          <path className="cloud" d="M175.8 5.5h-1.1c-.3-3.1-2.9-5.5-6-5.5-3.2 0-5.7 2.4-6 5.5h-1.1c-2 0-3.6 1.6-3.6 3.6s1.6 3.6 3.6 3.6h14.2c2 0 3.6-1.6 3.6-3.6s-1.6-3.6-3.6-3.6zM104.8 26.5h-1.1c-.3-3.1-2.9-5.5-6-5.5-3.2 0-5.7 2.4-6 5.5h-1.1c-2 0-3.6 1.6-3.6 3.6s1.6 3.6 3.6 3.6h14.2c2 0 3.6-1.6 3.6-3.6s-1.6-3.6-3.6-3.6z" />
          <path className="cloud" d="M175.8 5.5h-1.1c-.3-3.1-2.9-5.5-6-5.5-3.2 0-5.7 2.4-6 5.5h-1.1c-2 0-3.6 1.6-3.6 3.6s1.6 3.6 3.6 3.6h14.2c2 0 3.6-1.6 3.6-3.6s-1.6-3.6-3.6-3.6zM104.8 26.5h-1.1c-.3-3.1-2.9-5.5-6-5.5-3.2 0-5.7 2.4-6 5.5h-1.1c-2 0-3.6 1.6-3.6 3.6s1.6 3.6 3.6 3.6h14.2c2 0 3.6-1.6 3.6-3.6s-1.6-3.6-3.6-3.6z" />
        </Group>
        <g id="layer1" transform="translate(128, 22) scale(0.15)">
          <path d="m 63.336312,71.691314 c 0.657874,0.516957 1.313808,1.019644 1.967858,1.50837 0.703219,0.525466 1.40426,1.034792 2.103196,1.528361 26.43933,18.670713 49.865554,14.793317 74.144764,9.059598 29.00007,-6.848587 59.21709,-16.345568 97.23924,6.769806 -45.13915,-9.123362 -62.64783,29.422791 -93.92824,50.721071 -18.25376,12.42866 -41.19726,18.98383 -77.057915,6.76518 -0.745319,-0.25394 -1.496217,-0.516 -2.252768,-0.78629 -0.821847,-0.29361 -1.650366,-0.59693 -2.485651,-0.9101 z" id="flag" style={{fill: props.theme.secondary,"fillOpacity":1,"stroke":"none",display:"inline"}}>
            <animate attributeName="d" attributeType="auto" dur="1s" begin="0s" calcMode="spline" keyTimes="0;0.45;1" keySplines="0.05 0 0.95 1; 0.05 0 0.95 1" repeatCount="indefinite" values="
              m 63.336312,71.691314 c 0.657874,0.516957 1.313808,1.019644 1.967858,1.50837 0.703219,0.525466 1.40426,1.034792 2.103196,1.528361 26.43933,18.670713 49.865554,14.793317 74.144764,9.059598 29.00007,-6.848587 59.21709,-16.345568 97.23924,6.769806 -45.13915,-9.123362 -62.64783,29.422791 -93.92824,50.721071 -18.25376,12.42866 -41.19726,18.98383 -77.057915,6.76518 -0.745319,-0.25394 -1.496217,-0.516 -2.252768,-0.78629 -0.821847,-0.29361 -1.650366,-0.59693 -2.485651,-0.9101 z;
              m 63.336312,71.691314 c 0.659866,-0.117195 1.31518,-0.23111 1.965989,-0.341782 35.868039,-6.099477 58.054249,-2.347781 74.692589,5.207181 28.76555,13.061556 41.01008,50.467247 78.20427,43.150867 1.88702,-0.3712 3.82051,-0.81132 5.80443,-1.32596 -1.25386,1.41625 -2.4928,2.77815 -3.71749,4.08799 -27.3116,29.21061 -55.84873,15.6355 -76.52492,12.17591 -20.55607,-3.43948 -41.51945,-7.24243 -77.781612,10.26588 -0.960107,0.46356 -1.930939,0.94206 -2.912772,1.43591 z;
              m 63.336312,71.691314 c 27.630309,21.711854 51.837238,18.253668 76.877978,12.410458 28.75963,-6.711002 58.61912,-16.568046 96.02807,4.947797 0.48339,0.278028 0.96805,0.561294 1.45399,0.849874 0.36429,0.216339 0.72929,0.435664 1.09502,0.658006 -0.44532,-0.09001 -0.88796,-0.175375 -1.32795,-0.256166 -0.4257,-0.07817 -0.84893,-0.152052 -1.26972,-0.221707 -42.82316,-7.088795 -60.3299,29.618914 -90.4756,50.610194 -19.17784,13.35405 -43.47084,20.34745 -82.651304,5.65754 z
              " id="animate2988">
            </animate>
          </path>
        <path d="m 70.090849,244.00901 -3.61268,-178.010239 -6.5029,-0.33241 0.36127,179.187859 z" id="pole" style={{fill:"#543c24","fillOpacity":1,stroke:"none",display:"inline"}}></path>
        </g>
      </g>
    </Svg>
)

export default withTheme(Mountain)