import React from "react";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import UserProgress from "../UserProgress";
import Card from "./Card";
import { Link } from "react-router-dom";
import { CurrentAxisContext } from "../Context";
import { use } from "i18next";

const Axis = (props) => {
  const { data, index, userProgress, history } = props;


  return (
    <CurrentAxisContext.Consumer>
      {({ currentAxisColor, toggleAxisColor }) => (
        <Wrapper key={data._id}>
          <Card index={index} data={data} height="130" color={data.pallete} />
          <ProgressWrapper progress={!!userProgress}>
            {userProgress && (
              <UserProgress
                id={data._id}
                color={data.pallete}
                progress={userProgress}
                onClickButton={() => {
                  toggleAxisColor(data.pallete.primary);
                  history.push(`/axis/${data._id}`);
                }}
              />
            )}
          </ProgressWrapper>
          <div
            onClick={() => {
              toggleAxisColor(data.pallete.primary);
              history.push(`/axis/${data._id}`);
            }}
            // buttonText={"Continuar..."}
          >
          </div>
        </Wrapper>
      )}
    </CurrentAxisContext.Consumer>
  );
};

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: end;
  margin-top: 30px;
  right: 0;
`;

const Button = styled.div`
  background: ${(props) => props.theme.primary};
  cursor: pointer;
  color: white;
  padding: 0.7rem;
  width: fit-content;
  border-radius: ${(props) => props.theme.border_radius.rounded_sm};
`;

const Wrapper = styled.div`
  padding: 1rem 0;
  background: white;
  border: 1px solid ${(props) => props.theme.light_gray};
  height: 170px;
  padding: 2rem;
  border-radius: ${(props) => props.theme.border_radius.rounded_lg};
`;

const ProgressWrapper = styled.div`
  opacity: ${(props) => (props.progress ? "1" : "0")};

  transition: opacity 0.2s;
  height: 50px;
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  color: ${(props) => props.theme.gray};
`;
export default withRouter(Axis);
