import React from 'react'
import styled, {keyframes} from 'styled-components'
import { gradientBG } from '../../styles/animations'

const SuggestionSkeleton = () => (
  <SuggestionItem>
    <ContentTitle />
    <ContentBox />
  </SuggestionItem>
)

const SuggestionItem = styled.div`
  background-color: white;
  border-radius: 1.5rem;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 1rem;
  width: 300px;
  height: 84px;
  margin: 0 auto;
`

const ContentTitle = styled.div`
  width: 7rem;
  height: 1rem;
  background: linear-gradient(-45deg, #fbfbfb, #cccccc, #fbfbfb );
  background-size: 400% 400%;
  animation: ${gradientBG} 2s ease infinite;
`

const ContentBox = styled.div`
  width: 14rem;
  height: 1rem;
  margin-top: 0.5rem;
  background: linear-gradient(-45deg, #fbfbfb, #cccccc, #fbfbfb );
  background-size: 400% 400%;
  animation: ${gradientBG} 2s ease infinite;
`

export default SuggestionSkeleton