import React from 'react'
import styled from 'styled-components'

import RupuLogo from '../RupuLogo'

const Logo = styled.img`
  width: 180px;
  width: ${props => props.width || '180px'};
  padding-left: ${props => props.paddingLeft || '0'};
`


const GameLogo = ({width, paddingLeft}) => {
  const logo = localStorage.getItem('logo') || ''

  return (
    <div>
      { logo 
        ? <Logo src={logo} width={width} paddingLeft={paddingLeft} alt="Game logo" />
        : <RupuLogo />
      }
    </div>
  )
}

export default GameLogo