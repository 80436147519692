import layout from './scenes/index'
import reducer from './reducers'
import * as actions from './actions'
import * as constants from './constants'
import sagas from './sagas'

export default {
  actions,
  constants,
  layout,
  reducer,
  sagas: [
    ...sagas
  ]
}