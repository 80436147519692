export const calculatePosition = data => {
  const userId = data.user.global_id
  const rankingArr = data.usersRanking
  let aux = {}
  rankingArr.forEach( (arr, index) => {
    if(userId === arr.global_id) {
      aux = {
        position: index + 1,
        achievements: arr.achievementsCount
      }
    }
  })
  return aux
}

