import React from 'react'
import styled from 'styled-components'
import Bg from './assets/Bg.svg'
import Wrapper from '../AnimatedWrapper'
 
const BgWrapper = ({children}) => (
  <Background>
    <Wrapper>
      {children}
    </Wrapper>
  </Background>
)
const Background = styled.div`
  background-color: ${props => props.theme.primary};
  background-image: url(${Bg});
  background-size: 150%;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;
`

export default BgWrapper