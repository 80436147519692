import React from "react";
import styled from "styled-components";
import Title from "../Title";
import Text from "../Text";

const StretchedCard = ({ color, icon, children, margin }) => {
  return (
    <Wrapper>
      <StyledStretchedCard color={color} margin={margin}>
      {icon && (
          <IconWrapper>
            {icon}
          </IconWrapper>
        )}
        <TextWrapper>
          <Title fontSize="large">{children}</Title>
          <Text fontSize="medium"></Text> 
        </TextWrapper>
      </StyledStretchedCard>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding: 0 2rem;
  margin-top: 0rem;
`;

const IconWrapper = styled.div`
font-size: 2.6rem;
padding: 1rem 0.2rem 1rem 1rem;
@media(max-width: 600px){
  font-size: 1.6rem;
  padding: 35px 10px
  width: 1rem;
}
`;

const StyledStretchedCard = styled.div`
  padding: 0.5rem 0;
  display: flex;
  width: 100%;
  background: white;
  border-radius: ${(props) => props.theme.border_radius.rounded_lg};
  border: 1px solid ${(props) => props.theme.light_gray };
  margin: ${props => props.margin || '2rem 0'}
`;
const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export default StretchedCard;
