import React from "react";
import styled from "styled-components";

const IconContainer = styled.div`
  svg {
    width: ${props => props.width || "16px"};
    height: ${props => props.height || "16px"};
    transition: fill 0.2s;
  }

  &:hover {
    svg {
      fill: ${(props) => props.theme.primary};
    }
  }
`;

function Icon({color, height, width}) {
  return (
    <IconContainer color={color} width={width} height={height}>
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill={color}
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_1_53463)">
          <path
            d="M10 2.00001C10.0001 2.44349 9.85282 2.87443 9.58129 3.22506C9.30976 3.5757 8.9294 3.82614 8.5 3.93701V9.02401C9.363 9.10701 10 9.40101 10 9.75001C10 10.164 9.105 10.5 8 10.5C6.895 10.5 6 10.164 6 9.75001C6 9.40001 6.637 9.10701 7.5 9.02401V3.93701C7.13783 3.8435 6.80914 3.6503 6.55127 3.37935C6.2934 3.10841 6.11667 2.77058 6.04117 2.40423C5.96567 2.03789 5.99441 1.65771 6.12414 1.30688C6.25386 0.956049 6.47937 0.64863 6.77505 0.419537C7.07073 0.190444 7.42473 0.0488594 7.79684 0.0108647C8.16896 -0.0271301 8.54426 0.0399876 8.88014 0.204595C9.21602 0.369202 9.49901 0.6247 9.69696 0.942071C9.89492 1.25944 9.99991 1.62597 10 2.00001Z"
            fill={color}
          />
          <path
            d="M1.513e-10 9.665V11.382C9.91488e-05 11.5677 0.0518831 11.7496 0.149552 11.9075C0.247222 12.0654 0.386919 12.193 0.553 12.276L7.106 15.553C7.3836 15.6917 7.68967 15.7639 8 15.7639C8.31033 15.7639 8.6164 15.6917 8.894 15.553L15.447 12.276C15.6131 12.193 15.7528 12.0654 15.8504 11.9075C15.9481 11.7496 15.9999 11.5677 16 11.382V9.665C16 9.565 15.94 9.475 15.848 9.435L9.5 6.715V7.708L14.727 9.886C14.7496 9.8955 14.7689 9.91144 14.7825 9.93183C14.7961 9.95222 14.8034 9.97616 14.8035 10.0007C14.8036 10.0252 14.7965 10.0492 14.7831 10.0697C14.7697 10.0902 14.7505 10.1063 14.728 10.116L8.788 12.662C8.539 12.7687 8.27091 12.8238 8 12.8238C7.72909 12.8238 7.461 12.7687 7.212 12.662L1.272 10.116C1.24949 10.1063 1.23033 10.0902 1.21691 10.0697C1.20348 10.0492 1.19638 10.0252 1.19649 10.0007C1.19659 9.97616 1.2039 9.95222 1.21751 9.93183C1.23111 9.91144 1.25041 9.8955 1.273 9.886L6.5 7.708L6.487 6.72L0.152 9.435C0.106906 9.45421 0.0684562 9.48626 0.0414311 9.52715C0.0144059 9.56804 -1.70479e-06 9.61598 1.513e-10 9.665H1.513e-10Z"
            fill={color}
          />
        </g>
        <defs>
          <clipPath id="clip0_1_53463">
            <rect width="16" height="16" fill={color} />
          </clipPath>
        </defs>
      </svg>
    </IconContainer>
  );
}

export default Icon;
