import React, { useState } from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import t from '../../utils/i18n'
import RightArrow from '../GlobalIcons/RightArrow'
import Mountain from './assets/mountain'


const Congrats = ({ history }) => {
  const [showModal, setShowModal] = useState(true);

  const closeModal = () => {
    setShowModal(false);
    history.push("/axis");
  };

  return (
    <ModalOverlay show={showModal}>
      <ModalWrapper>
        <ModalOpen>
            <Header>
              <Text size="1.5rem">{t('axis.congrats')}</Text>
              <Text size="0.9rem">{t('axis.finished')}</Text>
            </Header>
            <StyledLink to="/axis">
              {t('axis.continue')}
            </StyledLink>
        </ModalOpen>
      </ModalWrapper>
    </ModalOverlay>
  );
};

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: ${props => (props.show ? "block" : "none")};
`;

const ModalWrapper = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 40px;
  text-align: center;
  border-radius: ${(props) => props.theme.border_radius.rounded_lg};
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  width: 500px;
  @media (max-width: 600px) {
    width: 280px;
  }
`;

const ModalOpen = styled.div`
  overflow: hidden;
  position: relative;
`;


const Header = styled.div`
  display: flex;
  color: black;
  gap: 2rem;
  flex-direction: column;
  align-items: center;
`;

const Text = styled.div`
  font-size: ${(props) => props.size};
  font-weight: 900;
  text-align: center;
`;

const StyledLink = styled(Link)`
  display: inline-block;
  position: relative;
  overflow: hidden;
  background-color: ${(props) => props.theme.primary};
  border-radius: ${(props) => props.theme.border_radius.rounded_sm};
  color: white;
  cursor: pointer;
  font-weight: bold;
  padding: 0.90rem 1rem;
  outline: none;
  text-align: center;
  text-decoration: none;
  margin: 2rem 0 0 0;
`;



export default Congrats