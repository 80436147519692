import reducer from './reducers'
import onboarding from './scenes/onboarding'
import createOrganization from './scenes/createOrganization'
import sagas from './sagas'
import * as constants from './constants'
import * as selectors from './selectors'
import * as actions from './actions'

export default {
  actions,
  selectors,
  constants,
  reducer,
  scenes: {
    chooseOrganization: onboarding,
    createOrganization: createOrganization
  },
  sagas: [
    ...sagas
  ]
}