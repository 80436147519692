import React from 'react'
import styled from 'styled-components'
import { gradientBG } from '../../styles/animations'

const AboutSkeleton = () => (
  <AboutItem>
    <AboutTitle />
    <AboutDescription />
  </AboutItem>
)

const AboutItem = styled.div`
  border-radius: 1.5rem;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding: 2.5rem;
  width: 100%;
  height: 600px;
  margin: 0 auto;
`

const AboutTitle = styled.div`
  width: 7rem;
  height: 2rem;
  background: linear-gradient(-45deg, #fbfbfb, #cccccc, #fbfbfb );
  background-size: 400% 400%;
  animation: ${gradientBG} 2s ease infinite;
  margin: 0 0 2rem 0;
`

const AboutDescription = styled.div`
  width: 100%;
  height: 9rem;
  margin-top: 0.5rem;
  background: linear-gradient(-45deg, #fbfbfb, #cccccc, #fbfbfb );
  background-size: 400% 400%;
  animation: ${gradientBG} 2s ease infinite;
`

export default AboutSkeleton