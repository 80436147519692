import React from 'react'
import styled, { keyframes} from 'styled-components'

const moveUp = keyframes`
  0% {
    transform:translateX(50vw);
  }
  100% {
    transform:translateX(0);
  }
`

// height: 100%;
const Wrapper = styled.div`
  padding: 1rem;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  
  width: 300px;
  margin: 0 auto;
  animation: ${moveUp} 1s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;
`

export default Wrapper
