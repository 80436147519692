import React, {useState, useEffect} from 'react'
import styled from 'styled-components'
import { withTranslation } from 'react-i18next'
import { withRouter, matchPath } from 'react-router'
// import Text from './text'
import Title from '../Title'
import AxisIcon from "../../components/GlobalIcons/AxisIcon";
import PageTitle from '../PageTitle';
import StretchedCard from '../StretchedCard';
import Text from '../Text'
import ArrowLeft from "../GlobalIcons/ArrowReturnLeft";

const Wrapper = styled.nav`

`

const ButtonContainer = styled.div`
@media(max-width: 600px) {
  display:none;
}
`

const Content = styled.nav`
@media (max-width: 600px) {
margin: 2.5rem 1rem 0.5rem 0;
}
`

const MessageWrapper = styled.div`  
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

`

const ChevronWrapper = styled.div`
   position: absolute;
   top:32px;
  // padding: 1rem 0;
  // cursor: pointer;
  // height: 2rem;
  // width: 1rem;
  margin-left: 2.5rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  padding: 1rem;
  width: 20px;
  height: 20px;
  cursor: pointer;
  background: ${(props) => props.theme.primary};
`

const Navbar = ({ activeRoute, location, history, t, currentAxisColor }) => {

  const routes = {
    '/': t('titles.home'),
    '/axis': t('titles.dashboard'),
    '/suggestions': t('titles.suggestions'),
    '/merits': t('titles.achievements'),
    '/about-us': t('about.about_us')
  }

  const startMessage = t('start')
  const [showMessage, toggleShowMessage] = useState(false)
  const [axis, setAxis] = useState(false)
  const [suggestion, setSuggestion] = useState(false)

  useEffect(() => {
    toggleShowMessage(activeRoute === '/axis')
  }, [activeRoute])

  useEffect(() => {
    setAxis(matchPath(location.pathname, { path: '/axis/:axisId' }))
  }, [location])

  useEffect(() => {
    setSuggestion(matchPath(location.pathname, { path: '/suggestions/:axisId' }))
  }, [location])

  return (
    <Wrapper 
      showMessage={showMessage}
      currentAxisColor={axis ? currentAxisColor : false}
    >
      <ButtonContainer>
        {/* { location.pathname !== '/' && location.pathname !== '/axis' && (
          <ChevronWrapper onClick={() => history.goBack()}>       
            <BackChevron />
          </ChevronWrapper>       
        )} */}

        {location.pathname !== '/' && location.pathname !== '/axis' && location.pathname !== '/suggestions' && !location.pathname.startsWith('/axis/') && (
        <ChevronWrapper onClick={() => history.goBack()}>       
          <ArrowLeft />
        </ChevronWrapper>

        )}
      </ButtonContainer>
      <MessageWrapper>
        { axis && <p axis={axis} /> }
        { showMessage && 
          <>
          <Content>
         <PageTitle>
          <AxisIcon />
            <Title padding={'0'}>{t('titles.dashboard')}</Title>
          </PageTitle>
          </Content>
            <StretchedCard margin={'0'} icon="🪄">
              <Title padding={'0.6rem 0 0.2rem 0rem'} >😃 {t('axis.time')} </Title>
              <Text fontSize={'medium'} padding={'0 2rem 0 0 '}>{t('home.welcome.get_started')}</Text>
            </StretchedCard>
          </>
        }
      </MessageWrapper>
      <div>
      </div>
    </Wrapper>
  )
}
export default withTranslation()(withRouter(Navbar))