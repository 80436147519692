import React from 'react'
import { render } from 'react-dom'
import { Provider } from 'react-redux'
import RootComponent from './app/Root'
import './lang/i18n'
import configureStore from './app/ConfigureStore'
import './index.css'
import * as serviceWorker from './serviceWorker'
import ReactGA from 'react-ga4'
import { hotjar } from "react-hotjar";

const store = configureStore()

const renderApp = () => {

  let analyticsIds = []
  if (process.env.REACT_APP_GA) {
    analyticsIds.push(process.env.REACT_APP_GA)
  }
  if (process.env.REACT_APP_GA_CUSTOMER) {
    analyticsIds.push(process.env.REACT_APP_GA_CUSTOMER)
  }
  if (analyticsIds.length > 0) {
    ReactGA.initialize(analyticsIds);
  }
  
  if (process.env.REACT_APP_HOTJAR_ID && process.env.REACT_APP_HOTJAR_SV) {
    hotjar.initialize(process.env.REACT_APP_HOTJAR_ID, process.env.REACT_APP_HOTJAR_SV);
  }
  render (
    (<Provider store={store}>
      <RootComponent />
    </Provider>), 
    document.getElementById('root') || document.createElement('div') // for testing purposes
  )

}
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
renderApp()
serviceWorker.unregister()
