import React, { Component } from 'react'
import styled from 'styled-components'
import AuthLayout from '../../../components/AuthLayout'
import Auth from './Auth'


const AuthWrapper = styled.div`
  background-color: white;
  display: inline-block;  
  z-index: 10;
  @media (max-width: 768px){
    width: 100%;
  }
`

class AuthContainer extends Component {
  constructor(props) {
    super(props)
    this.state = {
      email: '',
      password: ''
    }
  }

  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  handleAuth = (e) => {
    e.preventDefault()
    const { loginUser } = this.props
    const { email, password } = this.state
    loginUser(email, password)
  }

  responseGoogle = (res) => {
    const { profileObj } = res
    const data = {
      email: profileObj.email,
      name: profileObj.name,
      password: profileObj.googleId,
      provider: 'google',
      game_id: process.env.REACT_APP_ORGANIZATION_ID
    }
    this.props.googleAuth({data})
  }

  render () {
    const { responseGoogle } = this
    return (
      <AuthLayout>
        <AuthWrapper>
          <Auth
            {...this.props}
            onChange={this.onChange}
            loginUser={this.props.loginUser}
            responseGoogle={responseGoogle}
          />
        </AuthWrapper>
      </AuthLayout>
    )
  }
}

export default AuthContainer