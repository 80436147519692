import React from 'react'
import styled from 'styled-components'
import t from '../../../utils/i18n'

const Title = styled.div`
  color: #424242;
  font-weight: 300;
  font-size: 3rem;
  padding: ${props => props.theme.spacing.small} 0;

  @media (max-width: 768px){
    font-size: 2rem;
  }
`

const Subtitle = styled.div`
  color: #a9abad;
  font-weight: 400;
  font-size: ${props => props.theme.font_size.medium};
  padding: ${props => props.theme.spacing.xs} 0;
`

const OnboardingStepHeader = (props) => {
  return (
    <div>
      <Title>
        <span>{t('signup.register')}</span>
        { props.subtitle && (
          <Subtitle>{props.subtitle}</Subtitle>
        )}
      </Title>
    </div>
  )
}

OnboardingStepHeader.defaultProps = {
  subtitle: null
}

export default OnboardingStepHeader