import { connect } from 'react-redux'

import * as actions from '../actions'
import * as selectors from '../selectors'
import CreateOrganizationContainer from '../components/CreateOrganizationContainer'

const mapStateToProps = (state) => ({
  status: selectors.status(state),
  error: selectors.error(state),
})

const mapDispatchToProps = dispatch => ({
  createOrganization: data => dispatch(actions.createOrganization( data ))
})

export default connect(mapStateToProps, mapDispatchToProps)(CreateOrganizationContainer)