import React, { useEffect, useState } from "react";
import styled from "styled-components";
import MiniStars from "../GlobalIcons/MiniStars";
import ButtonClose from "../GlobalIcons/ButtonClose";
import Title from "../Title";
// import { GET_SUGGESTION } from '../../queries/suggestion'
// import { useQuery } from "react-apollo";
import t from "../../utils/i18n";
import Text from "../Text";
import IconSelector from "../IconSelector";
import StretchedCard from "../StretchedCard";

const Modal = ({ suggestion, closeModal }) => {
  const handleOverlayClick = (event) => {
    if (event.target === event.currentTarget) {
      closeModal();
    }
  };

  return (
    <ModalOverlay onClick={handleOverlayClick}>
      <ModalWrapper>
        <ButtonClose onClick={closeModal} />
        <IconWrapper>
          <MiniStars color={"white"} />
        </IconWrapper>
        <AxisName>{suggestion.axis.name}</AxisName>
        <ModalOpen>
          <Title>{suggestion.title}</Title>
          <Text fontSize={"medium"} padding={"20px"} textAlign={"center"}>
            {suggestion.description}
          </Text>
          {suggestion.materials && suggestion.materials.length > 0 && (
            <>
              <Text
                fontWeight={"700"}
                color={"black"}
                fontSize={"medium"}
                padding={"10px 0 0 30px"}
              >
                {t("suggestions.material")}
              </Text>
              <MatDivContainer>
              <MatDiv>
              {suggestion.materials &&
              suggestion.materials.map((material) => (
                <StretchedCard margin={'1rem 0'} key={material._id}>
                  <MaterialItem
                    href={material.link}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <IconSelector type={material.type} />
                    <Text color={"black"} fontSize={"medium"} padding={"0 0 0 10px"}>
                      {material.name}

                    </Text>
                  </MaterialItem>
                </StretchedCard>
              ))}</MatDiv></MatDivContainer>
            </>
          )}
        </ModalOpen>
      </ModalWrapper>
    </ModalOverlay>
  );
};

const MaterialItem = styled.a`
  cursor: pointer;
  color: ${(props) => props.theme.primary};
  display: flex;
  align-items: center;
  text-decoration: none;
  }
`;

const ModalWrapper = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  text-align: center;
  border-radius: ${(props) => props.theme.border_radius.rounded_lg};
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  width: 500px;
  @media (max-width: 600px) {
    width: 300px;
    height: 400px;
    overflow-y: auto;
  }
  height: auto;
  // overflow-y: auto;
`;

const ModalOpen = styled.div`
  overflow: hidden;
  position: relative;
`;

const MatDivContainer = styled.div`
  height: 110px; 
  overflow-y: scroll;
  @media (max-width: 600px) {
    height: 110px;  
  }
`;

const MatDiv = styled.div`
  overflow-y: scroll;

`;

const AxisName = styled.div`
  color: ${(props) => props.theme.primary};
  background: ${(props) => props.theme.slate_gray};
  border-radius: ${(props) => props.theme.border_radius.rounded_md};
  padding: 5px;
  width: fit-content;
  margin: 0 auto;
  display: flex;
  justify-content: center;
`;

const IconWrapper = styled.div`
  margin: 20px auto;
  display: flex;
  justify-content: center;
  width: 15px;
  height: 15px;
  padding: 10px;
  border-radius: 50%;
  background: ${(props) => props.theme.primary};
`;

const ModalOverlay = styled.body`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
`;

export default Modal;
