import React from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'
import LetterAvatar from '../../components/LetterAvatar'
import t from '../../utils/i18n'
import { REMOVE_USER_PROGRESS } from '../../queries/user'
import { useMutation } from '@apollo/react-hooks';
import { handleLogout } from '../../utils/helpers'

const Settings = ({userData, history}) => {

  const removeProgressMessage = t('navbar.remove')
  const [removeProgress, {loading}] = useMutation(REMOVE_USER_PROGRESS, {
    refetchQueries: ['game'],
    onCompleted: () => history.go(0)
  })

  return (
    <Wrapper> 
      <div>
        {userData && userData.user ? (
          <>

            <AvatarWrapper>
              <LetterAvatar name={userData.user.name} size="60" />
              <div>
                <UserinfoItem color="primary" textSize="medium">{userData.user.name}</UserinfoItem>
                <UserinfoItem color="pink" textSize="small">{userData.user.organization_name}</UserinfoItem>
              </div>
            </AvatarWrapper>

            </>
          ):<p>loading</p>
        }
      </div>
      <Title>
        {t('bottom_bar.settings')}
      </Title>
      { userData.user && userData.user.role === 'admin' && (
        <ResetTab 
          onClick={() => removeProgress()} 
          loading={loading}
        >
            {removeProgressMessage}
        </ResetTab>
      )}

      <LogoutTab onClick={() => handleLogout()}>
        {t('navbar.logout')}
      </LogoutTab>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  padding: 2rem;
`


const LogoutTab = styled.div`
  color: ${props => props.theme.primary};
  font-weight: bold;
  padding: 1rem 0;
  cursor: pointer;
`

const ResetTab = styled.div`
  color: ${props => props.theme.secondary};
  font-weight: bold;
  padding: 1rem 0;
  cursor: pointer;
`

const Title = styled.div`
  padding: 1rem 0;
  font-size: 1.5rem;
  font-family: Rubik;
  color: ${props => props.theme.secondary};

  &:first-letter {
    text-transform: uppercase;
  }
`

const AvatarWrapper = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 1rem;
`

const UserinfoItem = styled.span`
  color: ${props => props.theme[props.color]};
  font-size: ${props => props.theme.font_size[props.textSize]};
  font-weight: bold;
  display: block;
  padding-bottom: 0.5rem;
  padding-left: 0.5rem;
`

const mapStateToProps = state => ({
  userData: state.layout.user
})

export default connect(
  mapStateToProps,
  null
)(Settings)