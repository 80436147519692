import React from 'react'
import { withTranslation } from 'react-i18next'
import { OrganizationRequestEnhancer } from '../enhancers'
import styled from 'styled-components'
import TextInput from '../../../components/TextInput/index'
import Button from '../../../components/Button'
import Location from '../../../components/GlobalIcons/Location'
import Layers from '../../../components/GlobalIcons/Layers'
import World from '../../../components/GlobalIcons/World'
import AutocompleteInput from '../../../components/AutocompleteInput'
import ErrorMessage from '../../../components/ErrorMessage'

const Form = styled.form`
  box-sizing: border-box;
  padding: ${props => props.theme.spacing.medium} 0;
  width: 100%;

  @media (min-width: 1440px){
    margin: 1rem 0;
  }
`

const FlexWrapper = styled.div`
  display: block;
  box-sizing: border-box;
  padding: ${props => props.theme.spacing.medium} 0;
`

const ButtonWrapper = styled.div`
  margin: 0 auto;
  width: 70%;
  padding-top: ${props => props.theme.spacing.small};
`

const OrganizationForm = props => {
  const {
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
    isSubmitting,
    error,
    t
  } = props

  return (
    <Form onSubmit={handleSubmit} autoComplete="off"> 
      <FlexWrapper>
          <TextInput
            label={t('organization.name')}
            name="name"
            type="text"
            value={values.name}
            onChange={handleChange}
            handleBlur={handleBlur}
            icon={(<Layers color='secondary'/>)}
          />
          {errors.name &&
            touched.name && (
              <ErrorMessage>{t(errors.name)}</ErrorMessage>
          )}
          <TextInput 
            label={t('organization.web')}
            name="web_url"
            type="text"
            value={values.web_url}
            onChange={handleChange}
            handleBlur={handleBlur}
            icon={(<World color='secondary'/>)}
          />
          {errors.web_url &&
            touched.web_url && (
              <ErrorMessage>{t(errors.web_url)}</ErrorMessage>
          )}
          <AutocompleteInput
            label={t('organization.city')}
            name="location"
            type="text"
            location={values.location}
            onSelected={setFieldValue}
            handleBlur={handleBlur}
            icon={(<Location color='secondary'/>)}
          />          
          {errors.location &&
            touched.location && (
              <ErrorMessage>{t(errors.location.text)}</ErrorMessage>
          )}
          {error && error.map((err, i) => (
            <ErrorMessage key={i}>{err}</ErrorMessage>
          ))}
          <ButtonWrapper>
            <Button type='submit' disabled={isSubmitting}>
              { t('organization.cta') }
            </Button>
          </ButtonWrapper>
      </FlexWrapper>
    </Form>
    
  )
}



export default withTranslation()(OrganizationRequestEnhancer(OrganizationForm))