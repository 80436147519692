import React, { Component } from 'react'
import axios from 'axios'
import styled from 'styled-components'

import OutsideClick from '../../components/OutsideClick'
import TextInput from '../TextInput'

const Wrapper = styled.div`
  position: relative;
`

const Results = styled.div`
  position: absolute;
  z-index: 2;
  border-radius: 4px;
  border: 1px solid ${props => props.theme.primary};
  background: white;
  top: 60px;
  width: 100%;
`

const Result = styled.div`
  padding: 1rem;
`

class AutocompleteInput extends Component {

  constructor(props){
    super(props)
    this.state = {
      loading: false,
      results: [],
      selected: props.location
    }
    this.timeoutInterval = null
  }

  onChange = (e) => {
    this.setState({ 
      results: [], 
      selected: {
        ...this.state.selected,
        text: e.target.value
      }
    })
    clearTimeout(this.timeoutInterval)
    this.timeoutInterval = setTimeout(() => {
      this.fetchMapbox()
    }, 500)
    
  }

  fetchMapbox = async() => {
    if (this.state.selected.text.length < 3) {
      return false
    }

    const query = `https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURIComponent(this.state.selected.text.trim())}.json?access_token=${process.env.REACT_APP_MAPBOX_TOKEN}&types=district,place`
    const res = await axios.get(query)
    this.setState({
      results: res.data.features
    })
  }

  onResultSelected = (obj) => {
    this.setState({
      results: [],
      selected: {
        text: obj.place_name,
        lat: obj.center[1],
        lng: obj.center[0],
        locationId: obj.id
      }
    }, () => {
      this.props.onSelected('location', this.state.selected)
    })
  }

  toggleResults = () => {
    this.setState({
      results: [],
      selected: {
        ...this.state.selected,
        lat: null,
        lng: null,
        locationId: null
      }
    })
  }

  render(){
    return (
      <Wrapper>
        <TextInput 
          label={this.props.label}
          name={this.props.name}
          type="text"
          autocomplete='new-password'
          value={this.state.selected.text}
          onChange={this.onChange}
          handleBlur={this.props.handleBlur}
          icon={this.props.icon}
        />
        <OutsideClick handleClickOutside={this.toggleResults}>
          <div>
            { this.state.results.length > 0 && (
              <Results>
                { this.state.results.map((obj) => (
                  <Result
                    key={obj.id}
                    onClick={() => { this.onResultSelected(obj) }}
                  >
                    { obj.place_name }
                  </Result>
                ))}
              </Results>
            )}
          </div>
        </OutsideClick>
        
      </Wrapper>
    )
  }
}

export default AutocompleteInput