import React from "react";
import styled from "styled-components";

function Icon({ color }) {
  return (
    <IconFilled color={color}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill={color}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7.07242 9.813L5.01442 11.871C4.17043 12.715 3.69629 13.8597 3.69629 15.0532C3.69629 16.2468 4.17043 17.3915 5.01442 18.2355C5.85841 19.0795 7.00309 19.5536 8.19667 19.5536C9.39025 19.5536 10.5349 19.0795 11.3789 18.2355L14.1209 15.492C14.6299 14.983 15.0093 14.3593 15.2273 13.6733C15.4453 12.9873 15.4956 12.259 15.374 11.5496C15.2523 10.8402 14.9622 10.1702 14.5281 9.59611C14.0939 9.02197 13.5284 8.56035 12.8789 8.25L11.9999 9.129C11.9107 9.2184 11.8331 9.31869 11.7689 9.4275C12.2707 9.57175 12.7262 9.84447 13.0903 10.2187C13.4544 10.5929 13.7145 11.0557 13.8449 11.5612C13.9754 12.0668 13.9716 12.5976 13.834 13.1013C13.6964 13.6049 13.4298 14.064 13.0604 14.433L10.3199 17.175C9.757 17.7379 8.99351 18.0542 8.19742 18.0542C7.40133 18.0542 6.63784 17.7379 6.07492 17.175C5.512 16.6121 5.19575 15.8486 5.19575 15.0525C5.19575 14.2564 5.512 13.4929 6.07492 12.93L7.26442 11.742C7.09659 11.1131 7.03175 10.4611 7.07242 9.8115V9.813Z"
          fill={color}
        />
        <path
          d="M9.87907 7.00801C9.37013 7.51702 8.99072 8.1407 8.77269 8.82669C8.55465 9.51268 8.50436 10.241 8.62603 10.9504C8.7477 11.6599 9.03778 12.3298 9.47193 12.9039C9.90607 13.478 10.4716 13.9397 11.1211 14.25L12.2836 13.086C11.775 12.9496 11.3113 12.6817 10.939 12.3093C10.5668 11.9369 10.2991 11.4731 10.1629 10.9644C10.0267 10.4558 10.0268 9.92028 10.1631 9.41168C10.2994 8.90308 10.5672 8.43932 10.9396 8.06701L13.6801 5.32501C14.243 4.76209 15.0065 4.44584 15.8026 4.44584C16.5987 4.44584 17.3622 4.76209 17.9251 5.32501C18.488 5.88793 18.8042 6.65142 18.8042 7.44751C18.8042 8.2436 18.488 9.00709 17.9251 9.57001L16.7356 10.758C16.9036 11.388 16.9681 12.0405 16.9276 12.6885L18.9856 10.6305C19.8296 9.78652 20.3037 8.64183 20.3037 7.44826C20.3037 6.25468 19.8296 5.10999 18.9856 4.26601C18.1416 3.42202 16.9969 2.94788 15.8033 2.94788C14.6097 2.94788 13.4651 3.42202 12.6211 4.26601L9.87907 7.00801Z"
          fill={color}
        />
      </svg>
    </IconFilled>
  );
}

const IconFilled = styled.span`
  fill: ${(props) => props.color || props.theme.primary || "black"};
`;

export default Icon;
