import React from 'react'
import { withTranslation } from 'react-i18next'
import styled from 'styled-components'
import TextInput from '../../../../components/TextInput/index'
import Button from '../../../../components/Button'
import ErrorMessage from '../../../../components/ErrorMessage'
import EmailIcon from '../../../../components/GlobalIcons/Email'
import LockIcon from '../../../../components/GlobalIcons/Lock'
import { PersonalDataRequestEnhancer } from '../../enhancers'


const Form = styled.form`
  padding: 0 0.5rem;
`

const ButtonWrapper = styled.div`
  padding-top: ${ props => props.theme.spacing.medium};
`

const PersonalDataForm = props => {
  const {
    values,
    touched,
    errors,
    handleChange,
    handleSubmit,
    isSubmitting,
    error,
    t
  } = props;
  return (
    <Form onSubmit={handleSubmit}>
      <TextInput 
      label={t('personal.name')}
        name="name"
        type="text"
        value={values.name}
        onChange={handleChange}      
      />
      {errors.name &&
        touched.name && (
          <ErrorMessage>{t(errors.name)}</ErrorMessage>
      )}
      <TextInput  
        label={t('personal.email')}
        name="email"
        type="text"
        value={values.email}
        onChange={handleChange}
        icon={(<EmailIcon color='secondary' />)}
      />
      {errors.email &&
        touched.email && (
          <ErrorMessage>{t(errors.email)}</ErrorMessage>
      )}
      <TextInput 
        label={t('personal.password')}
        name="password"
        type="password"
        value={values.password}
        onChange={handleChange}
        icon={(<LockIcon color='secondary' />)}
      />
      {errors.password &&
        touched.password && (
          <ErrorMessage>{t(errors.password)}</ErrorMessage>
      )}
      <TextInput 
        label={t('personal.confirm_password')}
        name="passwordConfirmation"
        type="password"
        value={values.passwordConfirmation}
        onChange={handleChange}
        icon={(<LockIcon color='secondary' />)}
      />
      {errors.passwordConfirmation &&
        touched.passwordConfirmation && (
          <ErrorMessage>{t(errors.passwordConfirmation)}</ErrorMessage>
      )}
      { error &&
        <ErrorMessage>{t(error)}</ErrorMessage>
      }
      <ButtonWrapper>
        <Button type='submit' disabled={isSubmitting}>
          {t('personal.next')}
        </Button>
      </ButtonWrapper>
    </Form>
  )
}


export default withTranslation()(PersonalDataRequestEnhancer(PersonalDataForm))