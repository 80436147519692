import React, { Component } from 'react'
import styled from 'styled-components'
import { post, get } from '../../../utils/api'
import AuthLayout from '../../../components/AuthLayout'
import ResetPassword from '../components/ResetPassword'
import { withTranslation } from 'react-i18next'

 class ResetPasswordContainer extends Component {

  constructor(props){
    super(props)
    this.state = { 
      password: '',
      passwordConfirmation: '',
      error: null,
      checkingHash: true,
      user: {},
      hash: null,
      submiting: false,
      success: false
    }
  }

  componentDidMount(){
    this.checkHash()
  }

  checkHash = async() => {
    const hash = this.props.match.params.hash || ''
    
    try {
      const res = await get(`/users/check_hash?hash=${hash}`)

      if (res.res.status == 200 ) {
        this.setState({
          checkingHash: false,
          user: res.res.user,
          hash: hash
        })
      } else {
        this.props.history.push('/login')
      }
    
    } catch (err) {
      this.props.history.push('/login')
    }
  }

  onSubmit = (e) => {
    e.preventDefault()
    //set new pass 
    const error = this.hasErrors()
    if (error) {
      this.setState({
        error: error
      })
      return false
    } else {
      this.updatePass()
    }
  }

  updatePass = async() => {
    try {

      this.setState({
        submiting: true    
      })

      const res = await post('/users/reset_password', {
        token: this.state.hash,
        password: this.state.password
      })

      if (res.res && res.res.status === 200) {
        localStorage.setItem('token', this.state.user.token)
        localStorage.setItem('id', this.state.user.id)
        this.setState({
          submiting: true,
          success: true
        })
      }
    } catch (err) {
      this.setState({
        error: this.props.t('http_errors.500'),
        submiting: false
      })
    }
  }

  hasErrors = () => {
    if (this.state.password.length < 1 || this.state.passwordConfirmation.length < 1) {
      return this.props.t('auth.reset.password.required')
    }
    
    if (this.state.password !== this.state.passwordConfirmation) {
      return this.props.t('auth.reset.password.match')
    }

    return false
  }

  render(){
    return (
      <AuthLayout>
        <Wrapper>
          <ResetPassword
          t={this.props.t}
          checkingHash={this.state.checkingHash}
          password={this.state.password}
          passwordConfirmation={this.state.passwordConfirmation}
          submit={this.onSubmit}
          submitting={this.state.submiting}
          success={this.state.success}
          error={this.state.error}
          handleChange={e => {
            this.setState({ 
              [e.target.name]: e.target.value,
              error: null
            })
          }}
        />
        </Wrapper>
      </AuthLayout>
    )
  }
}

const Wrapper = styled.div`
  background-color: white;
  display: inline-block;
  width: 30vw;
  z-index: 10;
  @media (max-width: 768px){
    width: 100%;
  }
`

export default withTranslation()(ResetPasswordContainer)