import React from 'react'
import styled from 'styled-components'

const Radio = ({id, checked, label, onChange, value}) => {
  return (
    <Wrapper>
      <RadioContainer htmlFor={id}>
        <StyledRadio>
          <HiddenRadio
            id={id} 
            checked={checked}
            value={value} 
            onChange={onChange}
          />
          <InnerCircle/>
        </StyledRadio>
        <Text>{label}</Text>
      </RadioContainer>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  padding: 1rem;
`

const Text = styled.span`
  margin-left: 1rem;
  word-break: break-word;
`

const RadioContainer = styled.label`
  display: flex;
  align-items: center;
`

const HiddenRadio = styled.input.attrs({ type: 'radio' })`
  display: none;
`

const StyledRadio = styled.div`
  position: relative;
  display: inline-block;
  min-width: 20px;
  min-height: 20px;
  border: 1px solid ${(props) => props.theme.light_gray };
  border-radius: 50%;
  
`

const InnerCircle = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transition: all 0.1s ease-in;
  width: 0;
  height: 0;
  border-radius: 50%;
  background: ${props => props.theme.primary};

  ${HiddenRadio}:checked + & {
    height: 12px;
    width: 12px;
    top: 4px;
    left: 4px;
  }
`

export default Radio