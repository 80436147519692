import React from "react";
import styled from "styled-components";

function Icon({ color }) {
  return (
    <IconFilled color={color}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill={color}
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_175_8262)">
          <path
            d="M12 22.5C9.21523 22.5 6.54451 21.3938 4.57538 19.4246C2.60625 17.4555 1.5 14.7848 1.5 12C1.5 9.21523 2.60625 6.54451 4.57538 4.57538C6.54451 2.60625 9.21523 1.5 12 1.5C14.7848 1.5 17.4555 2.60625 19.4246 4.57538C21.3938 6.54451 22.5 9.21523 22.5 12C22.5 14.7848 21.3938 17.4555 19.4246 19.4246C17.4555 21.3938 14.7848 22.5 12 22.5ZM12 24C15.1826 24 18.2348 22.7357 20.4853 20.4853C22.7357 18.2348 24 15.1826 24 12C24 8.8174 22.7357 5.76516 20.4853 3.51472C18.2348 1.26428 15.1826 0 12 0C8.8174 0 5.76516 1.26428 3.51472 3.51472C1.26428 5.76516 0 8.8174 0 12C0 15.1826 1.26428 18.2348 3.51472 20.4853C5.76516 22.7357 8.8174 24 12 24Z"
            fill={color}
          />
          <path
            d="M9.4065 7.58248C9.52918 7.51931 9.6669 7.49121 9.80452 7.50126C9.94214 7.51132 10.0743 7.55915 10.1865 7.63948L15.4365 11.3895C15.5337 11.4589 15.613 11.5505 15.6676 11.6566C15.7223 11.7628 15.7509 11.8805 15.7509 12C15.7509 12.1194 15.7223 12.2371 15.6676 12.3433C15.613 12.4495 15.5337 12.5411 15.4365 12.6105L10.1865 16.3605C10.0743 16.4407 9.94224 16.4885 9.80469 16.4986C9.66715 16.5086 9.52949 16.4806 9.40686 16.4175C9.28422 16.3544 9.18136 16.2587 9.10958 16.1409C9.03781 16.0232 8.99989 15.8879 9 15.75V8.24998C8.99986 8.1121 9.03772 7.97685 9.10943 7.8591C9.18115 7.74134 9.28393 7.64563 9.4065 7.58248Z"
            fill={color}
          />
        </g>
        <defs>
          <clipPath id="clip0_175_8262">
            <rect width="24" height="24" fill={color} />
          </clipPath>
        </defs>
      </svg>
    </IconFilled>
  );
}

const IconFilled = styled.span`
  fill: ${(props) => props.color || props.theme.primary || "black"};
`;

export default Icon;
