import React from 'react'
import styled, { keyframes } from 'styled-components'
import Spinner from '../Spinner'
import PropTypes from 'prop-types'

const Button = ({loading, disabled, onClick, ...props}) => (
  <StyledButton onClick={onClick} disabled={disabled} {...props}>
    { loading ? (
      <SpinnerContent>
        <Spinner/>
      </SpinnerContent>
    ) : props.children }
  </StyledButton>
)

const ripple = keyframes`
  from {
    opacity: 1;
    transform: scale(0);
  }

  to {
    opacity: 0;
    transform: scale(10)
  }
`

const SpinnerContent = styled.div`
  position: relative;
  width: 100%;
`

const StyledButton = styled.button`
  display: inline-block;
  position: relative;
  overflow: hidden;
  background-color: ${props => props.outline ? 'white' : props.theme[props.brandColor]};
  border: ${props => props.outline ? `1px solid ${props.color}` : 'none'};
  border-radius: 25px;
  color: ${props => props.outline ? props.color : 'white'};
  cursor: pointer;
  font-weight: bold;
  width: 100%;
  padding: 0.90rem 2rem;
  outline: none;
  text-align: center;
  font-size: 1rem;
  min-height: 3rem;

  ${props => props.white && `
    background-color: white;
    -webkit-box-shadow: 1px 1px 21px 6px rgba(0,0,0,0.16);-moz-box-shadow: 1px 1px 21px 6px rgba(0,0,0,0.16);box-shadow: 1px 1px 21px 6px rgba(0,0,0,0.16);
    border: none;
    color: #5B5E62;
  `};

  ${props => props.disabled && `
    background-color: hsl(0,0%,80%);
    color: white;
    border: none;
    cursor: default;
  `};

  &:after{
    display: none;
    content: "";
    position: absolute;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.3);
    
    width: 100px;
    height: 100px;
    margin-top: -50px;
    margin-left: -50px;
    
    /* Center the ripple */
    top: 50%;
    left: 50%;

    animation: ${ripple} 1s;
    opacity: 0;
  }

  &:focus:not(:active)::after{
    display: block;
  }
`

Button.defaultProps = {
  color: '#676767',
  brandColor: "primary",
  disabled: false
}

Button.propTypes = {
  color: PropTypes.string,
  handleClick: PropTypes.func,
  outline: PropTypes.bool,
  loading: PropTypes.bool
}

export default Button