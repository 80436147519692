import React, {useRef, useState} from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

const TextInput = ({ label, ...props }) => {
  const [isShow, showLabel] = useState(false)
  const labelEl = useRef(null)
  const onFocusInput = e => {
    e.target.placeholder = ''
    showLabel(true)
  }
  const handleOnBlur = e => {
    e.target.placeholder = label
    if(!e.target.value){
      showLabel(false)
    }
  }
  return (
    <Wrapper>
      { isShow && <Label {...props} ref={labelEl}> { label }</Label> }
      <Input 
        {...props} 
        placeholder={label} 
        onFocus={ e => onFocusInput(e)} 
        onBlur={ e => handleOnBlur(e)}
      />
      <IconWrapper>
      { !!(props.icon) && props.icon }
      </IconWrapper>      
    </Wrapper>
  )
}

const Wrapper = styled.div`
  color: ${props => props.status === 'failure' ? '#F44336' : '#686868'};
  position: relative;
  margin-bottom: 0.7rem;  
  height: 3.4rem;
`

const Label = styled.label`
  display: block;
  font-size: 0.8rem;
  font-weight: ${props => props.isBold ? 'bold' : '400'};
  margin-bottom: 0.5rem;
  font-family: sans-serif;
  color: #c2c2c2;
  
`

const IconWrapper = styled.div`
  position: absolute;
  bottom: 6px;
  right: 6px;
  
  > svg {
    height: 24px;
    width: 24px;
  }
`

const Input = styled.input`
  border: none;
  border-bottom: 1.5px solid ${props => props.status === 'failure' ? '#F44336' : '#d2d7dc'};
  padding: 0.5rem 0 0.8rem 0;
  font-size: 1rem;
  font-weight: ${props => props.isBold ? 'bold' : '400'};
  outline: none;
  width: 100%;
  color: ${props => props.status === 'failure' ? '#F44336' : '#5B5E62'};

  position: absolute;
  bottom: 0;
`

TextInput.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  type: PropTypes.string
}

export default TextInput