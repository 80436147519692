import React, { Component } from 'react'
import styled from 'styled-components'
import AuthLayout from '../../../components/AuthLayout'
import ForgotPassword from '../components/ForgotPassword'
import { post } from '../../../utils/api'
import { withTranslation } from 'react-i18next'


class ForgotPasswordContainer extends Component {
  constructor(props){
    super(props)
    this.state = { 
      email: '',
      error: null,
      submitting: false,
      success: false
    }
  }

  onSubmit = (e) => {
    e.preventDefault()
    if(this.state.email.trim().length > 0) {
      //submit 
      this.requestReset()
    } else {
      this.setState({
        error: this.props.t('auth.forgot.required')
      })
    }
  }

  requestReset = async() => {
    try {
      this.setState({
        submitting: true
      })

      await post('/users/forgot_password', {
        email: this.state.email,
        url: window.location.origin,
        game: process.env.REACT_APP_ORGANIZATION_ID
      })

      this.setState({
        success: true,
        submitting: false
      })

    } catch (err) {
      let error = ''
      error = this.props.t('http_errors.' + 'err.status')    

      this.setState({
        submitting: false,
        error
      })
    } 
  }

  render(){
    return(
      <AuthLayout>
        <Wrapper>
          <ForgotPassword 
            t={this.props.t} 
            success={this.state.success}
            submit={this.onSubmit}
            submitting={this.state.submitting}
            email={this.state.email}
            error={this.state.error}
            handleChange={(e) => {
              this.setState({ 
                email: e.target.value,
                error: null
              })
            }}
          
          />
        </Wrapper>
      </AuthLayout>
    )
  }
}

const Wrapper = styled.div`
  background-color: white;
  display: inline-block;
  z-index: 10;
  @media (max-width: 768px){
    width: 100%;
  }
`

export default withTranslation()(ForgotPasswordContainer)