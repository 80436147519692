import React from 'react'
import { useQuery } from '@apollo/react-hooks'
import { gql } from 'apollo-boost'
import styled from 'styled-components'
import AboutSkeleton from '../../components/AboutSkeleton';
import Title from '../../components/Title';
import PageTitle from '../../components/PageTitle';
import About from '../../components/GlobalIcons/About';
import GameLogo from '../../components/GameLogo';
import { withTranslation } from 'react-i18next'

export const GET_GAME_DESCRIPTION = gql`
  query game($_id: String) {
    game(_id: $_id) {
      _id
      description
      title
      isShow
    }
  }
`
const AboutUs = ({t}) => {
  const { loading, data } = useQuery(GET_GAME_DESCRIPTION, {
    variables: { 
      _id: process.env.REACT_APP_ORGANIZATION_ID 
    },
  });

  return (
    <React.Fragment>
      {loading ? <AboutSkeleton /> : 
        <Wrapper>  
        <PageTitle padding={'0'}>
          <About />
            <Title padding={'0'}>{t("bottom_bar.about_us")}</Title>
            {/* <Title padding={'0'}>Acerca de</Title> */}
          </PageTitle>
          <Content>
              <GameLogo width={'260px'}/>
            </Content>
          <Title padding={'1rem 0 1rem 0'} >{data.game.title}</Title> 
          <Description>{data.game.description}</Description>
        </Wrapper>}
    </React.Fragment>
  );
}

const Wrapper = styled.div`
  margin: 8rem 3rem;
  display: block;
  @media(max-width:600px){
    margin: 3rem;
    padding-bottom: 2.5rem;
  }
`
const Content = styled.div`
  dispay: flex;
  flex-direction: column;
  padding: 0.5rem 0 1rem 0;
  gap: 1rem;
`

const Description = styled.div`
  font-size: ${props => props.size};
  font-weight: 300;
  letter-spacing: 1px;
  line-height: 1.2;
  word-break: break-word;
`

export default withTranslation()(AboutUs);