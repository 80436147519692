export const checkAndRedirectToAxis = (progress, history) => {
    for (const axisId in progress) {
      if (progress.hasOwnProperty(axisId)) {
        const axis = progress[axisId];
        if (axis.user < axis.total) {
          // primer eje c logros sin completar
          return history.push(`/axis/${axisId}`);
        }
      }
    }
    // todos los ejes tienen c logros completos
    return history.push('/axis');
  };
  