import React from "react";
const Star = () => (
  <svg width="56px" height="57px" viewBox="0 0 56 57" version="1.1">
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="Dashboard"
        transform="translate(-76.000000, -307.000000)"
        fillRule="nonzero"
      >
        <g id="Group-4" transform="translate(46.000000, 287.000000)">
          <g id="Group-3" transform="translate(30.000000, 20.000000)">
            <g id="Group-2">
              <g id="star">
                <ellipse
                  id="Oval"
                  fill="#FF4B4E"
                  cx="2.03389831"
                  cy="38"
                  rx="2.03389831"
                  ry="2"
                ></ellipse>
                <ellipse
                  id="Oval"
                  fill="#FFCA54"
                  cx="11.1864407"
                  cy="16"
                  rx="2.03389831"
                  ry="2"
                ></ellipse>
                <ellipse
                  id="Oval"
                  fill="#00CDB3"
                  cx="37.6271186"
                  cy="11"
                  rx="2.03389831"
                  ry="2"
                ></ellipse>
                <g
                  id="Group"
                  transform="translate(17.288136, 0.000000)"
                  fill="#57BAE5"
                >
                  <ellipse
                    id="Oval"
                    cx="36.7057827"
                    cy="37.9866667"
                    rx="1.90496249"
                    ry="1.89119497"
                  ></ellipse>
                  <ellipse
                    id="Oval"
                    cx="2.01810725"
                    cy="1.92465409"
                    rx="1.90496249"
                    ry="1.89119497"
                  ></ellipse>
                </g>
                <path
                  d="M52.3244299,24.7496639 C51.3660317,24.6143373 36.823421,22.5604238 35.9289761,22.4341022 L28.5966127,7.99379737 C27.9246531,6.66929709 25.9742717,6.66817145 25.301154,7.99379737 L17.9687907,22.4341022 L1.57333687,24.7496639 C0.881174736,24.8473443 0.306238799,25.3187363 0.0899300902,25.9652275 C-0.126121261,26.6118438 0.0541574423,27.3216208 0.554974512,27.7961396 L12.4187544,39.0361317 L9.61806492,54.9077473 C9.36147981,56.3630717 10.9380499,57.4783285 12.2842853,56.7905639 L26.949012,49.2970691 C42.903613,57.4498123 41.82786,56.9954299 42.468422,56.9954299 C43.6009223,56.9954299 44.4729771,56.0032429 44.2797018,54.9074972 L41.4790123,39.0361317 L53.3429209,27.7961396 C54.4311557,26.7650556 53.829712,24.9622844 52.3244299,24.7496639 Z"
                  id="Path"
                  fill="#FFCA54"
                ></path>
                <ellipse
                  id="Oval"
                  fill="#57BAE5"
                  cx="3.55932203"
                  cy="13.5"
                  rx="1.52542373"
                  ry="1.5"
                ></ellipse>
                <ellipse
                  id="Oval"
                  fill="#00CDB3"
                  cx="48.3050847"
                  cy="40.5"
                  rx="1.52542373"
                  ry="1.5"
                ></ellipse>
                <ellipse
                  id="Oval"
                  fill="#FFCA54"
                  cx="4.57627119"
                  cy="48.5"
                  rx="1.52542373"
                  ry="1.5"
                ></ellipse>
                <g
                  id="Group"
                  transform="translate(13.220339, 8.000000)"
                  fill="#FF4B4E"
                >
                  <path
                    d="M31.2244371,3.55786667 C30.8056514,3.1372 30.7962332,2.44533333 31.2031495,2.01266667 L32.672125,0.450933333 C33.0790412,0.0182666667 33.7485049,0.0084 34.1671616,0.429066667 C34.5860764,0.849733333 34.5953655,1.5416 34.1884493,1.97426667 L32.7196028,3.536 C32.3116544,3.96906667 31.6423198,3.97786667 31.2244371,3.55786667 Z"
                    id="Path"
                  ></path>
                  <path
                    d="M2.64108525,2.95733333 L0.687654946,2.14093333 C0.146304073,1.9148 -0.115211232,1.27786667 0.103728814,0.718266667 C0.322539843,0.1588 0.93871996,-0.111466667 1.48032886,0.1148 L3.4340172,0.9312 C3.97536807,1.15733333 4.23662535,1.79426667 4.01781432,2.35386667 C3.79939034,2.91266667 3.18333924,3.18386667 2.64108525,2.95733333 Z"
                    id="Path"
                  ></path>
                </g>
                <path
                  d="M6.68202624,44.9202092 L4.72885999,44.1043951 C4.18778276,43.878424 3.92620836,43.2418145 4.14521888,42.6827493 C4.36397144,42.1235509 4.98011587,41.8541444 5.52145106,42.0797157 L7.47461731,42.8956631 C8.01543657,43.1215009 8.27713995,43.7581104 8.05825841,44.3173088 C7.83834502,44.8790387 7.21974994,45.1451143 6.68202624,44.9202092 Z"
                  id="Path"
                  fill="#00CDB3"
                ></path>
                <path
                  d="M50.4108334,47.9200306 L48.4576318,47.1042712 C47.9165449,46.8783153 47.6550947,46.2417485 47.8739802,45.6827208 C48.0927367,45.1238264 48.7088923,44.853905 49.2502372,45.0798609 L51.2034387,45.8954871 C51.7445257,46.121443 52.0058469,46.7580098 51.7870904,47.3170374 C51.5701396,47.8714021 50.9577246,48.1477184 50.4108334,47.9200306 Z"
                  id="Path"
                  fill="#57BAE5"
                ></path>
                <g
                  id="Group"
                  transform="translate(0.000000, 7.000000)"
                  fill="#F99830"
                >
                  <path
                    d="M22.595061,13.393875 L28.7052812,1.332125 L28.5478489,1.021375 C27.8778315,-0.302375 25.9330873,-0.3035 25.2619151,1.021375 L17.9507445,15.4535 L20.4256518,15.02525 C20.599764,14.9935 21.9279946,14.710125 22.595061,13.393875 Z"
                    id="Path"
                  ></path>
                  <path
                    d="M13.5708681,47.225875 L16.0577079,33.1 C16.3502471,31.43575 15.3107065,30.363625 15.3073705,30.360375 L4.53371614,20.129375 C3.66418185,19.30375 3.84393949,17.8885 4.90837161,17.29975 L1.60242199,17.767625 C0.912260415,17.86525 0.338986219,18.336375 0.123302709,18.9825 C-0.0921241882,19.62875 0.0876334548,20.338125 0.58700301,20.812375 L12.416493,32.046 L9.62402661,47.90875 C9.36818311,49.36325 10.9401964,50.477875 12.2825408,49.7905 L14.1372137,48.84075 C13.6830081,48.434 13.4647584,47.827375 13.5708681,47.225875 Z"
                    id="Path"
                  ></path>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default Star;
