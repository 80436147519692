import React from 'react'
import styled from 'styled-components'
import Award from '../../../../../../components/GlobalIcons/Award'
import Title from '../../../../../../components/Title'

const parsedQuestion = (question) => {
  return question.length > 60 ? question.substring(0, 60) + '...' : question
}


const ContinueAxis = ({data, history, loading, t, toggleAxisColor}) => {
  return loading ? (
    <div>
      <LoadingContinueAxis/>
    </div>
  ) : (
    <div>
      { data && (
        <React.Fragment>
          <Title fontSize={'medium'} padding={'1rem 0 1rem 2.4rem'}>{t('home.resume')}</Title>
          <Wrapper onClick={() => { 
            if (data.color) {
              toggleAxisColor(data.color)
            }  

            history.push(`/axis/${data.id}`)             
          }}>
            <Head>
              <HeadTitle>
                {data.name}
              </HeadTitle>
              <Achievements>
                <span>
                  {data.count}
                </span>
                <Award color={'white'} />
              </Achievements>
            </Head>
            <Content>
              {parsedQuestion(data.question)}
            </Content>
          </Wrapper>
        </React.Fragment>
      )}
      
    </div>    
  )
}


// const Title = styled.div`
//   padding: 1rem 0;
// `

const Wrapper = styled.div`
  margin: 0 2rem
  padding: 1rem;
  background: ${props => props.theme.white};
  border-radius: ${(props) => props.theme.border_radius.rounded_lg};
  border: 1px solid ${(props) => props.theme.light_gray };
  color: ${props => props.theme.gray};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
`

const LoadingContinueAxis = styled.div`  
  background: #dedede;
  border-radius: 4px;
  height: 100px;
  padding: 1rem;
`
const Head = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;  
`

const HeadTitle = styled.div`
  font-size: 1.1rem;
  font-weight: 600;
  color: ${props => props.theme.primary};
`

const Content = styled.div`
  padding-top: 0.5rem;
`

const Achievements = styled.div`
  display: flex;
  padding: 0.2rem 0.5rem;
  border-radius: 25px;
  background: ${props => props.theme.primary};
  justify-content: space-between;
  align-items: center;
  color: ${props => props.theme.white};
  > span {
    font-weight: 500;
    padding-right: 0.5rem;
  }
  > svg {    
    height: 20px;
    fill: ${props => props.theme.secondary};
  }
`

// export default withTranslation(ContinueAxis)
export default ContinueAxis