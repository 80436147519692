import { isNil, isEmpty } from 'ramda'

export const isAuthenticated = ()  => {
  const token = localStorage.getItem('token')
  if (token === 'undefined' || isNil(token) || isEmpty(token)) return false

  return true
}

export const handleLogout = () => {
  localStorage.removeItem('token')
  localStorage.removeItem('user_id')

  window.location.replace('/')
}

export const formatSelectOptions = (options) => {
  const nextOpts = options.map(o => ({
    label: o.name,
    value: o.id
  }))

  return nextOpts
}


export const normalizeAchievements = progress => progress.filter( p => p.achievement ).map( a => a.achievement)

export const normalizeSuggestions = progress => progress.filter( p => p.suggestion).map( s => s.suggestion)

export const filterAchievementsByAxis = (achievements, axisId) => achievements.filter(ach => ach.axis._id === axisId)

export const findLeftAchievements = (axisAchievements, userAchievements) => {
  const userAchIds = userAchievements.map( ach => ach.question._id)
  const leftAch = axisAchievements.filter(x => !userAchIds.includes(x.question._id))

  const filterAchievements = filterDuplicates(leftAch)

  return filterAchievements
}

export const filterDuplicates = (achievements) => {
  const aux = new Set()

  const filterDuplicates = achievements.filter(ach => {
    const duplicate = aux.has(ach.question._id)
    aux.add(ach.question._id)
    return !duplicate
  } )

  return filterDuplicates
}


export const findQuestionsByAchievements = (questions, leftAch) => {
  const achIds = leftAch.map( ach => ach._id)
  const aux = []
  questions.forEach( q => {
    q.answers.forEach( ans => {
      if(ans.achievement && achIds.includes(ans.achievement._id)){
        aux.push(q)
      }
    })
  })
  
 return aux
}

export const parseProgressResponse = (res, handleProgress) => {
  let axes = {};
  const progress = res.game.currentUser.progress;
  res.game.axes.forEach((element) => {
    axes[element._id + ""] = element.achievements && element.achievements;
  });
  let finalAxes = {};
  Object.keys(axes).forEach((axisId) => {
    const achievements = normalizeAchievements(progress);
    const axisAchievements = achievements.filter(
      (ach) => ach.axis._id === axisId
    );
    finalAxes[axisId] = {
      total: filterDuplicates(axes[axisId]).length,
      user: axisAchievements.length,
    };
  });
  handleProgress(finalAxes);
};