import React, { useState, useEffect } from "react";
import Dashboard from "../components/Dashboard";
import { GET_GAME } from "../../../queries/GameOptions";
import { useQuery } from "@apollo/react-hooks";
import AxisSkeletonGrid from "../../../components/AxisSkeletonGrid";
import {
  parseProgressResponse
} from "../../../utils/helpers";

const DashboardContainer = (props) => {
  const [progress, handleProgress] = useState({});
  const { loading, error, data, refetch } = useQuery(GET_GAME, {
    fetchPolicy: "cache-and-network",
    variables: {
      _id: process.env.REACT_APP_ORGANIZATION_ID,
    },
    onCompleted: (res) => {
      parseProgressResponse(res, handleProgress);
    },
  });
  
  useEffect(() => {
    if (!loading) {
      refetch();
    }
  }, []);

  if (loading) {
    return <AxisSkeletonGrid />;
  }

  return <Dashboard data={data} progress={progress} />;
};

export default DashboardContainer;
