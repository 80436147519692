import React from "react";
import styled, { css } from "styled-components";
import Wrapper from "../AnimatedWrapper";
import t from "../../utils/i18n";
import IconSelector from "../IconSelector";
import BgWrapper from "./BgWrapper";
import MiniStars from "../GlobalIcons/MiniStars";
import Title from "../Title";
import Text from "../Text";
import StretchedCard from "../StretchedCard";
import RigthArrow from "../GlobalIcons/ChevronRight";
import ButtonClose from "../GlobalIcons/ButtonClose";

const SuggestionCard = ({ suggestion, children, isModal }) => {
  return (
    <>
      {" "}
      <ModalOverlay>
        <ModalWrapper className={isModal ? "modal" : ""}>
          {/* <ButtonClose/> */}
          <IconWrapper>
            <MiniStars color={"white"} />
          </IconWrapper>

          <AxisName>{suggestion.axis.name}</AxisName>
          <ModalOpen>
            <Title>{suggestion.title}</Title>
            <Text fontSize={"medium"} padding={"20px"} textAlign={"center"}>
              {suggestion.description}
            </Text>

            {suggestion.materials && suggestion.materials.length > 0 && (
              <>
                <Text
                  fontWeight={"700"}
                  color={"black"}
                  fontSize={"medium"}
                  padding={"10px 0 0 30px"}
                >
                  {t("suggestions.material")}
                </Text>
                <MatDivContainer>
                  <MatDiv>
                    {suggestion.materials &&
                      suggestion.materials.map((material) => (
                        <StretchedCard margin={"1rem 0"} key={material._id} ma>
                          <MaterialItem
                            href={material.link}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <IconSelector type={material.type} />
                            <Text
                              color={"black"}
                              fontSize={"medium"}
                              padding={"0 0 0 10px"}
                            >
                              {material.name}
                            </Text>
                          </MaterialItem>
                        </StretchedCard>
                      ))}
                  </MatDiv>
                </MatDivContainer>
              </>
            )}
            <ButtonWrapper>{children}</ButtonWrapper>
          </ModalOpen>
        </ModalWrapper>
      </ModalOverlay>
    </>
  );
};
const ModalOverlay = styled.body`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
  @media (max-width: 600px) {
  }
`;
const ModalOpen = styled.div`
  overflow: hidden;
  position: relative;
`;

const MatDivContainer = styled.div`
  height: 110px;
  overflow-y: scroll;
  @media (max-width: 600px) {
    height: 110px;
  }
`;

const MatDiv = styled.div`
  overflow-y: scroll;
`;

const IconWrapper = styled.div`
  margin: 20px auto;
  display: flex;
  justify-content: center;
  width: 15px;
  height: 15px;
  padding: 10px;
  border-radius: 50%;
  background: ${(props) => props.theme.primary};
`;

const ModalWrapper = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  text-align: center;
  border-radius: ${(props) => props.theme.border_radius.rounded_lg};
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  width: 500px;
  @media (max-width: 600px) {
    width: 300px;
    height: 400px;
    overflow-y: auto;
  }
  height: auto;
  // overflow-y: auto;
`;

const AxisName = styled.div`
  color: ${(props) => props.theme.primary};
  background: ${(props) => props.theme.slate_gray};
  border-radius: ${(props) => props.theme.border_radius.rounded_md};
  padding: 5px;
  width: fit-content;
  margin: 0 auto;
  display: flex;
  justify-content: center;
`;

const Subtitle = styled.p`
  font-size: 0.9rem;
  margin: 0.5rem;
`;

const SuggestionBody = styled.div`
  color: black;
  margin: 0 auto;
  width: 300px;
`;

const BodyTitle = styled.h4`
  font-size: 0.9rem;
  font-weight: bold;
`;

const MaterialGrid = styled.div`
  background-color: black;
  border-radius: 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0.5rem;
  width: 100%;
  margin-top: 0.5rem;
  box-sizing: border-box;
`;

const MaterialItem = styled.a`
  cursor: pointer;
  color: ${(props) => props.theme.primary};
  display: flex;
  align-items: center;
  text-decoration: none;
`;

const MaterialText = styled.span`
  color: #67696d;
  font-size: 0.9rem;
  margin-left: 0.5rem;
`;

const ButtonWrapper = styled.div`
  margin: 0 auto;
  width: 300px;
  display: flex;
  justify-content: center;
`;

export default SuggestionCard;
