import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import DownArrow from './assets/DownArrow'

const Collapse = () => {
  const [isOpen, toggleCollapse] = useState(false)

  return (
    <Wrapper>
      <CollapseWrapper isOpen={isOpen}>
        <CollapseBody isOpen={isOpen}>
          
        </CollapseBody>
      </CollapseWrapper>
      <ButtonWrapper>
        <ArrowButton onClick={() => toggleCollapse(!isOpen)} isOpen={isOpen}>
          <DownArrow />
        </ArrowButton>
      </ButtonWrapper>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  background-color: ${props => props.theme.primary};
  left: 0;
  margin-top: 7.5rem;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 10;
`

const CollapseWrapper = styled.div`
  height: ${props => !props.isOpen ? '0px' : '100%'};
  transition: height 0.5s ease;

`

const CollapseBody = styled.p`
  font-weight: normal;
  margin: 0 auto;
  padding: 1rem;
  text-align: left;
  width: 300px;
  display: ${props => props.isOpen ? 'block' : 'none'};
`


const ButtonWrapper = styled.div`
  padding: 1rem; 
`

const ArrowButton = styled.button`
  border: none;
  background: none;
  color: white;
  cursor: pointer;
  transform: ${props => props.isOpen ? 'rotate(180deg)' : ''};
  transition: transform 0.3s ease;
  outline: none;
`

export default Collapse