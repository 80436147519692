import React, {useState} from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { GET_SUGGESTIONS } from "../../queries/suggestion";
import { normalizeSuggestions } from "../../utils/helpers";
import { useQuery } from "react-apollo";
import SuggestionSkeleton from "../SuggestionSkeleton";
import Title from "../Title";
import MiniStars from "../GlobalIcons/MiniStars";
import Arrow from "../GlobalIcons/Arrow";
import ArrowLeft from "../GlobalIcons/ArrowReturnLeft";
import PageTitle from "../PageTitle";
import Modal from "../Modal";

const List = ({ match, t }) => {
  const [selectedSuggestion, setSelectedSuggestion] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const axisId = match.params.axisId;
  // console.log(axisId)
  const { loading, data } = useQuery(GET_SUGGESTIONS);
  if (loading) return <SuggestionSkeleton />;

  const { progress } = data.user;
  const suggestions = normalizeSuggestions(progress);
  const filterSuggestion = suggestions.filter((s) => s.axis._id === axisId);

  const openModal = (suggestion) => {
    setSelectedSuggestion(suggestion);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setSelectedSuggestion(null);
    setIsModalOpen(false);
  };

  return (
    <Wrapper>
      <PageTitle margin={'2rem 2rem'} padding={'0'}>
        <Title>{filterSuggestion[0].axis.name}</Title>
      </PageTitle>
      <Title fontSize={'medium'} padding={'1rem 0 0 0'}>{t('home.latest_recommendations')}</Title>
      <Content>
        {/* {t('suggestions.item.content')}  */}
        {/* {filterSuggestion[0].axis.name} */}
      </Content>
      {filterSuggestion.map((suggestion) => {
        const description =
          suggestion.description.length <= 100
            ? suggestion.description
            : suggestion.description.substring(0, 100).concat("...");
        return (
          <>          
          {/* <StyledLink to={`/suggestion/${suggestion._id}`} key={suggestion._id}>
            <SuggestionItem color={filterSuggestion[0].axis.pallete}>
             <TitleContent>
              <MiniStars />
              <ItemTitle>{suggestion.title}</ItemTitle>
              </TitleContent>
              <IconWrapper>
                <Arrow />
              </IconWrapper>
            </SuggestionItem>
          </StyledLink> */}
          <StyledLink key={suggestion._id}>
            <SuggestionItem color={filterSuggestion[0].axis.pallete}
            onClick={() => openModal(suggestion)}>
             <TitleContent>
              <MiniStars />
              <ItemTitle>{suggestion.title}</ItemTitle>
              </TitleContent>
              <IconWrapper>
                <Arrow />
              </IconWrapper>
            </SuggestionItem>
          </StyledLink>
          </>
        );
      })}
      {isModalOpen && selectedSuggestion && (
        <Modal suggestion={selectedSuggestion} closeModal={closeModal} />
      )}
    </Wrapper>
  );
};
const StyledLink = styled(Link)`
  text-decoration: none;
  display: flex;
  justify-content: space-between;
`;

const Wrapper = styled.div`
  padding: 0rem 3rem;
`;

const TitleContent = styled.div`
  display: flex;
  gap: 0.5rem;
`

const IconWrapper = styled.span`
  display: flex;
  justify-content: end;
`;

const Content = styled.p`
  color: ${(props) => props.theme.primary};
`;

const SuggestionItem = styled.div`
  color: ${(props) => props.theme.copies};
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  gap: 1rem
  align-items: center;
  padding: 1rem;
  width: 100%;
  background: white;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
  border-radius: ${(props) => props.theme.border_radius.rounded_lg};
  border: 1px solid ${(props) => props.theme.light_gray};
  height: 40px
  
`;

const ItemTitle = styled.div`
  font-weight: 500;
  margin-left: 0.5rem;
`;

const ItemDescription = styled(ItemTitle)`
  color: #67696d;
  font-weight: normal;
`;

export default withTranslation()(List);
