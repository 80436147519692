import React, { useState, useEffect } from "react";
import styled from "styled-components";
import t from "../../utils/i18n";
import Text from "../Text";
import HomeIcon from "../../components/GlobalIcons/HomeIcon";
import LogOut from "../../components/GlobalIcons/LogOut";
import Joystick from "../../components/GlobalIcons/Joystick";
import MiniStars from "../../components/GlobalIcons/MiniStars";
import InfoCercle from "../../components/GlobalIcons/InfoCercle";
import { REMOVE_USER_PROGRESS } from "../../queries/user";
import { useMutation } from "@apollo/react-hooks";
import { useQuery } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import GameLogo from '../GameLogo'


export const GET_SHOW_GAME = gql`
  query game($_id: String) {
    game(_id: $_id) {
      _id
      isShow
    }
  }
`;

const Sidebar = ({ handleLogout, user, history }) => {
  const [currentTab, setCurrentTab] = useState("");

  const { data } = useQuery(GET_SHOW_GAME, {
    variables: {
      _id: process.env.REACT_APP_ORGANIZATION_ID,
    },
  });

  useEffect(() => {
    setCurrentTab(history.location.pathname);
  }, [history.location.pathname]);

  const removeProgressMessage = t("navbar.remove");
  const [removeProgress, { loading }] = useMutation(REMOVE_USER_PROGRESS, {
    refetchQueries: ["game"],
    onCompleted: () => history.go(0),
  });

  const about = t("navbar.about");

  return (
    <Wrapper>
      <div>
        <LogoContainer>
         <GameLogo />
        </LogoContainer>
        {user ? (
          <>
            <AvatarWrapper>
              {/* <LetterAvatar name={user.name} size="60" /> */}
              <HandWrapper>👋</HandWrapper>
              <div>
                <Text
                  fontSize={"medium"}
                  fontWeight={"600"}
                  padding={"0 0 0 10px"}
                  color={"black"}
                >
                  {user.name}
                </Text>
                <Text
                  fontSize={"medium"}
                  fontWeight={"500"}
                  padding={"0 0 0 10px"}
                >
                  {user.organization_name}
                </Text>
              </div>
            </AvatarWrapper>
          </>
        ) : (
          <p>loading</p>
        )}
      </div>
      <ContentWrapper>
        <TabWrapper>
          {/* <Tab onClick={() => { history.push('/')}} selected={history.location.pathname === '/'}>
              <HomeIcon/>
              <span>{t('navbar.home')}</span>
            </Tab>

            <Tab onClick={() => { history.push('/axis')}} selected={history.location.pathname.includes('axis')}>
              <GamepadIcon/>
              <span>{t('navbar.axis')}</span>
            </Tab>

            <Tab onClick={() => { history.push('/suggestions')}} selected={history.location.pathname.includes('suggestions')}>
              <BulbIcon/>
              <span>{t('navbar.suggestions')}</span>
            </Tab> */}

          <Tab
            onClick={() => {
              history.push("/");
            }}
            selected={currentTab === "/"}
            className={currentTab === "/" ? "activo" : ""}
          >
            <HomeIcon />
            <span>{t("navbar.home")}</span>
          </Tab>

          <Tab
            onClick={() => {
              history.push("/axis");
            }}
            selected={currentTab.includes("axis")}
            className={currentTab.includes("axis") ? "activo" : ""}
          >
            <Joystick />
            <span>{t("navbar.axis")}</span>
          </Tab>

          <Tab
            onClick={() => {
              history.push("/suggestions");
            }}
            selected={currentTab.includes("suggestions")}
            className={currentTab.includes("suggestions") ? "activo" : ""}
          >
            <MiniStars color={"black"} />
            <span>{t("navbar.suggestions")}</span>
          </Tab>

          <Tab
            onClick={() => {
              history.push("/about-us");
            }}
            selected={currentTab.includes("about-us")}
            className={currentTab.includes("about-us") ? "activo" : ""}
          >
            <InfoCercle />
            <span>{about}</span>
          </Tab>

          {/* { data && data.game.isShow &&
              <Tab onClick={() => { history.push('/about-us')}} selected={history.location.pathname.includes('about-us')}>
            
                <AboutIcon />
                <span>{about}</span>
              </Tab>
            }  */}

          {user && user.role === "admin" && (
            <ResetTab onClick={() => removeProgress()} loading={loading}>
              {removeProgressMessage}
            </ResetTab>
          )}
          <hr></hr>
          <LogoutTab onClick={() => handleLogout()}>
            <Content>
              <LogOut />
              <span>{t("navbar.logout")}</span>
            </Content>
          </LogoutTab>
        </TabWrapper>
      </ContentWrapper>
    </Wrapper>
  );
};


const LogoContainer = styled.div`
display: flex;
justify-content: center
`

const Tab = styled.div`
  display: flex;
  gap:20px;
  align-items: center;  
  transition: all 0.2s;
  padding: 1rem 0;
  cursor: pointer;
  transition: all 0.2s;

  > span {
    font-weight: 500,
    margin-left: 1rem;
    color: #464646;
  }

  > svg {
    fill: #464646;
  }

  &:hover {
    svg,
    span {
      color: ${(props) => props.theme.primary};
      fill: ${(props) => props.theme.primary};
    }
  }

  &.activo {
    > span, 
    svg {
      color: ${(props) => props.theme.primary};
      fill: ${(props) => props.theme.primary};
   //   transform: scale(1.05); 
    }

  }
}
`;

const Wrapper = styled.div`
  background-color: #fafafa;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 9999;
  width: 0;
  visibility: hidden;
  opacity: 0;
  width: 200px;
  border-right: 1px solid ${(props) => props.theme.primary};
  visibility: visible;
  opacity: 1;
  height: 100vh;
  display: block;
  justify-content: initial;
  padding: 2rem;
`;

const ContentWrapper = styled.div`
  padding-top: 1rem;
  display: flex;
  flex-direction: column;
`;

const AvatarWrapper = styled.div`
  padding-top: 20px;
  display: flex;
  align-items: center;
`;

const HandWrapper = styled.div`
  display: flex;
  align-items: center;
  font-size: 30px;
`;

const UserinfoItem = styled.span`
  color: ${(props) => props.theme[props.color]};
  font-size: ${(props) => props.theme.font_size[props.textSize]};
  font-weight: bold;
  display: block;
  padding-bottom: 0.5rem;
  padding-left: 0.5rem;
`;

const LogoutTab = styled.div`
  padding: 1rem 0;
  cursor: pointer;
`;

const ResetTab = styled.div`
  color: ${(props) => props.theme.primary};
  font-weight: bold;
  padding: 1rem 0;
  cursor: pointer;
`;

const TabWrapper = styled.div`
  padding: 0 1rem;
`;

const Content = styled.div`
  display: flex;
  gap: 10px;
  &:hover {
    svg,
    span {
      fill: ${(props) => props.theme.primary};
      color: ${(props) => props.theme.primary};
    }
  }
`;

export default Sidebar;
