import React from 'react'
import styled from 'styled-components'
import { darken } from 'polished'

const Background = () => {

  return (
    <Container>
      <Wrapper>
        
        <div class="background">
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </Wrapper>
    </Container>
  )
}

const Container = styled.div`
  min-width: 40vw;
  width: 50vw;
  height: 100%;
  position: relative;
  overflow: hidden;
  border-radius: 10px;

  @media (max-width: 768px){
    display: none;
  }
`


const Wrapper = styled.div`
@keyframes move {
  100% {
      transform: translate3d(0, 0, 1px) rotate(360deg);
  }
}

.background {
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background: ${props => darken(0.25, props.theme.primary)};
  overflow: hidden;
}

.background span {
  width: 20vmin;
  height: 20vmin;
  border-radius: 20vmin;
  backface-visibility: hidden;
  position: absolute;
  animation: move;
  animation-duration: 45;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}


.background span:nth-child(0) {
  color: ${props => props.theme.secondary};
  top: 34%;
  left: 84%;
  animation-duration: 43s;
  animation-delay: -5s;
  transform-origin: 16vw 20vh;
  box-shadow: -40vmin 0 5.297929151246508vmin currentColor;
}
.background span:nth-child(1) {
  color: ${props => props.theme.primary};
  top: 57%;
  left: 99%;
  animation-duration: 52s;
  animation-delay: -33s;
  transform-origin: -21vw -1vh;
  box-shadow: 40vmin 0 5.612977135115274vmin currentColor;
}
.background span:nth-child(2) {
  color: ${props => props.theme.primary};
  top: 44%;
  left: 18%;
  animation-duration: 46s;
  animation-delay: -8s;
  transform-origin: 12vw 7vh;
  box-shadow: -40vmin 0 5.665590070328243vmin currentColor;
}
.background span:nth-child(3) {
  color: ${props => props.theme.secondary};
  top: 40%;
  left: 27%;
  animation-duration: 43s;
  animation-delay: -31s;
  transform-origin: -5vw 17vh;
  box-shadow: 40vmin 0 5.234845143897505vmin currentColor;
}
.background span:nth-child(4) {
  color: ${props => props.theme.secondary};
  top: 18%;
  left: 47%;
  animation-duration: 44s;
  animation-delay: -7s;
  transform-origin: 19vw -24vh;
  box-shadow: -40vmin 0 5.108605551830535vmin currentColor;
}
.background span:nth-child(5) {
  color: ${props => props.theme.secondary};
  top: 6%;
  left: 46%;
  animation-duration: 38s;
  animation-delay: -47s;
  transform-origin: 11vw 19vh;
  box-shadow: 40vmin 0 5.714221826647892vmin currentColor;
}
.background span:nth-child(6) {
  color: ${props => props.theme.secondary};
  top: 44%;
  left: 86%;
  animation-duration: 47s;
  animation-delay: -27s;
  transform-origin: 14vw 0vh;
  box-shadow: -40vmin 0 5.204413098931301vmin currentColor;
}
.background span:nth-child(7) {
  color: ${props => props.theme.secondary};
  top: 13%;
  left: 52%;
  animation-duration: 41s;
  animation-delay: -17s;
  transform-origin: -2vw 3vh;
  box-shadow: 40vmin 0 5.78940708753923vmin currentColor;
}
.background span:nth-child(8) {
  color: ${props => props.theme.primary};
  top: 28%;
  left: 42%;
  animation-duration: 41s;
  animation-delay: -11s;
  transform-origin: -2vw -18vh;
  box-shadow: -40vmin 0 5.9275994457583945vmin currentColor;
}
.background span:nth-child(9) {
  color: ${props => props.theme.primary};
  top: 97%;
  left: 24%;
  animation-duration: 37s;
  animation-delay: -48s;
  transform-origin: 14vw 9vh;
  box-shadow: -40vmin 0 5.419823193666521vmin currentColor;
}
.background span:nth-child(10) {
  color: ${props => props.theme.secondary};
  top: 35%;
  left: 15%;
  animation-duration: 23s;
  animation-delay: -35s;
  transform-origin: 24vw -9vh;
  box-shadow: -40vmin 0 5.091434241645404vmin currentColor;
}
.background span:nth-child(11) {
  color: ${props => props.theme.primary};
  top: 36%;
  left: 2%;
  animation-duration: 7s;
  animation-delay: -16s;
  transform-origin: -8vw -15vh;
  box-shadow: -40vmin 0 5.702248729580316vmin currentColor;
}
.background span:nth-child(12) {
  color: ${props => props.theme.secondary};
  top: 76%;
  left: 24%;
  animation-duration: 50s;
  animation-delay: -19s;
  transform-origin: -17vw 7vh;
  box-shadow: -40vmin 0 5.396170542659995vmin currentColor;
}
.background span:nth-child(13) {
  color: ${props => props.theme.secondary};
  top: 84%;
  left: 62%;
  animation-duration: 25s;
  animation-delay: -1s;
  transform-origin: -14vw 4vh;
  box-shadow: 40vmin 0 5.164073221122981vmin currentColor;
}
.background span:nth-child(14) {
  color: ${props => props.theme.secondary};
  top: 38%;
  left: 42%;
  animation-duration: 47s;
  animation-delay: -23s;
  transform-origin: 11vw 21vh;
  box-shadow: 40vmin 0 5.001405830012282vmin currentColor;
}
.background span:nth-child(15) {
  color: ${props => props.theme.secondary};
  top: 33%;
  left: 97%;
  animation-duration: 50s;
  animation-delay: -37s;
  transform-origin: 6vw 7vh;
  box-shadow: 40vmin 0 5.268020442513072vmin currentColor;
}
.background span:nth-child(16) {
  color: ${props => props.theme.primary};
  top: 59%;
  left: 63%;
  animation-duration: 17s;
  animation-delay: -18s;
  transform-origin: 1vw 12vh;
  box-shadow: -40vmin 0 5.016166856653206vmin currentColor;
}
.background span:nth-child(17) {
  color: ${props => props.theme.secondary};
  top: 95%;
  left: 20%;
  animation-duration: 7s;
  animation-delay: -5s;
  transform-origin: 5vw 7vh;
  box-shadow: 40vmin 0 5.680524309997537vmin currentColor;
}
.background span:nth-child(18) {
  color: ${props => props.theme.secondary};
  top: 62%;
  left: 3%;
  animation-duration: 16s;
  animation-delay: -44s;
  transform-origin: 3vw -5vh;
  box-shadow: 40vmin 0 5.202512226154769vmin currentColor;
}
.background span:nth-child(19) {
  color: ${props => props.theme.secondary};
  top: 82%;
  left: 69%;
  animation-duration: 8s;
  animation-delay: -4s;
  transform-origin: -3vw -6vh;
  box-shadow: 40vmin 0 5.035517313188318vmin currentColor;
}


`

export default Background
 
// import React from "react";

// function Icon() {
//   return (
//     <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1920 1080">
//       <path fill="#3054f2" d="M0 0H1920V1080H0z"></path>
//       <path
//         fill='url("#SvgjsLinearGradient1164")'
//         d="M0-1080c-13.083 94.789-64.202 390.408-78.499 568.734-14.296 178.326-21.99 312.437-7.28 501.222 14.71 188.785 94.317 457.597 95.538 631.49 1.22 173.891-82.875 228.525-88.212 411.862-5.337 183.337 43.114 500.378 56.19 688.16C-9.189 1909.25-3.712 2086.911 0 2160h1920v-3240z"
//         transform="rotate(60 1051.779 461.879) scale(1.15)"
//       ></path>
//       <path
//         fill='url("#SvgjsLinearGradient1165")'
//         d="M320-1080c.394 89.97-13.01 391.434 2.364 539.816 15.373 148.382 92.957 223.27 89.875 350.477C409.157-62.5 303.429 70.742 303.87 223.059c.443 152.317 96.52 318.962 111.024 501.135 14.504 182.173-22.561 432.328-24 591.902-1.44 159.573 27.18 224.889 15.363 365.54C394.443 1822.286 334.376 2080.273 320 2160h1600v-3240z"
//         transform="rotate(60 1051.779 461.879) scale(1.15)"
//       ></path>
//       <path
//         fill='url("#SvgjsLinearGradient1166")'
//         d="M640-1080c18.75 76.237 94.429 317.024 112.499 457.421 18.07 140.398 19.284 242.483-4.079 384.964C725.058-95.133 629.956 89.355 612.323 232.31c-17.633 142.955 7.06 250.377 30.301 387.807 23.24 137.429 95.077 332.56 109.14 436.77 14.064 104.208-.6 86.94-24.759 188.48-24.158 101.54-105.689 268.323-120.19 420.762-14.5 152.439 27.654 411.56 33.185 493.871h1280v-3240z"
//         transform="rotate(60 1051.779 461.879) scale(1.15)"
//       ></path>
//       <path
//         fill='url("#SvgjsLinearGradient1167")'
//         d="M960-1080c-11.895 92.765-55.633 393.462-71.373 556.593-15.74 163.131-22.927 225.984-23.065 422.195-.137 196.21 17.145 565.985 22.24 755.069 5.093 189.083 2.924 215.562 8.324 379.433 5.4 163.87 13.431 416.008 24.077 603.793C930.848 1824.868 953.367 2072.847 960 2160h960v-3240z"
//         transform="rotate(60 1051.779 461.879) scale(1.15)"
//       ></path>
//       <path
//         fill='url("#SvgjsLinearGradient1168")'
//         d="M1280-1080c9.389 126.224 64.166 543.267 56.333 757.343-7.834 214.075-75.825 342.642-103.333 527.108-27.509 184.465-68.729 373.406-61.717 579.684 7.013 206.278 85.67 428.674 103.789 657.984 18.12 229.311 4.107 598.234 4.928 717.881h640v-3240z"
//         transform="rotate(60 1051.779 461.879) scale(1.15)"
//       ></path>
//       <path
//         fill='url("#SvgjsLinearGradient1169")'
//         d="M1600-1080c18.003 96.177 94.374 389.268 108.017 577.06 13.643 187.793-26.45 373.054-26.16 549.697.292 176.643 53.361 341.545 27.906 510.162-25.455 168.617-148.664 307.567-180.637 501.54-31.974 193.972-23.016 478.705-11.204 662.295 11.813 183.59 68.398 366.038 82.078 439.246h320v-3240z"
//         transform="rotate(60 1051.779 461.879) scale(1.15)"
//       ></path>
//       <defs>
//         <linearGradient
//           id="SvgjsLinearGradient1164"
//           x1="0.1"
//           x2="0"
//           y1="0"
//           y2="1"
//         >
//           <stop offset="0" stopColor="#3054f2"></stop>
//           <stop offset="0" stopColor="#3074f2"></stop>
//         </linearGradient>
//         <linearGradient
//           id="SvgjsLinearGradient1165"
//           x1="0.1"
//           x2="0"
//           y1="0"
//           y2="1"
//         >
//           <stop offset="0" stopColor="#133cf0"></stop>
//           <stop offset="0.167" stopColor="#1361f0"></stop>
//         </linearGradient>
//         <linearGradient
//           id="SvgjsLinearGradient1166"
//           x1="0.1"
//           x2="0"
//           y1="0"
//           y2="1"
//         >
//           <stop offset="0" stopColor="#0e33d8"></stop>
//           <stop offset="0.333" stopColor="#0e55d8"></stop>
//         </linearGradient>
//         <linearGradient
//           id="SvgjsLinearGradient1167"
//           x1="0.1"
//           x2="0"
//           y1="0"
//           y2="1"
//         >
//           <stop offset="0" stopColor="#0c2cbb"></stop>
//           <stop offset="0.5" stopColor="#0c49bb"></stop>
//         </linearGradient>
//         <linearGradient
//           id="SvgjsLinearGradient1168"
//           x1="0.1"
//           x2="0"
//           y1="0"
//           y2="1"
//         >
//           <stop offset="0" stopColor="#0a259e"></stop>
//           <stop offset="0.667" stopColor="#0a3e9e"></stop>
//         </linearGradient>
//         <linearGradient
//           id="SvgjsLinearGradient1169"
//           x1="0.1"
//           x2="0"
//           y1="0"
//           y2="1"
//         >
//           <stop offset="0" stopColor="#081f82"></stop>
//           <stop offset="0.833" stopColor="#083382"></stop>
//         </linearGradient>
//       </defs>
//     </svg>
//   );
// }

// export default Icon;

// import React from 'react'
// import styled, { keyframes } from 'styled-components'

// const Background = props => (
//   <BgWrapper>
//     <Svg viewBox="0 24 150 28" {...props}>
//       <defs>
//         <path
//           id="prefix__a"
//           d="M-160 44c30 0 58-18 88-18s58 18 88 18 58-18 88-18 58 18 88 18v44h-352z"
//         />
//       </defs>
//       <g className="wavies">
//         <use xlinkHref="#prefix__a" x={50} y={6} fill="currentColor" />
//       </g>
//       <g className="wavies">
//         <use xlinkHref="#prefix__a" x={50} y={8} fill="currentColor" />
//       </g>
//     </Svg>
//   </BgWrapper>
// )

// const Waves = keyframes`
//   0% { transform: translate(-90px , 0%) }
//   100% { transform: translate(85px , 0%) }
// `
// const Swell = keyframes`
//   0% { transform: translate(-90px , 0%) }
//   50% { transform: translate(0px , 0%) }
//   100% { transform: translate(85px , 0%) }
// `

// const BgWrapper = styled.div`
//   width: 70%;
//   max-height: 100vh;
//   display: flex;
//   justify-content: flex-end;
//   flex-direction: column;
//   @media(max-width: 768px) {
//     display: none;
//   }
// `

// const Svg = styled.svg`
//   height: 40vh;
//   position: absolute;
//   bottom: 0;
//   max-width: 100%;
//   & >.wavies {
//     transform: translate3d(0, 0, 0);
//     animation: ${Waves} 7s cubic-bezier( 0.36, 0.45, 0.63, 0.53) infinite;
//     color: ${props => props.theme.primary};
//     opacity: 0.5;
//   }
//   & >.wavies:nth-of-type(2) {
//     top: -10px;
//     transform: translate3d(0, -25px, 0);
//     animation: ${Swell} 7s cubic-bezier( 0.36, 0.45, 0.63, 0.53) infinite;
//     color: ${props => props.theme.primary};
//     opacity: 1;
//   }
// `

// export default Background