import React from 'react'
import styled from 'styled-components'
import t from '../../../utils/i18n'
import PersonalDataForm from './forms/PersonalDataRequestForm'

const HeadingWrapper = styled.div`
  font-weight: 300;
  color: #424242;
  font-size: 3rem;
  padding: ${props => props.theme.spacing.small} 0;

  @media (max-width: 768px){
    padding-bottom: ${props => props.theme.spacing.small};
    font-size: 2rem;
  }
`

const Signup = ({ handleSignupUser, error, status}) => (  
  <>
    <HeadingWrapper>
      {t('signup.register')}
    </HeadingWrapper>
    <div>
      <PersonalDataForm
        handleSignupUser={handleSignupUser}
        status={status}
        error={error}
      />
    </div>  
  </>
)

export default Signup