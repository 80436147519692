import React from "react";
import styled from 'styled-components';

function Icon({onClick}) {
  return (
    <IconWrapper onClick={onClick}>
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.1223 1.87774C12.1631 1.91838 12.1954 1.96666 12.2174 2.01981C12.2395 2.07296 12.2508 2.12994 12.2508 2.18749C12.2508 2.24504 12.2395 2.30202 12.2174 2.35517C12.1954 2.40832 12.1631 2.4566 12.1223 2.49724L2.49731 12.1222C2.41516 12.2044 2.30374 12.2505 2.18756 12.2505C2.07139 12.2505 1.95997 12.2044 1.87781 12.1222C1.79566 12.0401 1.74951 11.9287 1.74951 11.8125C1.74951 11.6963 1.79566 11.5849 1.87781 11.5027L11.5028 1.87774C11.5435 1.837 11.5917 1.80467 11.6449 1.78262C11.698 1.76056 11.755 1.74921 11.8126 1.74921C11.8701 1.74921 11.9271 1.76056 11.9802 1.78262C12.0334 1.80467 12.0817 1.837 12.1223 1.87774Z"
        fill="#1F2937"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M1.8778 1.87774C1.83706 1.91838 1.80473 1.96666 1.78268 2.01981C1.76062 2.07296 1.74927 2.12994 1.74927 2.18749C1.74927 2.24504 1.76062 2.30202 1.78268 2.35517C1.80473 2.40832 1.83706 2.4566 1.8778 2.49724L11.5028 12.1222C11.585 12.2044 11.6964 12.2505 11.8125 12.2505C11.9287 12.2505 12.0401 12.2044 12.1223 12.1222C12.2045 12.0401 12.2506 11.9287 12.2506 11.8125C12.2506 11.6963 12.2045 11.5849 12.1223 11.5027L2.4973 1.87774C2.45666 1.837 2.40838 1.80467 2.35523 1.78262C2.30208 1.76056 2.2451 1.74921 2.18755 1.74921C2.13 1.74921 2.07302 1.76056 2.01987 1.78262C1.96672 1.80467 1.91844 1.837 1.8778 1.87774Z"
        fill="#1F2937"
      />
    </svg>
    </IconWrapper>
  );
}

const IconWrapper = styled.div`
display: flex;
justify-content: end;
padding: 5px;
`

export default Icon;
