import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="47"
      height="34"
      viewBox="0 0 47 34"
    >
      <defs>
        <path id="b" d="M270 303L310 303 310 329z"></path>
        <filter
          id="a"
          width="137.5%"
          height="157.7%"
          x="-18.8%"
          y="-17.3%"
          filterUnits="objectBoundingBox"
        >
          <feOffset
            dy="3"
            in="SourceAlpha"
            result="shadowOffsetOuter1"
          ></feOffset>
          <feGaussianBlur
            in="shadowOffsetOuter1"
            result="shadowBlurOuter1"
            stdDeviation="2"
          ></feGaussianBlur>
          <feColorMatrix
            in="shadowBlurOuter1"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.122049825 0"
          ></feColorMatrix>
        </filter>
      </defs>
      <g
        fill="none"
        fillRule="evenodd"
        stroke="none"
        strokeWidth="1"
        transform="translate(-267 -302)"
      >
        <use fill="#000" filter="url(#a)" xlinkHref="#b"></use>
        <use fill="#FFF" xlinkHref="#b"></use>
      </g>
    </svg>
  );
}

export default Icon;
