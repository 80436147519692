import React, {useState} from 'react'
import styled from 'styled-components'
import MiniStars from '../../../../components/GlobalIcons/MiniStars'
import Arrow from '../../../../components/GlobalIcons/Arrow'
import { useQuery } from '@apollo/react-hooks'
import { GET_SUGGESTIONS } from '../../../../queries/suggestion'
import { withTranslation } from 'react-i18next'
import Title from '../../../../components/Title'
import themes from '../../../../utils/themes'
import Modal from '../../../../components/Modal'


const parseSuggestions = (data) => {
  let rawSuggestions = data.user.progress.filter((obj) => { return !!(obj.suggestion) }).sort().reverse()
  return rawSuggestions.filter((obj, key) => key < 5)
}

const parsedTitle = (title) => {
  return title.length > 40 ? title.substring(0, 40) + '...' : title
}

const LatestRecommendations = ({history, t}) => { 
  const [selectedSuggestion, setSelectedSuggestion] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { loading, error, data } = useQuery(GET_SUGGESTIONS, {
    fetchPolicy: "no-cache",
    variables: {
      _id: process.env.REACT_APP_ORGANIZATION_ID
    },    
  })

  let suggestions = []
  if (!loading && data) {
    suggestions = parseSuggestions(data)
  }

console.log(suggestions)

  const openModal = (suggestion) => {
    setSelectedSuggestion(suggestion);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setSelectedSuggestion(null);
    setIsModalOpen(false);
  };

  return (
    <Wrapper>
      { !loading && suggestions.length > 0 && (
        <Title fontSize={'medium'}>
          {t('home.latest_recommendations')}          
        </Title>
      )}
      <div>
        { loading && (
          <LoadingSuggestion/>
        )}
        { suggestions.map((obj) => (
          <Suggestion 
            key={obj.suggestion._id}
            // onClick={() => {history.push(`/suggestion/${obj.suggestion._id}`)}}
             onClick={() => openModal(obj.suggestion)}
          >
            <TitleWrapper>
              <MiniStars color={themes.white}/>
              <div>{ parsedTitle(obj.suggestion.title)}</div>
              </TitleWrapper>
            <IconWrapper>
            <Arrow />
            </IconWrapper>
          </Suggestion>

        ))}
      </div>

      {isModalOpen && selectedSuggestion && (
        <Modal suggestion={selectedSuggestion} closeModal={closeModal} />
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  margin: 2rem 0;
  padding: 0 2rem;
  @media(max-width: 600px){
    margin-bottom: 5rem;
  }
`

const TitleWrapper = styled.div`
display: flex;
gap: 1rem

`

const IconWrapper = styled.span`
display: flex;
justify-content: end;
`

const Suggestion = styled.div`
  height: 70px;
  margin: 1rem 0;  
  padding: 0 1rem;
  display: flex;
  justify-content: space-between
  flex: 1;
  align-items: center;
  background: white;
  border-radius: ${(props) => props.theme.border_radius.rounded_lg};
  border: 1px solid ${(props) => props.theme.light_gray };
  cursor: pointer; 

  > svg {
    min-width: 24px;
    margin-right: 1rem;
    fill: ${props => props.theme.primary};
  }

  &:hover {
    box-shadow: 0 3px 9px -5px rgba(0,0,0,0.75);
  }
`

const LoadingSuggestion = styled.div`
  height: 70px;
  margin: 1rem 0;  
  padding: 0 1rem;
  border-radius: 4px;
  flex: 1;
  background: #dedede;
`

export default withTranslation()(LatestRecommendations)