import React from 'react'
import styled from 'styled-components'
import { lighten } from 'polished'
import Title from '../Title'
import themes from '../../utils/themes'
//import themes from '../../../../../utils/themes'

const Card = (props) => {
  const {data, height, color, textColor} = props
  return (
    <CardWrapper height={height} color={color}>
      <NameWrapper>
        <Title color={textColor || 'black'} fontSize={'medium'} padding={'5px'}>{ data.name }</Title>
      </NameWrapper>
    </CardWrapper>
  )
}

const CardWrapper = styled.div`
  background: ${props => props.color.primary});
  text-align: center;
  display: flex;
  justify-content: initial;
  align-items: center;
  transition: transform 0.5s ease;
  width: 100%;
`

const NameWrapper = styled.div`
  padding-top: ${props => props.theme.spacing.xs};
  z-index: 1;
`

export default Card