import React from "react";
import { withTheme } from 'styled-components'

const getColor = (props) => {
  if (props.color === 'primary' || props.color === 'secondary') {
    return props.theme[props.color]
  }
  //Custom colors
  return props.color
}

function Icon(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill={getColor(props)} viewBox="0 0 60 60">
      <path
        d="M52.44 49.89a29.94 29.94 0 00-.58-40.41A29.87 29.87 0 0031 0h-2A29.89 29.89 0 008.16 9.47a29.93 29.93 0 00-.59 40.41l.06.07A30 30 0 0029 60h2a30 30 0 0021.38-10zM2.1 31h12.41A38.41 38.41 0 0017 43.67 38.17 38.17 0 008.62 48 27.75 27.75 0 012.1 31zm7.09-19.6a37.73 37.73 0 008.16 4A38.52 38.52 0 0014.51 29H2.1a27.77 27.77 0 017.09-17.6zM57.9 29H45.49a38.52 38.52 0 00-2.84-13.55 37.73 37.73 0 008.16-4A27.77 27.77 0 0157.9 29zM29 15.48a36.34 36.34 0 01-8.77-1.33A36.16 36.16 0 0129 2.74zm0 2.07V29H16.58a36.17 36.17 0 012.76-12.91A38.38 38.38 0 0029 17.55zm2.06 0a38.38 38.38 0 009.63-1.49A36.17 36.17 0 0143.42 29H31zm0-2.07V2.74a36.16 36.16 0 018.74 11.41 36.34 36.34 0 01-8.8 1.33zm10.78-2a38.19 38.19 0 00-8.17-11.2 27.84 27.84 0 0115.68 7.61 36.09 36.09 0 01-7.54 3.63zm-23.62 0a36.09 36.09 0 01-7.54-3.63 27.84 27.84 0 0115.68-7.53 38.19 38.19 0 00-8.17 11.2zM16.58 31H29v10.42A38.05 38.05 0 0019 43a36.34 36.34 0 01-2.39-12zM29 43.49v13.77a36.23 36.23 0 01-9.2-12.32 35.92 35.92 0 019.2-1.45zm2 13.77V43.49a35.92 35.92 0 019.2 1.45A36.23 36.23 0 0131 57.26zm0-15.84V31h12.42A36.34 36.34 0 0141 43a38.05 38.05 0 00-10-1.59zM45.49 31H57.9a27.75 27.75 0 01-6.52 17A38.17 38.17 0 0043 43.67 38.41 38.41 0 0045.49 31zM10 49.49a36.29 36.29 0 017.77-3.91 38 38 0 008.56 12.1A27.8 27.8 0 0110 49.49zm23.61 8.19a37.85 37.85 0 008.56-12.1A36.29 36.29 0 0150 49.49a27.8 27.8 0 01-16.33 8.19z"
        data-name="Capa 2"
      ></path>
    </svg>
  );
}

Icon.defaultProps = {
  color: "primary"
}

export default withTheme(Icon);
