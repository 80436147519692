import React from "react";
import styled from "styled-components";
import { GET_SUGGESTION } from "../../queries/suggestion";
import { useQuery } from "@apollo/react-hooks";
import SuggestionCard from "../../components/SuggestionCard";
import Text from "../../components/Text";
import t from "../../utils/i18n";
import BgWrapper from "../../components/SuggestionCard/BgWrapper";
import ChevronRight from "../../components/GlobalIcons/ChevronRight";

const SuggestedMaterial = ({ suggestion, handleModalClose }) => {

  const buttonText = t("question.next");

  const { loading, error, data } = useQuery(GET_SUGGESTION, {
    variables: {
      _id: suggestion,
    },
  });

  if (loading) {
    return <div></div>;
  }
  return (
    <SuggestionCard suggestion={data.suggestion}>
      <StyledButton
        onClick={() =>
          handleModalClose({ id: "", name: "suggestion", open: false })
        }
      >
        <Text fontSize={"medium"} color={"white"}>
          {buttonText}
        </Text>
        <ChevronRight />
      </StyledButton>
    </SuggestionCard>
  );
};

const StyledButton = styled.button`
display: flex;
align-items: center;
gap: 15px;
border: none;
box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1),
  0px -6px 6px -2px rgba(0, 0, 0, 0.05);
background: ${(props) => props.theme.primary};
cursor: pointer;
color: white;
padding: 0.6rem 0.8rem;
width: fit-content;
border-radius: ${(props) => props.theme.border_radius.rounded_sm};
:disabled {
  opacity: 0.5;
  pointer-events: none;
}
`;
const Icon = styled.span`
  padding: 7px 30px 0 0;
`;

export default SuggestedMaterial;
