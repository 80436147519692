import React from 'react'
import { withRouter } from 'react-router'
import styled from 'styled-components'
import { GET_SUGGESTION } from '../../queries/suggestion'
import { useQuery } from '@apollo/react-hooks'
import SuggestionCard from '../../components/SuggestionCard'

const Wrapper = styled.div`
  position: relative;
  height: 100%;
  padding: 8rem
`

const Suggestion = (props) => {
  const { loading, error, data } = useQuery(GET_SUGGESTION, {
    variables: {
      _id: props.match.params.id
    }
  })

  if(loading){
    return null
  }
  return (
    <Wrapper>
      <SuggestionCard suggestion={data.suggestion} />
    </Wrapper>
  )
}


export default withRouter(Suggestion)