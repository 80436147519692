import { gql } from "apollo-boost";

export const GAME_OPTIONS = gql`
  query game($_id: String) {
    game(_id: $_id) {
      _id
      name
    }
  }
`;

export const GET_GAME = gql`
  query game($_id: String) {
    game(_id: $_id) {
      _id
      isShow
      description
      title
      axes {
        _id
        name
        pallete {
          primary
        }
        achievements {
          _id
          question {
            _id
          }
        }
        questions {
          content
          _id
        }
      }
      currentUser {
        progress {
          achievement {
            axis {
              _id
            }
          }
        }
      }
    }
  }
`;
