import React from 'react'
import styled from 'styled-components'
import Button from '../../../components/Button'
import ErrorMessage from '../../../components/ErrorMessage'
import LockIcon from '../../../components/GlobalIcons/Lock'
import TextInput from '../../../components/TextInput'


const ForgotPassword = ({ 
  handleChange, 
  submit, 
  submitting, 
  password, 
  passwordConfirmation, 
  error, 
  checkingHash,
  success,
  t}) => (
  <Form onSubmit={submit} show={!checkingHash}>
    { success ? (
      <React.Fragment>
        <Title>
          {t('auth.reset.success')}
        </Title>
        <ButtonWrapper>
          <Button onClick={ () => window.location = '/'}>
            {t('auth.reset.success_button')}
          </Button>
        </ButtonWrapper>
      </React.Fragment>
    ) : (
    <React.Fragment>
      <FormTitle>{t('auth.reset.title')}</FormTitle>       
      <TextInput
        type="password"
        name="password"
        label={t("auth.reset.labels.password")}
        values={password}
        onChange={handleChange}
        icon={(<LockIcon color="secondary" />)}
      />
      <TextInput
        type="password"
        name="passwordConfirmation"
        label={t("auth.reset.labels.confirm_password")}
        values={passwordConfirmation}
        onChange={handleChange}
        icon={(<LockIcon color="secondary" />)}
      />

      { error && (
        <ErrorMessage message={error} />
      )}
              
      <ButtonWrapper>
        <Button
          disabled={submitting}
          loading={submitting}
        >
          {t('auth.forgot.button')}
        </Button>
      </ButtonWrapper>
    </React.Fragment>
    )}     
  </Form>
)



const Title = styled.h1`
  color: ${props => props.theme.secondary};
  font-size: 1.2rem;
  font-weight: 400;
  margin: 0;
  padding: 1rem 0;
  text-align: center;
`

const Subtitle = styled.h2`
  color: #616161;
  font-size: 1.25rem;
  margin: 0.5rem 0 0 0.5rem;
  opacity: 0.7;
`

const FormTitle = styled.h2`
  color: ${props => props.theme.secondary};
  font-size: 1.2rem;
  font-weight: 400;
  margin: 0;
  padding: 1rem 0;
  text-align: left;
`


const Form = styled.form`
  width: 100%;
  margin: 1rem auto 0;
`

const ButtonWrapper = styled.div`
  padding: 1rem 0;
`

export default ForgotPassword