import React from 'react'
import styled from 'styled-components'
import { withTranslation } from 'react-i18next'
import { calculatePosition } from './helper'
import Star from './assets/Star'
import StretchedCard from '../../../../components/StretchedCard'
import Title from '../../../../components/Title'
import Text from '../../../../components/Text'
import themes from '../../../../utils/themes'

const RankingPosition = ({data, loading, t}) => {
  if(loading) return false
  
  const userData = calculatePosition(data)
  if(!userData.achievements) return false
  return (
    <StretchedCard icon="🎊">
      <Title fontSize="large" padding={'0.8rem 2rem 0 0'} fontWeight={'500'}>
      {t('home.personal_ranking.total')} 
      <AchievementText>
      <span>{t('home.personal_ranking.span', {achievements: userData.achievements})} {
         userData.achievements && userData.achievements > 1 ? t('home.personal_ranking.achs') : t('home.personal_ranking.ach')}
        </span>
        </AchievementText>
      </Title>
      <Text fontSize="medium" color={'black'}>
      {t('home.personal_ranking.position', {position: userData.position})}
      </Text>
      </StretchedCard>
  )
}

const AchievementText = styled.span`
color: ${props => props.theme.primary};
font-weight: 700;
`

export default withTranslation()(RankingPosition)