import React from "react";
import { withTheme } from 'styled-components'

const getColor = (props) => {
  if (props.color === 'primary' || props.color === 'secondary') {
    return props.theme[props.color]
  }
  //Custom colors
  return props.color
}

const Icon = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill={getColor(props)} viewBox="0 0 62.74 59.88">
      <path
        d="M50.93 23.88l10.6-6.06L31.37.69 1.21 17.82l10.6 6.06-10.6 6L11.81 36l-10.6 6 30.16 17.19L61.53 42l-10.6-6 10.6-6.06zM31.37 3.22l25.72 14.6-25.72 14.7-25.72-14.7zM14 25.14l17.34 9.91 17.35-9.91 8.37 4.76-25.69 14.69L5.65 29.9zM57.09 42L31.37 56.67 5.65 42 14 37.21l17.34 9.91 17.35-9.91z"
        className="cls-1"
        data-name="Capa 2"
      ></path>
    </svg>
  );
}

Icon.defaultProps = {
  color: "primary"
}

export default withTheme(Icon);
