import React, { Component } from 'react'
import axios from 'axios'
import styled from 'styled-components'
import i18next from 'i18next'
import RupuLogo from '../RupuLogo'

const SplashScreen = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fafafa;
  padding-bottom: 200px
`

const TitleWrapper = styled.div`
  color: #bdbdbd;
  display: flex;
  justify-content: center;
  align-items: center;

  > p {
    font-size: 5rem;
  }
`

const Logo = styled(RupuLogo)`
  color: #bdbdbd;
`

class FetchGameWrapper extends Component { 

  constructor(props) {
    super(props)
    this.state = {
      loading: true
    }
  }
  
  componentDidMount(){
    this.fetchGame()
  }

  checkLang = (newLang) => { 
    if (newLang !== i18next.language) {
      i18next.changeLanguage(newLang)
    }
  }

  fetchGame = async() => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_GAME_URL}/api/game?id=${process.env.REACT_APP_ORGANIZATION_ID}`)
      this.setState({
        loading: false,
      }, () => {
        localStorage.setItem('logo', res.data.logo)
        this.checkLang(res.data.lang)
        this.props.onGameChange(res.data)
      })
    } catch (err) {
      this.setState({loading: false})
    }
  }  

  render(){
    return (
      <React.Fragment>
        { this.state.loading ? (
          <SplashScreen>
            <TitleWrapper>
              <Logo />
            </TitleWrapper>          
          </SplashScreen>
        ) : this.props.children }
      </React.Fragment>
    )
  }
}

export default FetchGameWrapper