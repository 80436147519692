import React from "react";
import { withTheme } from 'styled-components'

const getColor = (props) => {
  if (props.color === 'primary' || props.color === 'secondary') {
    return props.theme[props.color]
  }
  //Custom color
  return props.color
}

const Icon = (props) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' fill={getColor(props)} viewBox='0 0 63.24 46.61'>
      <g data-name='Capa 2'>
        <path
          d='M19.1 25.84L7.44 37.57a1.18 1.18 0 101.67 1.66l11.66-11.72a1.18 1.18 0 10-1.67-1.67zM2.34 4.18l25.12 25a5.91 5.91 0 008.35 0L60.78 4a1.18 1.18 0 000-1.67A5.89 5.89 0 0056.57.62L6.54.76a5.88 5.88 0 00-4.12 1.68l-.09.07a1.18 1.18 0 000 1.67zm4.21-1.06l50-.14a3.64 3.64 0 011.57.35l-24 24.14a3.55 3.55 0 01-5 0L5 3.48a3.51 3.51 0 011.57-.36z'
          className='cls-1'
        ></path>
        <path
          d='M62.62 39.9l-.09-31.43a1.18 1.18 0 00-1.18-1.18 1.2 1.2 0 00-1.18 1.19l.09 31.42a3.6 3.6 0 01-3.58 3.6l-50 .13a3.58 3.58 0 01-3.59-3.57L3 8.62a1.18 1.18 0 00-2.36 0l.07 31.44a5.94 5.94 0 006 5.93l50-.13a6 6 0 005.93-6z'
          className='cls-1'
        ></path>
        <path
          d='M54.2 39.11a1.18 1.18 0 001.67-1.68L44.14 25.78a1.17 1.17 0 00-1.66 0 1.18 1.18 0 000 1.67z'
          className='cls-1'
        ></path>
      </g>
    </svg>
  );
}

Icon.defaultProps = {
  color: "primary"
}

export default withTheme(Icon);
