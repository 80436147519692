import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Wrapper from "../AnimatedWrapper/";
import Confetti from "./assets/Confetti";
import DiamondIcon from "../GlobalIcons/Diamond";
import rightarrow from "./assets/rightarrow.svg";
import PropTypes from "prop-types";
import Title from "../Title";
import Text from "../Text";
import Winner from "../GlobalIcons/Winner";
import themes from "../../utils/themes";

const Achievement = ({
  handleModalClose,
  achievement: { title, description },
}) => {
  const [confetti, showConfetti] = useState(false);
  useEffect(() => {
    const timer = setTimeout(() => {
      showConfetti(true);
    }, 800);
    return () => clearTimeout(timer);
  }, []);

  return (
    <ModalOverlay>
      <ModalWrapper>
        <ModalOpen>
          <Confetti />
          {/* <div> */}
          <ContentWrapper>
            <div>
              <IconWrapper>
                <Winner color={themes.default} />
              </IconWrapper>
              <TitleWrapper>
                <Title color={"white"}>¡Felicitaciones!</Title>
              </TitleWrapper>
              <TitleWrapper>
                <Title color={"white"}>{title}</Title>
              </TitleWrapper>
              <Text
                fontSize={"medium"}
                color={"white"}
                textAlign={"center"}
                padding={"20px"}
              >
                {description}
              </Text>
            </div>

            <Button
              onClick={() =>
                handleModalClose({ name: "achievement", id: "", open: false })
              }
            >
              <Text  color={themes.default} fontSize={"medium"} padding={"14px 0 0 0"}>
                Continuar
              </Text>
              <ButtonIcon></ButtonIcon>
            </Button>
          </ContentWrapper>
          {/* </div> */}
        </ModalOpen>
      </ModalWrapper>
    </ModalOverlay>
  );
};

Achievement.propTypes = {
  onNext: PropTypes.func,
  achievementName: PropTypes.string,
};

const ModalOverlay = styled.body`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
`;

const ModalWrapper = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: ${(props) => props.theme.primary};
  padding: 20px;
  text-align: center;
  border-radius: ${(props) => props.theme.border_radius.rounded_lg};
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  z-index: 1;
  width: 500px;
  @media (max-width: 600px) {
    width: 280px;
  }

`;

const ModalOpen = styled.div`
  overflow: hidden;
  position: relative;
`;
// const AbsoluteWrapper = styled.div`
//   background: ${props => props.theme.primary};
//   position: absolute;
//   height: 100%;
//   top: 0;
//   left: 0;
//   right: 0;
//   z-index: 10;
// `

const IconWrapper = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  margin: 1rem 0;
  z-index: 10;

  > div {
    padding: 1rem;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;

  
  }
`;

const TitleWrapper = styled.div`
  padding: 1rem 0;
`;

const ContentWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;

  > div {
    z-index: 10;
  }
`;

const Button = styled.div`
  margin: 1rem 0;
  cursor: pointer;
  background: ${(props) => props.theme.white};
  padding: 0 1rem;
  border-radius: ${(props) => props.theme.border_radius.rounded_sm};
  z-index: 10;
  color: ${(props) => props.theme.primary};
`;

const ButtonIcon = styled.div`
  background: url("${rightarrow}") no-repeat 50%;
  background-size: cover;
  height: 1rem;
  width: 1.5rem;
`;

export default Achievement;
