import { call, put, takeEvery } from "redux-saga/effects";

import { get } from "../../utils/api";
import * as actions from "./actions";

export function* fetchUserDataWorker(action) {
  yield put(actions.fetchUserData.start());

  const userId = localStorage.getItem("user_id");
  const { router } = action.payload;

  const { res, error } = yield call(get, `/users/${userId}`);

  if (error) {
    if (error.status == 401) {
      localStorage.clear();
      window.location = "/login";
    }
    return yield put(actions.fetchUserData.failure({ error }));
  }

  if (
    !res.user.organization_id &&
    !res.role === "alone" &&
    res.user.role !== "admin"
  ) {
    router.push(`/onboarding/list/${res.user.id}`);
  }

  return yield put(actions.fetchUserData.success({ user: res }));
}

export function* fetchUserDataWatcher() {
  yield takeEvery(actions.fetchUserData.type, fetchUserDataWorker);
}
export default [fetchUserDataWatcher];
