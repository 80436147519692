import React from 'react'
import styled, {keyframes} from 'styled-components'
import Radio from '../Radio'

const Answer = ({answer, onCheckedAnswer, selectedAnswer}) => {
  if(!answer.content){
    return null
  }
  return (
    <>
    <Wrapper>
    <Radio
      value={answer._id}
      checked={answer._id === selectedAnswer}
      label={answer.content}
      onChange={onCheckedAnswer}
    />
    </Wrapper>
    </>
  )
}

const Wrapper = styled.div`
  width: fit-content;
  margin-left: 20px;
  min-width: 200px;
  background: white;
  border-radius: ${(props) => props.theme.border_radius.rounded_sm};
  border: 1px solid ${(props) => props.theme.light_gray };
`

export default Answer