import React, { useContext, useEffect } from 'react'
import { useQuery, useMutation } from '@apollo/react-hooks'
import { GET_AXIS } from '../../queries/axis'
import { GET_SUGGESTION } from '../../queries/suggestion';
import { UPDATE_USER_PROGRESS } from '../../queries/user'
import { 
        normalizeAchievements, 
        filterAchievementsByAxis, 
        findLeftAchievements,
        filterDuplicates
      } from '../../utils/helpers'
import AxisSkeletonGrid from '../../components/AxisSkeletonGrid';
import Question from '../Question'
import { CurrentAxisContext } from '../../components/Context'

const Axis = props => {
  const { match, history, suggestion } = props
  const axisId = match.params.axisId

  const { loading, error, data } = useQuery(GET_AXIS, {
    errorPolicy:'all',
      variables: { 
        _id: axisId
      }
  })


  const [updateUserProgress, mutationResponse] = useMutation(UPDATE_USER_PROGRESS, {
    refetchQueries: ['axis'],
  })

  //Get axis color context
  const contextValue = useContext(CurrentAxisContext)

  //update context if it's not there
  useEffect(() => {
    if (!contextValue.currentAxisColor && data && data.axis) {
      contextValue.toggleAxisColor(data.axis.pallete.primary)
    }
  }, [loading])


  if (loading) {
    return <AxisSkeletonGrid />
  }

  const { lastQuestion } = data.axis
  const { progress } = data.user
  const achievements = normalizeAchievements(progress)
  const achievementsByAxis = filterAchievementsByAxis(achievements, axisId)
  const leftAch = findLeftAchievements(data.axis.achievements, achievementsByAxis)
  console.log(data)
  return (
    <Question 
      data={data.axis.questions}
      name={data.axis.name}
      leftAch={leftAch}
      totalAchievements={filterDuplicates(data.axis.achievements).length}
      userAchievements={achievementsByAxis.length}
      history={history} 
      lastQuestion={lastQuestion}
      updateUserProgress={(data) => {
        updateUserProgress({
          variables: {
            axis: axisId,
            answer: data.answer
          }
        })
      }} 
      />
  )
}

export default Axis
