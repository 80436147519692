import React from 'react'
import styled from "styled-components"

const PageTitle = ({children, margin, padding}) => {
  return (<StyledPageTitle margin={margin} padding={padding}>
    {children}
  </StyledPageTitle>)
}

const StyledPageTitle = styled.div`
// padding: 0 2rem;
padding: ${props => props.padding || '0 2rem'}
margin: ${props => props.margin || '2rem 0'}
  display: flex;
  align-items: center;

  > h1 {
    margin-left: 1rem;
  }`


export default PageTitle