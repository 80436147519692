import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { withTranslation } from "react-i18next";
import devices from "../../utils/devices";
import AboutIcon from "../../components/GlobalIcons/About";
import Joystick from "../../components/GlobalIcons/Joystick";
import Play from "../../components/GlobalIcons/Play";
import MiniStars from "../../components/GlobalIcons/MiniStars";
import HomeIcon from "../../components/GlobalIcons/HomeIcon";
import Text from "../Text";
import LogOut from "../../components/GlobalIcons/LogOut";
import GameLogo from "../GameLogo";
import { GET_GAME } from "../../queries/GameOptions";
import { useQuery } from "@apollo/react-hooks";
import { checkAndRedirectToAxis } from "./checkAndRedirectToAxis";
import { parseProgressResponse } from "../../utils/helpers";


const BottomBar = ({ history, t, user, handleLogout }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [progress, setProgress] = useState({});

  const { loading, error, data, refetch } = useQuery(GET_GAME, {
    fetchPolicy: "cache-and-network",
    variables: {
      _id: process.env.REACT_APP_ORGANIZATION_ID,
    },
    onCompleted: (res) => {
      parseProgressResponse(res, setProgress);
    },
  });
  useEffect(() => {
    if (!loading) {
      refetch();
    }
  }, []);
  

  return (
    <React.Fragment>        
        <HamburgerButton onClick={() => setIsMenuOpen(!isMenuOpen)}>
        <GameLogo width={'140px'} paddingLeft={'40px'}/>
        <div className={`menu-icon${isMenuOpen ? " open" : ""}`}>&#8801;</div>
      </HamburgerButton>
      <Wrapper>
        <FirstTabs>
          <ItemMob
            onClick={() => {
              history.push("/");
            }}
            selected={!!(history.location.pathname === "/")}
          >
            <HomeIcon height={'20px'} width={'20px'}/>
            <span>{t("bottom_bar.home")}</span>
          </ItemMob>
          <ItemMob
            onClick={() => {
              history.push("/axis");
            }}
            selected={!!history.location.pathname.includes("axis")}
          >
            <Joystick height={'20px'} width={'20px'}/>
            <span>{t("bottom_bar.axes")}</span>
          </ItemMob>
          <ItemMob
            onClick={() => {
              history.push("/suggestions");
            }}
            selected={!!history.location.pathname.includes("suggestions")}
          >
            <MiniStars color={"black"} height={'24px'} width={'18px'} padding={'0'}/>
            <span>{t("bottom_bar.suggestions")}</span>
          </ItemMob>
        </FirstTabs>
        <LastTab>
        <PlayContainer>
              <ItemMob
                onClick={() => {
                  checkAndRedirectToAxis(progress, history);
                }}
                selected={!!history.location.pathname.includes('/axis/')}
              >
                <Play color={"black"} />
                <PlayText>{t('bottom_bar.play')}</PlayText>
              </ItemMob>
            </PlayContainer>
        </LastTab>
      </Wrapper>
      {isMenuOpen && (
        <MobileMenu>
          <CloseButton onClick={() => setIsMenuOpen(false)}>
            &times;
          </CloseButton>
          <MobileContainer>
            {user ? (
              <>
                <AvatarWrapper>
                  <HandWrapper>👋</HandWrapper>
                  <div>
                    <Text
                      fontSize={"medium"}
                      fontWeight={"600"}
                      padding={"0 0 0 10px"}
                      color={"black"}
                      textAlign={"left"}
                    >
                      {user.name}
                    </Text>
                    <Text
                      fontSize={"medium"}
                      fontWeight={"500"}
                      padding={"0 0 0 10px"}
                    >
                      {user.organization_name}
                    </Text>
                  </div>
                </AvatarWrapper>
              </>
            ) : (
              <p>loading</p>
            )}
            <Item
              onClick={() => {
                history.push("/");
                setIsMenuOpen(false);
              }}
              selected={!!(history.location.pathname === "/")}
            >
              <HomeIcon />
              <span>{t("bottom_bar.home")}</span>
            </Item>
            <Item
              onClick={() => {
                history.push("/axis");
                setIsMenuOpen(false);
              }}
              selected={!!history.location.pathname.includes("axis")}
            >
              <Joystick />
              <span>{t("bottom_bar.axes")}</span>
            </Item>
            <Item
              onClick={() => {
                history.push("/suggestions");
                setIsMenuOpen(false);
              }}
              selected={!!history.location.pathname.includes("suggestions")}
            >
              <MiniStars color={"black"} />
              <span>{t('navbar.suggestions')}</span>
            </Item>
            <Item
              onClick={() => {
                history.push("/about-us");
                setIsMenuOpen(false);
              }}
              selected={!!history.location.pathname.includes("about-us")}
            >
              <AboutIcon color={"black"} />
              <span>{t("bottom_bar.about_us")}</span>
            </Item>
            <Line/>
            <Item onClick={() => handleLogout()}>
              <LogOut />
              <span>{t("navbar.logout")}</span>
          </Item>
          </MobileContainer>
        </MobileMenu>
      )}
    </React.Fragment>
  );
};

const AvatarWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Line = styled.div`
width: 100%;
background: ${(props) => props.theme.light_gray};
color: ${(props) => props.theme.light_gray};
border: 1px solid ${(props) => props.theme.light_gray};
`;


const PlayContainer = styled.div`
  position: relative;
  text-align: center; // Alinear el texto en el centro
  > span {
    position: absolute;
    top: -5px;
    left: 0;
  }
`;

const HandWrapper = styled.div`
  display: flex;
  align-items: center;
  font-size: 30px;
`;
const PlayText = styled.div`
position: absolute;
top: -5px; 
margin-left: 6px;
left: 0;
background: white;
color: black;
padding: 2px;
font-size: ${(props) => props.fontSize || props.theme.font_size.small}
border-radius: ${(props) => props.theme.border_radius.rounded_md};
border: 1px solid ${(props) => props.theme.light_gray};
`;

const HamburgerButton = styled.div`
  position: fixed;
  display: flex;
  justify-content: space-between;
  align-items:center;
  gap: 10rem;
  top: 0;
  background: ${(props) => props.theme.slate_gray};
  width: 100%;
  .menu-icon {
    font-size: 54px;
    color: #464646;
    transition: transform 0.3s;
  }

  .menu-icon.open {
    display: none;
  }

  @media ${devices.laptop} {
    display: none;
  }
`;

const Wrapper = styled.div`  
  position: absolute;
  padding-top: 3rem;
  bottom: 0;
  width: 100%
  z-index: 1;
  padding: 1rem 0;
  background: ${(props) => props.theme.white};
  border-top-left-radius: ${(props) => props.theme.border_radius.rounded_lg};
  border-top-right-radius: ${(props) => props.theme.border_radius.rounded_lg};
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-between;

  @media ${devices.laptop} {
    display: none;
  };

`;

const MobileContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  width: 160px;
  padding-right: 70px;
`;

const MobileMenu = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: white;
  z-index: 1;
  display: flex;
  flex-direction: column;
  padding-left: 40px;
`;

const CloseButton = styled.div`
  align-self: flex-end;
  position: relative;
  top: 30px;
  cursor: pointer;
  padding: 20px 60px 10px 10px;
  font-size: 24px;
  line-height: 1;
`;
const Item = styled.div`
  display: flex;
  gap: 15px;
  align-items: initial;
  color: #464646;
  cursor: pointer;

  > svg {
    fill: #464646;
  }

  > span {
    font-weight: 500;
  }
  &:hover {
    svg,
    span {
      color: ${(props) => props.theme.primary};
      fill: ${(props) => props.theme.primary};
    }
  }
`;

const ItemMob = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  color: #464646;
  cursor: pointer;

  > svg {
    fill: #464646;
  }

  > span {
    font-weight: 500;
  }
  &:hover {
    svg,
    span {
      color: ${(props) => props.theme.primary};
      fill: ${(props) => props.theme.primary};
    }
  }
`;

const FirstTabs = styled.div`
  display: flex;
  justify-content: space-evenly;
  gap: 2rem;
  padding: 0 1rem;
`;

const LastTab = styled.div`
  display: flex;
  padding-right: 2rem;
`;

export default withTranslation()(BottomBar);
