const globalTheme = {
  pink: '#ef5777',
  white: '#ffffff',
  gray: "#6B7280",
  light_gray: '#E5E7EB',
  slate_gray: "#F9FAFB",
  error: "#E0245E",
  light: '#F3F7FC',
  black: '#1F2937',
  red: '#E42C1E',
  background: "#fafafa",
  copies: "#3E0C68",
  spacing: {
    xs: '0.5rem',
    small: '1rem',
    medium: '1.75rem',
    large: '2.5rem'
  },
  font_size: {
    heading: '3.5rem',
    xxl: '2.5rem',
    xl: '2rem',
    large: '1.5rem',
    medium: '1rem',
    small: '0.75rem',
  },
  border_radius: {
    rounded_sm: '4px',
    rounded: '8px',
    rounded_md: '12px',
    rounded_lg: '16px'
  }
}


const themes = {
  default: {
    primary: '#2266E9',
    ...globalTheme
  },
  yellow: {
    primary: '#EFA51B',
    ...globalTheme
  },
  red: {
    primary: '#E42C1E', 
    ...globalTheme
  },
  purple: {
    primary: '#794BC4',
    ...globalTheme
  },
  orange: {
    primary: '#EE6A0B',
    ...globalTheme
  },
  green: {
    primary: '#3A9E56',
    ...globalTheme
  },
  blue: {
    primary: '#2266E9',
    ...globalTheme
  },
  violet: {
    primary: '#8C41BA',
    ...globalTheme
  },
  fuchsia: {
    primary: '#F20091',
    ...globalTheme
  },
  light_blue: {
    primary: '#498EAB',
    ...globalTheme
  },
}


export default themes
