import React from "react";
import { withTheme } from 'styled-components'

const getColor = (props) => {
  if (props.color === 'primary' || props.color === 'secondary') {
    return props.theme[props.color]
  }
  //Custom colors
  return props.color
}

const Icon = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill={getColor(props)} viewBox="0 0 47.5 61.5">
      <g data-name="Capa 2">
        <path
          d="M23.75.75a23 23 0 00-23 23C.75 36.15 22.1 59.44 23 60.43a1 1 0 001.46 0c.92-1 22.27-24.28 22.27-36.68a23 23 0 00-23-23zm0 57.51C20 54 2.75 34.14 2.75 23.75a21 21 0 0142 0c0 10.39-17.2 30.25-21 34.51z"
          className="cls-1"
        ></path>
        <path
          d="M23.75 14.75a9 9 0 00-9 9.26 9.11 9.11 0 008.75 8.74 9 9 0 10.25-18zm0 16a7 7 0 01-7-7.1 7.09 7.09 0 016.9-6.9 7 7 0 11.1 14z"
          className="cls-1"
        ></path>
      </g>
    </svg>
  );
}

Icon.defaultProps = {
  color: "primary"
}

export default withTheme(Icon);
