import React from "react";
import styled from "styled-components";

function Icon({ color }) {
  return (
    <PlayFilled color={color}>
<svg width="46" height="46" viewBox="0 0 46 46" fill={color || "none"} xmlns="http://www.w3.org/2000/svg">
<rect width="46" height="46" rx="23" color={color || "none"}/>
<path d="M26.1465 23.6099L20.5789 26.8404C20.1064 27.1143 19.5 26.7827 19.5 26.2306V19.7696C19.5 19.2183 20.1055 18.8858 20.5789 19.1606L26.1465 22.3911C26.254 22.4524 26.3433 22.5411 26.4055 22.6481C26.4676 22.7552 26.5003 22.8767 26.5003 23.0005C26.5003 23.1243 26.4676 23.2458 26.4055 23.3529C26.3433 23.4599 26.254 23.5486 26.1465 23.6099Z" fill="white"/>
</svg>


    </PlayFilled>
  );
}

const PlayFilled = styled.span`
  fill: ${(props) => props.color || props.theme.primary || 'black'};
`;

export default Icon;
