import React from 'react'
import styled from 'styled-components'

const ErrorMessage = ({ children }) => (
  <Wrapper>
    <ErrorLabel>{ children }</ErrorLabel>
  </Wrapper>
)

const Wrapper = styled.div`
  margin-bottom: 0.5rem;
`

const ErrorLabel = styled.span`
  color: #F44336;
  font-family: sans-serif;
  margin-bottom: 1rem;
`

export default ErrorMessage