import React from 'react'
import styled from "styled-components"

const Text = ({color, fontSize, padding, fontWeight, textAlign, children}) => {
  return (<StyledText color={color} fontSize={fontSize} padding={padding} fontWeight={fontWeight} textAlign={textAlign}>
    {children}
  </StyledText>)
}

const StyledText = styled.p`
  font-size: ${props => props.fontSize || props.theme.font_size.small}
  padding: ${props => props.padding || '0.4rem 0'}
  margin: 0;
  text-align: ${props => props.textAlign || 'left'};
  color: ${props => props.color || 'gray'}
  font-weight: ${props => props.fontWeight || '400'}
`

export default Text