import { call, put, takeEvery } from 'redux-saga/effects'
import { updateUser } from '../../utils/gameService'
import { get, post, patch } from '../../utils/api'
import * as actions from './actions'



export function * userOrganizationHookWorker(action){
  yield put(actions.userOrganizationHook.start())
  const { organizationId, router } = action.payload

  yield call(updateUser, {organization_id: organizationId})


  router.push('/')
  return yield put(actions.userOrganizationHook.success())

}

export function * getOrganizationsWorker() {
  yield put(actions.getOrganizations.start())

  const {res, error} = yield call(get, 'organizations')

  if(error) {
    return yield put(actions.getOrganizations.failure({error}))
  }

  return yield put(actions.getOrganizations.success({ data: res.organizations }))
}

export function * createOrganizationWorker(action) {
  yield put(actions.createOrganization.start())

  const { data, router } = action.payload

  const { res, error } = yield call(post, 'organizations',{organization: data.organization, user: data.user} )

  if(error){
    return yield put(actions.createOrganization.failure({ error:error.data.errors }))
  }

  yield call(updateUser, {organization_id: res.organization.id || null})

  router.push('/')
  return yield put(actions.createOrganization.success({ data: res }))

}

export function * updateUserWorker(action){
  yield put(actions.updateUser.start())

  const { userId, role, router } = action.payload
  const { res, error } = yield call(patch, `users/${userId}`, {role})

  if(error){
    return yield put(actions.updateUser.failure({ error }))
  }

  router.push('/')
  return yield put(actions.updateUser.success())
}


export function * userOrganizationHookWatcher(){
  yield takeEvery(actions.userOrganizationHook.type, userOrganizationHookWorker)
}

export function * getOrganizationsWatcher() {
    yield takeEvery(actions.getOrganizations.type, getOrganizationsWorker)
}

export function * createOrganizationWatcher(){
  yield takeEvery(actions.createOrganization.type, createOrganizationWorker)
}

export function * updateUserWatcher(){
  yield takeEvery(actions.updateUser.type, updateUserWorker)
}
export default [
  userOrganizationHookWatcher,
  getOrganizationsWatcher,
  createOrganizationWatcher,
  updateUserWatcher
]