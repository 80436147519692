import React from "react";

function Icon({height, width}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 36 29"
    >
      <g fillRule="evenodd" stroke="none" strokeWidth="1">
        <g fillRule="nonzero" transform="translate(-189 -156)">
          <g transform="translate(189 156)">
            <path d="M35.667 8.354L30.703.92A2.088 2.088 0 0028.96 0H7.057C6.35 0 5.699.343 5.314.918L.334 8.352c-.509.76-.43 1.763.191 2.44l15.922 17.333c.394.429.96.675 1.552.675a2.11 2.11 0 001.552-.675L35.475 10.79c.62-.675.7-1.677.192-2.436zm-7.714-6.626l-4.175 6.26-4.177-6.26h8.352zM22.14 8.715h-8.282l4.141-6.206 4.141 6.206zm-5.743-6.987l-4.166 6.243-4.164-6.243h8.33zM6.42 2.44l4.186 6.275H2.216L6.42 2.44zm-3.826 8.004h8.969l4.742 14.927L2.593 10.444zm15.406 14.392l-4.572-14.392h9.144L18 24.836zm1.695.536l4.742-14.928h8.97L19.694 25.372zm5.722-16.657l4.182-6.27 4.187 6.27h-8.369z"></path>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default Icon;
