import React from 'react'
import Background from '../Background'
import styled from 'styled-components'
import GameLogo from '../GameLogo'
import { darken } from 'polished'

const AuthLayout = ({ children }) => (
  <Wrapper>
    <InnerWrapper>
      <Background />
      <OuterContainer>
        <InnerContainer>
          <AvatarWrapper>
            <GameLogo />
          </AvatarWrapper>
          { children }
        </InnerContainer>
      </OuterContainer>
    </InnerWrapper>
  </Wrapper>
)
// background: ${props => darken(0.15, props.theme.primary)};
const Wrapper = styled.div`  
  position: relative;
  height: 100vh;  
  width: 100vw;  
`

const InnerWrapper = styled.div`
  padding: 1rem;
  overflow: hidden;    
  box-sizing: border-box;
  height: 100%;
  background: white;
  display: flex;  
  
  @media (max-width: 768px){
    padding: 0;    
    position: relative;
  }
`

const OuterContainer = styled.div`
  padding-left: 1rem;
  flex-grow: 1;
`

const InnerContainer = styled.div`
  position: relative;
  height: 100%;
  background-color: white;  
  padding: ${props => props.theme.spacing.medium};
  display: flex;  
  box-sizing: border-box;
  flex-direction: column;
  justify-content: center;
  z-index: 10;
  flex: 1;
  flex-grow: 1;  
`

const AvatarWrapper = styled.div`
  padding: ${props => props.theme.spacing.small} 0.5rem;
`

export default AuthLayout
