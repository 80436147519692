import React, {useState} from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { useQuery } from "@apollo/react-hooks";
import { withTranslation } from "react-i18next";
import { GET_AXIS_SUGGESTIONS } from "../../queries/suggestion";
import { normalizeSuggestions } from "../../utils/helpers";
import SuggestionSkeleton from "../../components/SuggestionSkeleton";
import Card from "../../components/AxisCard/Card";
import PageTitle from "../../components/PageTitle";
import MiniStars from "../../components/GlobalIcons/MiniStars";
import Title from "../../components/Title";
import Badge from "../../components/Badge/Badge";

const Suggestions = ({ t }) => {
  const { loading, error, data } = useQuery(GET_AXIS_SUGGESTIONS);
  if (loading) return <SuggestionSkeleton />;
  const { progress } = data.user;
  const suggestions = normalizeSuggestions(progress);

  const uniqueAxes = Array.from(new Set(suggestions.map((s) => s.axis)));
  const startAxis = t("start-choosing")
  return (
    <Wrapper>
      <PageTitle>
      <MiniStars color={'black'}/>
        <Title padding={'0'}>{t('suggestions.title')}</Title>
      </PageTitle>

     { uniqueAxes.length === 0 ? (<Title fontSize={'medium'} padding={'0rem 0 4rem 2rem'}>{startAxis}</Title>) : 
     <Title fontSize={'medium'} padding={'0rem 0 2rem 2rem'}>{t('choose')}</Title>
      }
      <Content>
        { uniqueAxes.map((s, index) => (
           <StyledLink to={`/suggestions/${s._id}`}
          >
          <Badge>
              <Card index={index} data={s} height="80" color={'s.pallete'} textColor="white" />
          </Badge>
          </StyledLink>
        ))}
      </Content>
    </Wrapper>
  );
};

const Wrapper = styled.div`

`;
const Content = styled.div`
  display: flex;
  align-items:center;
  width: fit-content;
  justify-content: initial; 
  margin: 0 2rem;
  padding-bottom: 1rem
  gap: 0.6rem;
  flex-wrap: wrap;
`;


const CardWrapper = styled.div`
  margin: 1rem 0;
`;
const StyledLink = styled(Link)`
  text-decoration: none;
  color: ${(props) => props.theme.gray};
`;
const Subtitle = styled.p`
  font-size: 1rem;
  color: ${(props) => props.theme.primary};
`;

export default withTranslation()(Suggestions);
