import { gql } from 'apollo-boost'


export const UPDATE_USER_PROGRESS = gql`
  mutation updateUserProgress($answer: ID!, $axis: ID!){
    updateUserProgress(answer: $answer, axis: $axis)
  }
`

export const REMOVE_USER_PROGRESS = gql`
  mutation removeUserProgress{
    removeUserProgress
  }
`

export const GET_PROGRESS = gql`
  query game($_id: String) {
    user {
      global_id
      progress {
        question {
          _id
          content
          axis {
            _id
            name
            lastQuestion
            pallete {
              primary
            }
          }
        }
        achievement {
          _id
          axis { 
            _id
            lastQuestionObject {
              content
            }
          }
        }
      }
    },
    usersRanking(game: $_id) {
      global_id
      achievementsCount
    }
    game(_id: $_id) {
      axes {
        _id
        name
        lastQuestion
        pallete {
          primary
        }
      }
    }
  }
`