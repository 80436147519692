import React from "react";

function Icon() {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M2.5 16.875V8.75H3.75V16.875C3.75 17.0408 3.81585 17.1997 3.93306 17.3169C4.05027 17.4342 4.20924 17.5 4.375 17.5H15.625C15.7908 17.5 15.9497 17.4342 16.0669 17.3169C16.1842 17.1997 16.25 17.0408 16.25 16.875V8.75H17.5V16.875C17.5 17.3723 17.3025 17.8492 16.9508 18.2008C16.5992 18.5525 16.1223 18.75 15.625 18.75H4.375C3.87772 18.75 3.40081 18.5525 3.04917 18.2008C2.69754 17.8492 2.5 17.3723 2.5 16.875ZM16.25 3.125V7.5L13.75 5V3.125C13.75 2.95924 13.8158 2.80027 13.9331 2.68306C14.0503 2.56585 14.2092 2.5 14.375 2.5H15.625C15.7908 2.5 15.9497 2.56585 16.0669 2.68306C16.1842 2.80027 16.25 2.95924 16.25 3.125Z" fill="#1F2937"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M9.11625 1.87502C9.35067 1.64068 9.66855 1.50903 10 1.50903C10.3315 1.50903 10.6493 1.64068 10.8838 1.87502L19.1925 10.1825C19.3099 10.2999 19.3758 10.459 19.3758 10.625C19.3758 10.791 19.3099 10.9502 19.1925 11.0675C19.0751 11.1849 18.916 11.2508 18.75 11.2508C18.584 11.2508 18.4249 11.1849 18.3075 11.0675L10 2.75877L1.6925 11.0675C1.57515 11.1849 1.41597 11.2508 1.25 11.2508C1.08403 11.2508 0.924862 11.1849 0.807504 11.0675C0.690145 10.9502 0.624214 10.791 0.624214 10.625C0.624214 10.459 0.690145 10.2999 0.807504 10.1825L9.11625 1.87502Z" fill="#1F2937"/>
    </svg>

  );
}

export default Icon;
