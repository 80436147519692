import React from "react";
import { withTheme } from 'styled-components'

function Icon(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
      <path
        fill={props.theme.primary}
        d="M0 224l34.3 5.3C68.6 235 137 245 206 240c68.3-5 137-27 205-21.3C480 224 549 256 617 256c68.7 0 137-32 206-64 68.4-32 137-64 206-48 68.1 16 137 80 205 101.3 68.9 21.7 137-.3 172-10.6l34-10.7V0H0z"
      ></path>
    </svg>
  );
}

export default withTheme(Icon);
