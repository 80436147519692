import React from 'react'
import styled from 'styled-components'
import ContinueAxis from './components/ContinueAxis'
import NewAxes from './components/NewAxes'
import { withTranslation } from 'react-i18next'
import { CurrentAxisContext } from '../../../../components/Context'

const filterProgress = (data, loading) => {
  if (loading || !data) return false

  let axes = {}  
  data.user.progress.forEach((obj) =>  {
    let axisId = obj.question && obj.question.axis._id
    if (axes[axisId]) {
      axes[axisId] = {
        ...axes[axisId],
        count: (obj.achievement && axes[obj.achievement.axis._id]) ? axes[obj.achievement.axis._id].count + 1 : axes[axisId].count
      }
    } else if (!!(obj.question.axis.lastQuestion)) {      
      axes[axisId] = {
        count: (obj.achievement && obj.achievement.axis.lastQuestionObject) ? 1 : 0,
        question: obj.question.content,
        name: obj.question.axis.name,
        color: obj.question.axis.pallete ? obj.question.axis.pallete.primary : null,
        id: axisId
      }
    }
  })

  
  const newAxes = data.game.axes.filter((obj) => {
    return !(axes[obj._id]) && !!(obj.lastQuestion)
  })

  const axisKeys = Object.keys(axes).sort((a, b) => { return axes[b].count - axes[a].count })
  const continueAxis = axisKeys && axisKeys.length > 0 ? axes[axisKeys[0]] : null
  return { continueAxis, newAxes }
}

const ResumeGame = (props) => {

  const { continueAxis, newAxes } = filterProgress(props.data, props.loading)
  return (
    <Wrapper>
      <CurrentAxisContext.Consumer>
      {({currentAxisColor, toggleAxisColor}) => (
        <>      
          <ContinueAxis
            t={props.t}
            loading={props.loading}
            data={continueAxis}
            history={props.history}
            toggleAxisColor={toggleAxisColor}
          />    
          <NewAxes
            t={props.t}
            loading={props.loading}
            data={newAxes}
            history={props.history}
            toggleAxisColor={toggleAxisColor}
          />
        </>
      )}      
      </CurrentAxisContext.Consumer>
    </Wrapper>
  )
}

const Wrapper = styled.div`
`

export default withTranslation()(ResumeGame)