import React from 'react'
import styled, { keyframes } from 'styled-components'
import PropTypes from 'prop-types'
import t from '../../utils/i18n'
import GoogleIcon from './assets/GoogleIcon'

const Button = (props) => (
  <StyledButton {...props}>
    <GoogleIcon/>
    <p>
      {t('login.google_login')}
    </p>
  </StyledButton>
)

const ripple = keyframes`
  from {
    opacity: 1;
    transform: scale(0);
  }

  to {
    opacity: 0;
    transform: scale(10)
  }
`
const StyledButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;  
  background-color: white;  
  border-radius: 25px;
  color: ${props => props.color};
  cursor: pointer;
  font-weight: bold;
  width: 100%;
  padding: 0.90rem 1rem;
  outline: none;
  text-align: center;
  border: none;
  font-size: 1rem;
  -webkit-box-shadow: 1px 1px 21px 6px rgba(0,0,0,0.16);-moz-box-shadow: 1px 1px 21px 6px rgba(0,0,0,0.16);box-shadow: 1px 1px 21px 6px rgba(0,0,0,0.16);

  > p {
    padding-left: 1rem;
    margin: 0;
  }

  > svg {
    height: 24px;
    width: 24px;
  }

  &:after{
    display: none;
    content: "";
    position: absolute;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.3);
    
    width: 100px;
    height: 100px;
    margin-top: -50px;
    margin-left: -50px;
    
    /* Center the ripple */
    top: 50%;
    left: 50%;

    animation: ${ripple} 1s;
    opacity: 0;
  }

  &:focus:not(:active)::after{
    display: block;
  }
`

Button.defaultProps = {
  color: '#676767'
}

Button.propTypes = {
  color: PropTypes.string,
  handleClick: PropTypes.func,
  outline: PropTypes.bool
}

export default Button